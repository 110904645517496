/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import Paper from '@mui/material/Paper';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Select as MuiSelect,
  Box, // Rename Mui Select to avoid conflict
} from '@mui/material'; // Import Mui Select as MuiSelect

import Select from 'react-select';
import RichDataTable from '../admin-work-flow/rich-data-table/RichDataTable-contractor';
import useOverviewScreen from '../admin-work-flow/hooks/useOverviewScreen';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useClientDashboard from './hooks/useClientDashboard';
import { generalMonthArray } from 'app/shared/util/utits';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BusinessIcon from '@mui/icons-material/Business';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const Contractor = props => {
  const { showMenu } = props;
  const { states, handlers } = useClientDashboard();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const {
    countTotalIntervention,
    countTotalFollowUp,
    countTotal,
    pageNo,
    limit,
    totalCount,
    allUserPharmacy,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    allSessionPharmacyListing,
  } = states;

  const {
    downloadPharmacyInExcelFile,
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    customFilterOption,
    handleSelectMonth,
    handleEdit,
  } = handlers;

  const gridData = [
    { icon: '../../../../content/assets/check.png', heading: 'NMS Completed', heading2: totalCount !== undefined ? totalCount : 0 },
  ];

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
      width: 'max-content',
      minWidth: '100%',
    }),
  };

  const customStyles = {
    placeholder: provided => ({
      ...provided,
      fontSize: '12px', // Adjust placeholder text size
      color: '#999', // Optional: Change placeholder color if needed
    }),
    control: (provided, state) => ({
      ...provided,
      zIndex: 200, // Ensure the control is above other elements
      border: 'none', // Remove all borders
      borderBottom: state.isFocused ? '2px solid grey' : '1px solid #ccc', // Grey outline when focused
      boxShadow: state.isFocused ? '0 0 5px grey' : 'none', // Grey shadow when focused
      borderRadius: '0px', // Ensure no rounded corners
      '&:hover': {
        borderBottom: '2px solid grey', // Ensure hover effect matches focus
      },
    }),
    menu: provided => ({
      ...provided,
      zIndex: 300, // Ensure the dropdown menu appears above the table
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? 'lightgrey' : isFocused ? '#f0f0f0' : 'white', // Grey background for selected, light hover for focused
      color: isSelected ? '#000' : '#000',
      zIndex: 1,
    }),
  };

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={8}
      container
      style={{
        backgroundColor: 'transparent',
        marginTop: '2%',
        height: 'auto',
        transition: 'ease 0.5s',
        marginLeft: 'auto',
      }}
    >
      <Grid lg={11.6} xs={11.9} container style={{ flexDirection: 'row', display: 'flex' }}>
        <div style={{ width: '100%', display: 'flex', marginBottom: '20px' }}>
          <Grid container spacing={2} style={{ width: '100%' }}>
            {/* Left Section for Date Picker and Month Selector */}
            <Grid item style={{ display: 'flex', gap: '15px' }}>
              <Tooltip title="Date From">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    className="clock mui-field-design-filter"
                    onChange={newValue => handledateFrom(newValue)}
                    format="DD/MM/YYYY"
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    sx={{ width: '200px' }}
                  />
                </LocalizationProvider>
              </Tooltip>

              <Tooltip title="Date Till">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock mui-field-design-filter mui-field-design-date-filter"
                    onChange={newValue => handledateTill(newValue)}
                    format="DD/MM/YYYY"
                    sx={{ width: '200px' }}
                  />
                </LocalizationProvider>
              </Tooltip>

              <MuiSelect
                value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                onChange={handleSelectMonth}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className={'mui-field-design-filter'}
                style={{ width: '100%' }}
              >
                {generalMonthArray.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </MuiSelect>
            </Grid>

            {/* Right Section for Total Count */}
            <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <span>Completed NMS : {totalCount !== undefined ? totalCount : 0}</span>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', gap: 10, marginLeft: -25 }}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src="../../../../content/assets/refresh.png" alt="refresh" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download">
              <IconButton
                onClick={downloadPharmacyInExcelFile}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src="../../../../content/assets/download.png" alt="refresh" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
          <FormControl style={{ minWidth: '100%' }}>
            <Select
              isClearable
              name="pharmacies"
              className="basic-single"
              placeholder="Search pharmacy by name, branch name, post code"
              classNamePrefix="select"
              value={searchCreteria.pharmacy || ''}
              options={allUserPharmacy}
              styles={customStyles}
              onChange={handleRole}
              filterOption={customFilterOption}
            />
          </FormControl>
        </div>
        <Grid sx={{ width: '100%', paddingTop: '3px', border: 'none', marginTop: '-1%', backgroundColor: 'white' }}>
          <Grid lg={12} item style={{ height: 30, marginTop: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 26 }}>
              <FormControl sx={{ width: '100ch' }}>
                <OutlinedInput
                  style={{ borderRadius: 'none', height: 30, width: '100%' }}
                  placeholder="Search here"
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                        <img src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} alt="logo" style={{ height: '15px' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 13 } }}
                  classes={{ notchedOutline: 'white-outline' }}
                />
              </FormControl>
            </div>
          </Grid>

          <RichDataTable
            limit={limit ? limit : 20}
            page={pageNo ? pageNo : 0}
            count={totalCount ? totalCount : 0}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['patient.fullName', 'patient.telephone', 'patient.address', 'pharmacy.name']}
            dataSource={allSessionPharmacyListing}
            handleEdit={handleEdit}
            headers={[
              { label: 'Patient Name', icon: <PersonOutlineIcon style={{ fontSize: '18px', color: '#7CB6F8' }} /> },
              { label: 'Phone', icon: <PhoneIcon style={{ fontSize: '18px', color: '#7CB6F8' }} /> },
              { label: 'Address', icon: <PinDropIcon style={{ fontSize: '18px', color: '#FA0019' }} /> },
              { label: 'Pharmacy', icon: <LocalPharmacyIcon style={{ fontSize: '18px', color: '#2AB465' }} /> },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contractor;

interface Styles {
  container: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
};
