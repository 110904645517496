import './dashboard-menu-commp.scss';
import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import DailySummary from 'app/modules/deo-work-flow/deo-listing-component/daily-summary';
import AdminOverviewTable from 'app/modules/admin-work-flow/admin-over-veiw/listing-overview-table';
import AdminInterventionsTable from 'app/modules/admin-work-flow/listing-table';
import AdminManageRole from 'app/modules/admin-work-flow/admin-manage-role/admin-manage-role';
import AdminManagePharmacy from 'app/modules/admin-work-flow/admin-manage-pharmacy/admin-manage-pharmacy';
import Overview from 'app/modules/deo-work-flow/deo-listing-component/overview';
import Uploaded from 'app/modules/deo-work-flow/deo-listing-component/uploaded';
import ManagePharmacy from '../deo-listing-component/managePharmacy';
import File from 'app/modules/deo-work-flow/deo-listing-component/file';
import Record from 'app/modules/deo-work-flow/deo-listing-component/record';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

type dashboardMenuCommpProps = {
  onSelectContent: (content: string) => void;
  sessionCounts: any;
};

export const DashboardMenuCommp = props => {
  const role = JSON.parse(localStorage.getItem('role'));
  const { state }: any = useLocation();
  const { showMenu } = useDashboardContext();
  const [index, setIndex] = React.useState(0);
  const [showOptions, setShowOptions] = React.useState(false);
  const [selectedColor, setSelectedColor] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(sta => sta.authentication.account);
  const overdueListingCount = useAppSelector(sta => sta.deo.deoOverdueSessionCount);
  const deoUploadedFileCount = useAppSelector(sta => sta.deo.deoUploadedFileCount);
  const deoManagePharmacyFileCount = useAppSelector(sta => sta.deo.deoManagePharmacyFileCount);
  const deoRecordCount = useAppSelector(sta => sta.deo.deoRecordCount);
  const deoFileCount = useAppSelector(sta => sta.deo.deoFileCount);
  const isDEO = useAppSelector(sta => hasAnyAuthority(sta.authentication.account.authorities, [AUTHORITIES.DEO])) && role === 'DEO';
  const isLeadDEO =
    useAppSelector(sta => hasAnyAuthority(sta.authentication.account.authorities, [AUTHORITIES.LEAD_DEO])) && role === 'LEAD_DEO';
  const lastActiveTabDeo = localStorage.getItem('lastActiveTabDeo');
  const tabStateDeo = lastActiveTabDeo ? JSON.parse(lastActiveTabDeo) : { activeItem: '' };
  const [activeItem, setActiveItem] = useState('Daily-Summary');

  useEffect(() => {
    if (state) {
      handleItemClick(tabStateDeo.activeItem);
    }
  }, [lastActiveTabDeo]);

  const handleItemClick = (content: string) => {
    // onSelectContent(content);
    localStorage.setItem('lastActiveTabDeo', JSON.stringify({ activeItem: content }));
    setActiveItem(content);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid
        lg={showMenu ? 0.6 : 2.1}
        xs={12}
        sm={showMenu ? 1 : 3}
        container
        style={{
          background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
          justifyContent: 'center', // Center content
          transition: 'ease 0.4s',
          position: 'fixed',
          height: 'inherit',
          top: 0,
          opacity: 0.95,
          maxWidth: showMenu ? '4%' : '15%',
        }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <img
            src={'../../../content/logos/login-bg.png'}
            alt="background"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 0.2,
            }}
          />
        </div>
        {showMenu === false ? (
          <Grid
            item
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
              <span
                style={{
                  color: 'white',
                  fontWeight: 600,
                  marginLeft: '10px', // Add space between image and text
                  textAlign: 'center',
                }}
              >
                Pharmacy Solutions
              </span>
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              className={'custom_list_css'}
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
              }}
            >
              <ListItem onClick={() => handleItemClick('Daily-Summary')} className={'custom_list_item_css'}>
                <ListItemButton
                  onClick={() => handleItemClick('Daily-Summary')}
                  className={'custom_list_button_css'}
                  style={{
                    backgroundColor: activeItem === 'Daily-Summary' ? '#99D7FF' : 'transparent',
                  }}
                >
                  <img
                    src={
                      activeItem === 'Daily-Summary'
                        ? '../../../../content/assets/summary.png'
                        : '../../../../content/assets/summary-white.png'
                    }
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                  <ListItemContent
                    className={'custom_list_item_content_css'}
                    sx={{
                      color: activeItem === 'Daily-Summary' ? '#004CB2' : 'white',
                    }}
                  >
                    Summary
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              {!isDEO && (
                <>
                  <ListItem className={'custom_list_item_css'}>
                    <ListItemButton
                      onClick={() => handleItemClick('Overview')}
                      className={'custom_list_button_css'}
                      style={{
                        backgroundColor: activeItem === 'Overview' ? '#99D7FF' : 'transparent',
                      }}
                    >
                      <img
                        src={
                          activeItem === 'Overview'
                            ? '../../../../content/assets/overview.png'
                            : '../../../../content/assets/overview-white.png'
                        }
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: activeItem === 'Overview' ? '#004CB2' : 'white',
                        }}
                      >
                        Overview
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>

                  <ListItem className={'custom_list_item_css'}>
                    <ListItemButton
                      onClick={() => handleItemClick('Uploaded')}
                      className={'custom_list_button_css'}
                      style={{
                        backgroundColor: activeItem === 'Uploaded' ? '#99D7FF' : 'transparent',
                      }}
                    >
                      <img
                        src={
                          activeItem === 'Uploaded'
                            ? '../../../../content/assets/upload.png'
                            : '../../../../content/assets/upload-white.png'
                        }
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                      <ListItemContent
                        className={'custom_list_item_content_css'}
                        sx={{
                          color: activeItem === 'Uploaded' ? '#004CB2' : 'white',
                        }}
                      >
                        Uploaded
                      </ListItemContent>
                      <Typography
                        level="body-sm"
                        sx={{ fontWeight: 'bold', color: activeItem === 'Uploaded' ? '#004CB2' : 'white', fontSize: 13 }}
                      >
                        {deoUploadedFileCount || 0}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  {!isLeadDEO && (
                    <>
                      <ListItem className={'custom_list_item_css'}>
                        <ListItemButton
                          onClick={() => handleItemClick('ManagePharmacy')}
                          className={'custom_list_button_css'}
                          style={{
                            backgroundColor: activeItem === 'ManagePharmacy' ? '#99D7FF' : 'transparent',
                          }}
                        >
                          <img
                            src={
                              activeItem === 'ManagePharmacy'
                                ? '../../../../content/assets/manage-branches.png'
                                : '../../../../content/assets/manage-branches-white.png'
                            }
                            alt="logo"
                            className={'menu_bar_img'}
                          />
                          <ListItemContent
                            className={'custom_list_item_content_css'}
                            sx={{
                              color: activeItem === 'ManagePharmacy' ? '#004CB2' : 'white',
                            }}
                          >
                            Manage Branches
                          </ListItemContent>
                          <Typography
                            level="body-sm"
                            sx={{ fontWeight: 'bold', color: activeItem === 'ManagePharmacy' ? '#004CB2' : 'white', fontSize: 13 }}
                          >
                            {deoManagePharmacyFileCount || 0}
                          </Typography>
                        </ListItemButton>
                      </ListItem>

                      <ListItem className={'custom_list_item_css'}>
                        <ListItemButton
                          onClick={() => {
                            setShowOptions(prevState => !prevState);
                            handleItemClick('File');
                          }}
                          className={'custom_list_button_css'}
                          style={{
                            backgroundColor: activeItem === 'File' || activeItem === 'Record' ? '#99D7FF' : 'transparent',
                          }}
                        >
                          <img
                            src={
                              activeItem === 'File' || activeItem === 'Record'
                                ? '../../../../content/assets/trash.png'
                                : '../../../../content/assets/trash-white.png'
                            }
                            alt="logo"
                            className={'menu_bar_img'}
                          />

                          <ListItemContent
                            className={'custom_list_item_content_css'}
                            sx={{
                              color: activeItem === 'File' || activeItem === 'Record' ? '#004CB2' : 'white',
                            }}
                          >
                            Trash
                          </ListItemContent>
                          {showOptions === true ? (
                            <img src={'../../../../content/assets/uparrow.png'} alt="logo" style={{ height: '13px', marginLeft: 24 }} />
                          ) : (
                            <Typography
                              level="body-sm"
                              sx={{
                                fontWeight: 'bold',
                                color: activeItem === 'File' || activeItem === 'Record' ? '#004CB2' : 'white',
                                fontSize: 13,
                              }}
                            >
                              {deoFileCount + deoRecordCount}
                            </Typography>
                          )}
                        </ListItemButton>
                      </ListItem>
                      {showOptions === true ? (
                        <>
                          <ListItem className={'custom_list_item_css'}>
                            <ListItemButton
                              selected={index === 1}
                              className={'custom_list_button_css'}
                              style={{
                                backgroundColor: activeItem === 'File' ? '#b5d8ef96' : 'transparent',
                              }}
                              onClick={() => {
                                handleItemClick('File');
                              }}
                            >
                              <ListItemContent
                                className={'custom_list_item_content_css'}
                                sx={{
                                  color: activeItem === 'File' ? '#004CB2' : 'white',
                                }}
                              >
                                - File
                              </ListItemContent>
                              <Typography
                                level="body-sm"
                                sx={{ fontWeight: 'bold', color: activeItem === 'File' ? '#004CB2' : 'white', fontSize: 13 }}
                              >
                                {deoFileCount || 0}
                              </Typography>
                            </ListItemButton>
                          </ListItem>

                          <ListItem className={'custom_list_item_css'}>
                            <ListItemButton
                              className={'custom_list_button_css'}
                              selected={index === 2}
                              style={{
                                backgroundColor: activeItem === 'Record' ? '#b5d8ef96' : 'transparent',
                              }}
                              onClick={() => {
                                handleItemClick('Record');
                              }}
                            >
                              <ListItemContent
                                className={'custom_list_item_content_css'}
                                sx={{
                                  color: activeItem === 'Record' ? '#004CB2' : 'white',
                                }}
                              >
                                - Records
                              </ListItemContent>
                              <Typography
                                level="body-sm"
                                sx={{ fontWeight: 'bold', color: activeItem === 'Record' ? '#004CB2' : 'white', fontSize: 13 }}
                              >
                                {deoRecordCount || 0}
                              </Typography>
                            </ListItemButton>
                          </ListItem>
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </List>
          </Grid>
        ) : (
          <Grid
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              className={'custom_list_css'}
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
              }}
            >
              <ListItem onClick={() => handleItemClick('Daily-Summary')} className={'custom_list_item_css'}>
                <ListItemButton
                  onClick={() => handleItemClick('Daily-Summary')}
                  className={'custom_list_button_css'}
                  style={{
                    backgroundColor: activeItem === 'Daily-Summary' ? '#99D7FF' : 'transparent',
                  }}
                >
                  <img
                    src={
                      activeItem === 'Daily-Summary'
                        ? '../../../../content/assets/summary.png'
                        : '../../../../content/assets/summary-white.png'
                    }
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                </ListItemButton>
              </ListItem>
              {!isDEO && (
                <>
                  <ListItem className={'custom_list_item_css'}>
                    <ListItemButton
                      onClick={() => handleItemClick('Overview')}
                      className={'custom_list_button_css'}
                      style={{
                        backgroundColor: activeItem === 'Overview' ? '#99D7FF' : 'transparent',
                      }}
                    >
                      <img
                        src={
                          activeItem === 'Overview'
                            ? '../../../../content/assets/overview.png'
                            : '../../../../content/assets/overview-white.png'
                        }
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                    </ListItemButton>
                  </ListItem>

                  <ListItem className={'custom_list_item_css'}>
                    <ListItemButton
                      onClick={() => handleItemClick('Uploaded')}
                      className={'custom_list_button_css'}
                      style={{
                        backgroundColor: activeItem === 'Uploaded' ? '#99D7FF' : 'transparent',
                      }}
                    >
                      <img
                        src={
                          activeItem === 'Uploaded'
                            ? '../../../../content/assets/upload.png'
                            : '../../../../content/assets/upload-white.png'
                        }
                        alt="logo"
                        className={'menu_bar_img'}
                      />
                    </ListItemButton>
                  </ListItem>
                  {!isLeadDEO && (
                    <>
                      <ListItem className={'custom_list_item_css'}>
                        <ListItemButton
                          onClick={() => handleItemClick('ManagePharmacy')}
                          className={'custom_list_button_css'}
                          style={{
                            backgroundColor: activeItem === 'ManagePharmacy' ? '#99D7FF' : 'transparent',
                          }}
                        >
                          <img
                            src={
                              activeItem === 'ManagePharmacy'
                                ? '../../../../content/assets/manage-branches.png'
                                : '../../../../content/assets/manage-branches-white.png'
                            }
                            alt="logo"
                            className={'menu_bar_img'}
                          />
                        </ListItemButton>
                      </ListItem>

                      <ListItem className={'custom_list_item_css'}>
                        <ListItemButton
                          onClick={() => {
                            setShowOptions(prevState => !prevState);
                            handleItemClick('File');
                          }}
                          className={'custom_list_button_css'}
                          style={{
                            backgroundColor: activeItem === 'File' || activeItem === 'Record' ? '#99D7FF' : 'transparent',
                          }}
                        >
                          <img
                            src={
                              activeItem === 'File' || activeItem === 'Record'
                                ? '../../../../content/assets/trash.png'
                                : '../../../../content/assets/trash-white.png'
                            }
                            alt="logo"
                            className={'menu_bar_img'}
                          />
                        </ListItemButton>
                      </ListItem>
                      {/*{showOptions === true ? (*/}
                      {/*  <>*/}
                      {/*    <ListItem className={'custom_list_item_css'}>*/}
                      {/*      <ListItemButton*/}
                      {/*        selected={index === 1}*/}
                      {/*        className={'custom_list_button_css'}*/}
                      {/*        style={{*/}
                      {/*          backgroundColor: activeItem === 'File' ? '#b5d8ef96' : 'transparent',*/}
                      {/*        }}*/}
                      {/*        onClick={() => {*/}
                      {/*          handleItemClick('File');*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        <ListItemContent*/}
                      {/*          className={'custom_list_item_content_css'}*/}
                      {/*          sx={{*/}
                      {/*            color: activeItem === 'File' ? '#004CB2' : 'white',*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          - File*/}
                      {/*        </ListItemContent>*/}
                      {/*        <Typography*/}
                      {/*          level="body-sm"*/}
                      {/*          sx={{ fontWeight: 'bold', color: activeItem === 'File' ? '#004CB2' : 'white', fontSize: 13 }}*/}
                      {/*        >*/}
                      {/*          {deoFileCount || 0}*/}
                      {/*        </Typography>*/}
                      {/*      </ListItemButton>*/}
                      {/*    </ListItem>*/}

                      {/*    <ListItem className={'custom_list_item_css'}>*/}
                      {/*      <ListItemButton*/}
                      {/*        className={'custom_list_button_css'}*/}
                      {/*        selected={index === 2}*/}
                      {/*        style={{*/}
                      {/*          backgroundColor: activeItem === 'Record' ? '#b5d8ef96' : 'transparent',*/}
                      {/*        }}*/}
                      {/*        onClick={() => {*/}
                      {/*          handleItemClick('Record');*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        <ListItemContent*/}
                      {/*          className={'custom_list_item_content_css'}*/}
                      {/*          sx={{*/}
                      {/*            color: activeItem === 'Record' ? '#004CB2' : 'white',*/}
                      {/*          }}*/}
                      {/*        >*/}
                      {/*          - Records*/}
                      {/*        </ListItemContent>*/}
                      {/*        <Typography*/}
                      {/*          level="body-sm"*/}
                      {/*          sx={{ fontWeight: 'bold', color: activeItem === 'Record' ? '#004CB2' : 'white', fontSize: 13 }}*/}
                      {/*        >*/}
                      {/*          {deoRecordCount || 0}*/}
                      {/*        </Typography>*/}
                      {/*      </ListItemButton>*/}
                      {/*    </ListItem>*/}
                      {/*  </>*/}
                      {/*) : null}*/}
                    </>
                  )}
                </>
              )}
            </List>
          </Grid>
        )}
      </Grid>
      {activeItem === 'Daily-Summary' && (
        <>
          <DailySummary />
        </>
      )}
      {activeItem === 'Overview' && (
        <>
          <Overview />
        </>
      )}

      {activeItem === 'Uploaded' && (
        <>
          <Uploaded />
        </>
      )}
      {activeItem === 'ManagePharmacy' && (
        <>
          <ManagePharmacy />
        </>
      )}
      {activeItem === 'File' && (
        <>
          <File />
        </>
      )}
      {activeItem === 'Record' && (
        <>
          <Record />
        </>
      )}
    </Grid>
  );
};
export default DashboardMenuCommp;
