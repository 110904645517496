import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
  MenuItem,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import RichDataTable from '../rich-data-table/RichDataTable';
import useManageMedicineCondition from '../hooks/useManageMedicineCondition';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

const ManageMedicineCondition = () => {
  const { states, handlers } = useManageMedicineCondition();
  const { showMenu } = useDashboardContext();
  const {
    allMedicines,
    allMedicinesConditions,
    dialogOpen,
    addConditionDialogOpen,
    addMedicineDialogOpen,
    selectedCondition,
    newConditionName,
    selectedMedicines,
    manageMedicineDialogOpen,
    newMedicineName,
    confirmationDialogOpen,
    editConditionDialogOpen,
    conditionToEdit,
    setDialogOpen,
    setNewConditionName,
    setSelectedMedicines,
    setNewMedicineName,
    setConditionToEdit,
    setEditConditionDialogOpen,
  } = states;

  const {
    handleAddConditionDialogOpen,
    handleAddConditionDialogClose,
    handleAddCondition,
    handleManageConditions,
    handleConditionChange,
    handleAddMedicineDialogOpen,
    handleAddMedicineDialogClose,
    handleAddMedicine,
    handleManageMedicineDialogOpen,
    handleManageMedicineDialogClose,
    handleAddNewMedicine,
    handleDeleteCondition,
    handleConfirmDelete,
    handleCancelDelete,
    handleEditCondition,
    handleEditConditionDialogClose,
    handleSaveEditCondition,
  } = handlers;

  const [listMedicinesDialogOpen, setListMedicinesDialogOpen] = useState(false);
  const [selectedListCondition, setSelectedListCondition] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConditions, setFilteredConditions] = useState(allMedicinesConditions);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleListMedicinesDialogOpen = () => {
    setListMedicinesDialogOpen(true);
  };

  const handleListMedicinesDialogClose = () => {
    setListMedicinesDialogOpen(false);
  };

  const handleListConditionChange = event => {
    const conditionId = event.target.value;
    const selectedCondition = allMedicinesConditions.find(condition => condition.id === conditionId);
    setSelectedListCondition(selectedCondition);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setFilteredConditions(allMedicinesConditions.filter(condition => condition.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm, allMedicinesConditions]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      container
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: '2%',
        transition: 'ease 0.5s',
        maxWidth: '-webkit-fill-available',
        position: 'fixed',
        marginLeft: showMenu ? '6%' : '17%',
      }}
    >
      <Grid lg={11.8} xs={12} container>
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginBottom: '2%',
          }}
        >
          <div style={{ width: '100%', display: 'flex', backgroundColor: '' }}>
            <Tooltip title="Add New Condition">
              <IconButton
                onClick={handleAddConditionDialogOpen}
                style={{
                  background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
                  marginLeft: 5,
                  height: 40,
                  width: 150,
                  marginBottom: 10,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <span style={{ fontSize: 12, color: 'white', margin: 'auto' }}>Add New Condition</span>
              </IconButton>
            </Tooltip>
            <Tooltip title="New Medicine">
              <IconButton
                onClick={handleManageMedicineDialogOpen}
                style={{
                  background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
                  marginLeft: 5,
                  height: 40,
                  width: 150,
                  marginBottom: 10,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <span style={{ fontSize: 12, color: 'white', margin: 'auto' }}>New Medicines</span>
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage Conditions">
              <IconButton
                onClick={handleManageConditions}
                style={{
                  background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
                  marginLeft: 5,
                  height: 40,
                  width: 150,
                  marginBottom: 10,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <span style={{ fontSize: 12, color: 'white', margin: 'auto' }}>Manage Conditions</span>
              </IconButton>
            </Tooltip>
            <Tooltip title="List Medicines by Condition">
              <IconButton
                onClick={handleListMedicinesDialogOpen}
                style={{
                  background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
                  marginLeft: 5,
                  height: 40,
                  width: 180,
                  marginBottom: 10,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <span style={{ fontSize: 12, color: 'white', margin: 'auto' }}>List Medicines by Condition</span>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Grid>

      {/* Search and Pagination Controls */}
      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>
        {/* RichDataTable component for displaying conditions */}
        <RichDataTable
          limit={rowsPerPage ? rowsPerPage : 50}
          page={page ? page : 0}
          count={filteredConditions.length}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          emptyRowCount={0}
          emptyCellCount={0} // Adjust empty cell count as per your data
          dataIndexs={['id', 'name']}
          dataSource={filteredConditions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          headers={['ID', 'Condition Name']}
          showEditDeletButton={true} // Corrected prop name
          handleDelete={handleDeleteCondition}
          handleEdit={handleEditCondition}
        />
      </Grid>

      {/* Dialogs for managing conditions, adding condition, adding medicine, managing medicine */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle style={{ paddingBottom: 0 }}>Manage Conditions</DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <TextField select label="Select Condition" value={selectedCondition} onChange={handleConditionChange} fullWidth>
              {allMedicinesConditions.map(condition => (
                <MenuItem key={condition.id} value={condition.id}>
                  {condition.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <div>
            <Button variant="contained" color="primary" onClick={handleAddMedicineDialogOpen} style={{ marginTop: 10 }}>
              Add Medicine
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addConditionDialogOpen} onClose={handleAddConditionDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Add New Condition</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label="Condition Name"
              value={newConditionName}
              onChange={e => setNewConditionName(e.target.value)}
              fullWidth
              margin="normal"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddConditionDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCondition} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addMedicineDialogOpen} onClose={handleAddMedicineDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Add Medicine to Condition</DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            options={allMedicines}
            getOptionLabel={option => option.name}
            value={selectedMedicines}
            onChange={(event, newValue) => setSelectedMedicines(newValue)}
            renderInput={params => <TextField {...params} label="Select Medicines" margin="normal" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddMedicineDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddMedicine} color="primary" variant="contained">
            Add Medicines
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={manageMedicineDialogOpen} onClose={handleManageMedicineDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Add New Medicine</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label="Medicine Name"
              value={newMedicineName}
              onChange={e => setNewMedicineName(e.target.value)}
              fullWidth
              margin="normal"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManageMedicineDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNewMedicine} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationDialogOpen} onClose={handleCancelDelete} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this condition?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={listMedicinesDialogOpen} onClose={handleListMedicinesDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>List Medicines by Condition</DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginBottom: 20 }}>
            <TextField
              select
              label="Select Condition"
              value={selectedListCondition?.id || ''}
              onChange={handleListConditionChange}
              fullWidth
            >
              {allMedicinesConditions.map(condition => (
                <MenuItem key={condition.id} value={condition.id}>
                  {condition.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {selectedListCondition && (
            <Grid container spacing={2}>
              {selectedListCondition.medicines.map(medicine => (
                <Grid item key={medicine.id} xs={12} sm={6} md={4}>
                  <div>{medicine.name}</div>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleListMedicinesDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editConditionDialogOpen} onClose={handleEditConditionDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Edit Condition</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label="Condition Name"
              value={conditionToEdit?.name || ''}
              onChange={e =>
                setConditionToEdit(prev => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              fullWidth
              margin="normal"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditConditionDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEditCondition} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ManageMedicineCondition;
