import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import '../rich-data-table/admin-dashboard.scss';
import AdminManagePharmacy from '../admin-manage-pharmacy/admin-manage-pharmacy';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
export const ManagePharmacistDashboard = props => {
  const { state }: any = useLocation();
  const role = localStorage.getItem('role');
  const { sessionByPharmacyEntities, sessionCounts, sessionTypeValue, sessionStatusValue, rowsPer, pageNumber, isAdmin, isPharmacist } =
    props;
  const page = useAppSelector(sta => sta.session.pageNo);
  const rowsPerPage = useAppSelector(sta => sta.session.rows);
  const [index, setIndex] = useState(1);
  const [showOptions, setShowOptions] = useState(false);
  const [values, setValues] = useState({ service_id: 1, session_type: null, session_status: null });
  const [selectedColor, setSelectedColor] = useState(state ? state.selectedColor : 'one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(sta => sta.authentication.account);
  const pharmacyName = localStorage.getItem('pharmacy_name');
  const [showadmin, setShowadmin] = useState(false);
  const { showMenu } = useDashboardContext();
  useEffect(() => {
    if (state) {
      switch (state.selectedColor) {
        case 'one':
          setIndex(1);
          break;
        default:
          setIndex(1);
      }
      // setSelectedColor(selectedColor);

      setSelectedColor(state.selectedColor);
      setIndex(state.index);
    }
  }, [state]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };
  const handleTabClick = (color, idx) => {
    setSelectedColor(color);
    setIndex(idx);
  };
  return (
    <Grid container style={{ height: '100%' }}>
      <Grid
        lg={showMenu ? 0.6 : 2.1}
        xs={12}
        sm={showMenu ? 1 : 3}
        container
        style={{
          background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
          justifyContent: 'center', // Center content
          transition: 'ease 0.4s',
          position: 'fixed',
          height: 'inherit',
          top: 0,
          opacity: 0.95,
          maxWidth: showMenu ? '4%' : '15%',
        }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
          <img
            src={'../../../content/logos/login-bg.png'}
            alt="background"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: 0.2,
            }}
          />
        </div>
        {showMenu === false ? (
          <Grid
            item
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
              <span
                style={{
                  color: 'white',
                  fontWeight: 600,
                  marginLeft: '10px', // Add space between image and text
                  textAlign: 'center',
                }}
              >
                Pharmacy Solutions
              </span>
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              className={'custom_list_css'}
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('one', 1);
                }}
                className={'custom_list_item_css'}
              >
                <ListItemButton
                  data-index={1}
                  selected={index === 1}
                  className={'custom_list_button_css'}
                  style={{ backgroundColor: selectedColor === 'one' ? '#99D7FF' : 'transparent' }}
                  onClick={() => {
                    setSelectedColor('one');
                  }}
                >
                  <img
                    data-index={1}
                    src={
                      selectedColor === 'one'
                        ? '../../../../content/assets/manage-pharmacy.png'
                        : '../../../../content/assets/manage-pharmacy-white.png'
                    } // Use the image URL from the array
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                  <ListItemContent
                    data-index={1}
                    className={'custom_list_item_content_css'}
                    sx={{
                      color: selectedColor === 'one' ? '#004CB2' : 'white',
                    }}
                  >
                    Manage Pharmacy
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        ) : (
          <Grid
            lg={10}
            xs={10}
            sm={10}
            style={{
              textAlign: 'center', // Center-align content
              zIndex: 99999,
            }}
          >
            <Grid
              item
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center', // Vertically align items
                justifyContent: 'center', // Adjust horizontal alignment
              }}
            >
              <img
                src={'../../../../content/logos/final-logo.png'}
                alt="Logo"
                style={{
                  width: 'auto',
                  height: '45px',
                }}
              />
            </Grid>
            <hr style={{ marginTop: 0, color: 'white' }} />
            <List
              aria-label="Sidebar"
              className={'custom_list_css'}
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
              }}
            >
              <ListItem
                onClick={() => {
                  handleTabClick('one', 1);
                }}
                className={'custom_list_item_css'}
              >
                <ListItemButton
                  data-index={1}
                  selected={index === 1}
                  className={'custom_list_button_css'}
                  style={{ backgroundColor: selectedColor === 'one' ? '#99D7FF' : 'transparent' }}
                  onClick={() => {
                    setSelectedColor('one');
                  }}
                >
                  <img
                    data-index={1}
                    src={
                      selectedColor === 'one'
                        ? '../../../../content/assets/manage-pharmacy.png'
                        : '../../../../content/assets/manage-pharmacy-white.png'
                    } // Use the image URL from the array
                    alt="logo"
                    className={'menu_bar_img'}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        )}
      </Grid>
      {index === 1 && selectedColor === 'one' && (
        <>
          <AdminManagePharmacy />
        </>
      )}
    </Grid>
  );
};
export default ManagePharmacistDashboard;
