import moment from 'moment';
import { NMS_TYPES } from 'app/config/constants';
import * as XLSX from 'xlsx';

// Utility function to convert a string to an array buffer
export function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    // eslint-disable-next-line no-bitwise
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}
export const sessionStatusesOverview = [
  { id: 1, name: 'Completed NMS' },
  { id: 2, name: 'Completed Interventions' },
  { id: 3, name: 'Completed Follow-ups' },
  { id: 4, name: 'No Answered' },
  { id: 5, name: 'No Consent' },
  { id: 6, name: 'Ineligible' },
  { id: 7, name: 'Pending Intervention' },
  { id: 8, name: 'Pending Follow-ups' },
  { id: 9, name: 'Incorrect Number' },
  { id: 10, name: 'Intervention Not Delivered' },
  { id: 11, name: 'Patient Left Pharmacy' },
];

export const generalMonthArray = [
  'All Months',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const ethnicGroups = [
  {
    label: '',
    options: [
      {
        key: 'Prefer not to say,value',
        value: 'Prefer not to say',
      },
    ],
  },
  {
    label: 'Asian or Asian British',
    options: [
      { key: 'indian', value: 'Indian' },
      { key: 'pakistani', value: 'Pakistani' },
      { key: 'bangladeshi', value: 'Bangladeshi' },
      { key: 'chinese', value: 'Chinese' },
      { key: 'other_asian', value: 'Any other Asian background' },
    ],
  },
  {
    label: 'Black, Black British, Caribbean or African',
    options: [
      { key: 'caribbean', value: 'Caribbean' },
      { key: 'african', value: 'African' },
      { key: 'other_black', value: 'Any other Black, Black British, or Caribbean background' },
    ],
  },
  {
    label: 'Mixed or multiple ethnic groups',
    options: [
      { key: 'white_black_caribbean', value: 'White and Black Caribbean' },
      { key: 'white_black_african', value: 'White and Black African' },
      { key: 'white_asian', value: 'White and Asian' },
      { key: 'other_mixed', value: 'Any other Mixed or multiple ethnic background' },
    ],
  },
  {
    label: 'White',
    options: [
      { key: 'roma', value: 'Roma' },
      { key: 'irish', value: 'Irish' },
      { key: 'other_white', value: 'Any other White background' },
      { key: 'gypsy_traveller', value: 'Gypsy or Irish Traveller' },
      { key: 'british', value: 'English, Welsh, Scottish, Northern Irish or British' },
    ],
  },
  {
    label: 'Other ethnic group',
    options: [
      { key: 'arab', value: 'Arab' },
      { key: 'other_ethnic', value: 'Any other ethnic group' },
    ],
  },
];

export const isValidDate = e => {
  const myDate = new Date(e);

  const isDate = String(myDate) !== 'Invalid Date' && String(myDate) !== 'Thu Jan 01 1970 05:00:00 GMT+0500 (Pakistan Standard Time)';

  const date = isDate ? moment(myDate).format('Y-MM-DD') : '';
  return date;
};

const medicineCellFormation = (medicines, pMedicineRId = null) => {
  return medicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(elem => {
      const { medicine } = elem;
      return medicine.name;
    })
    .join(',');
};
const interventionNoteFormation = (medicines, pMedicineRId = null) => {
  return medicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(elem => {
      const { medicine } = elem;
      let cellText = '';
      cellText += `\n${elem?.interventionNote ? elem.interventionNote : ''}`;
      return cellText;
    })
    .join(',  ');
};
const followupNoteFormation = (medicines, pMedicineRId = null) => {
  return medicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(elem => {
      const { medicine } = elem;
      let cellText = '';
      cellText += `\n${elem?.description ? elem.description : ''}`;
      return cellText;
    })
    .join(',  ');
};

const outcomofphonecellFormation = (elem, pMedicineRId = null) => {
  if (!elem.prescription || !elem.prescription.prescriptionMedicines) return '';
  let medicineDetails = [];
  if (elem.sessionStatus.name === 'completed') {
    medicineDetails = elem.prescription.prescriptionMedicines
      .filter(med => !pMedicineRId || med.id === pMedicineRId)
      .map(pMed => {
        const details = [];
        if (pMed.isNewMedicine !== null) {
          details.push(`${pMed.isNewMedicine === true ? 'New Medicine' : 'Not New Medicine'}`);
        }
        // if (pMed.isEligibleCondition !== null) {
        //   details.push(`${pMed.isEligibleCondition === true ? 'Eligible Condition' : 'Not Eligible Condition'}`);
        // }
        return details.join(', ');
      });
  } else {
    medicineDetails = elem.prescription.prescriptionMedicines
      .filter(med => !pMedicineRId || med.id === pMedicineRId)
      .map(pMed => {
        const details = [];
        if (pMed.isNewMedicine !== null) {
          details.push(` ${pMed.isNewMedicine === true ? 'New Medicine' : 'Not New Medicine'}`);
        }
        if (pMed.isEligibleCondition !== null) {
          details.push(`${pMed.isEligibleCondition === true ? 'Eligible Condition' : 'Not Eligible Condition'}`);
        }
        return details.join(', ');
      });

    if (elem.sessionStatus.name === 'incorrect_number') {
      medicineDetails.push('Incorrect Number');
    }

    if (elem.callAttempt) {
      medicineDetails.push(`Call Attempt: ${elem.callAttempt}`);
    }
  }
  return medicineDetails.join(', ');
};

const outcomofphonecellFormationOther = (elem, pMedicineRId = null) => {
  if (!elem.prescription || !elem.prescription.prescriptionMedicines) return '';
  const medicineDetails = [];

  if (elem.sessionStatus.name === 'incorrect_number') {
    medicineDetails.push('Incorrect Number');
  }

  if (elem.notStartedCallAttempt) {
    medicineDetails.push(`Call Attempt: ${elem.notStartedCallAttempt}`);
  }

  return medicineDetails.join(', ');
};

const outcomofphonecellFormationNotEligibleCondition = (elem, pMedicineRId = null) => {
  if (!elem.prescription || !elem.prescription.prescriptionMedicines) return '';
  let medicineDetails = [];
  // if (elem.sessionStatus.name === 'completed') {
  medicineDetails = elem.prescription.prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(pMed => {
      const details = [];
      // if (pMed.isNewMedicine !== null) {
      //   details.push(`${pMed.isNewMedicine === true ? 'New Medicine' : 'Not New Medicine'}`);
      // }

      if (pMed.isEligibleCondition !== null) {
        details.push(`${pMed.isEligibleCondition === true ? 'Eligible Condition' : 'Not Eligible Condition'}`);
      }
      return details.join(', ');
    });
  // }
  return medicineDetails.join(', ');
};

const outcomofphonecellFormationNotNewMedicine = (elem, pMedicineRId = null) => {
  if (!elem.prescription || !elem.prescription.prescriptionMedicines) return '';
  let medicineDetails = [];
  // if (elem.sessionStatus.name === 'completed') {
  medicineDetails = elem.prescription.prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(pMed => {
      const details = [];
      if (pMed.isNewMedicine !== null) {
        details.push(`${pMed.isNewMedicine === true ? 'New Medicine' : 'Not New Medicine'}`);
      }
      // if (pMed.isEligibleCondition !== null) {
      //   details.push(`${pMed.isEligibleCondition === true ? 'Eligible Condition' : 'Not Eligible Condition'}`);
      // }
      return details.join(', ');
    });
  // }
  return medicineDetails.join(', ');
};

const patientUsingMedicineAsPrescibedCellFormation = medicines => {
  let cellText = '';
  return medicines
    .map((elem, index) => {
      const { medicine, medicineUsedAsPrescribed } = elem;
      cellText += `${medicine.name}\n`;
      cellText += medicineUsedAsPrescribed === true ? 'Using as Prescribed' : 'Not Using as Prescribed';
      return cellText;
    })
    .join('\n');
};

const followUpCompletedCellFormation = (medicines, answersList, answersList2, pMedicineRId = null) => {
  return medicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map((elem, index) => {
      const { medicine, medicineUsedAsPrescribed, sessionPatientMedicineUsedAsPrescribedNoType, id } = elem;
      const result = sessionPatientMedicineUsedAsPrescribedNoType
        ?.filter(({ sessionType }) => sessionType.id === 2)
        ?.map(({ sessionMedicineUsedAsPrescribedNoType }) => {
          return sessionMedicineUsedAsPrescribedNoType?.name || '';
        })
        .join(', ');

      let cellText = `${medicineUsedAsPrescribed === true ? 'Using as Prescribed' : ''} ${result ? `, ${result}` : ''}`;

      answersList2
        .filter(({ prescriptionMedicine }) => prescriptionMedicine.id === id)
        .map((answers, index2) => {
          const { sessionPharmacistAgreedActionReferBack } = answers;
          cellText += `, ${answers.description}`;

          if (sessionPharmacistAgreedActionReferBack.length) {
            cellText += `, ${sessionPharmacistAgreedActionReferBack.map(spARb => spARb.description).join(', ')}`;
          }
        });

      return cellText;
    })
    .join(', ');
};

const eligibleConditionCellFormation = (prescriptionMedicines, pMedicineRId = null) => {
  console.log({ prescriptionMedicines });
  return prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map(elem => {
      if (elem?.sessionMedicineCondition) {
        const { sessionMedicineCondition } = elem;
        const { medicineCondition } = sessionMedicineCondition;
        let cellText = '';
        cellText = `\n${medicineCondition?.name}`;
        return cellText;
      }
    })
    .join('\n  ');
};

const medicineUsedAsPresrcibed = (prescriptionMedicines, pMedicineRId = null) => {
  return prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map((elem, index) => {
      const { medicine, medicineUsedAsPrescribed, sessionPatientMedicineUsedAsPrescribedNoType } = elem;
      let cellText = '';
      cellText += `\n${
        medicineUsedAsPrescribed === true
          ? 'Using as Prescribed'
          : sessionPatientMedicineUsedAsPrescribedNoType[0]?.sessionType?.name === 'intervention'
          ? sessionPatientMedicineUsedAsPrescribedNoType[0]?.sessionMedicineUsedAsPrescribedNoType?.name
          : ''
      }`;
      return cellText;
    })
    .join('\n  ');
};

const questionCellFormation = (medicines, answersList, pMedicineRId = null) => {
  const data = answersList.length
    ? medicines
        .filter(med => !pMedicineRId || med.id === pMedicineRId)
        .map(elem => {
          const { medicine, id } = elem;
          const cellText = answersList
            .filter(({ prescriptionMedicine }) => prescriptionMedicine.id === id)
            .map(answers => answers.description)
            .join(', '); // Join descriptions with a comma and space
          return cellText;
        })
        .join(', ')
    : '';
  return data;
};

const questionNotUsingMedicineReasonFormation = prescriptionMedicines => {
  return prescriptionMedicines
    .map((elem, index) => {
      const { medicine, medicineUsedAsPrescribed, sessionPatientMedicineUsedAsPrescribedNoType } = elem;
      let cellText = medicine?.name;
      const result = sessionPatientMedicineUsedAsPrescribedNoType
        ?.filter(({ sessionType }) => sessionType.id === 2)
        ?.map(({ sessionMedicineUsedAsPrescribedNoType }) => {
          return sessionMedicineUsedAsPrescribedNoType?.name || '';
        })
        .join(',');
      cellText += `\n${medicineUsedAsPrescribed === true ? 'No Reason' : result}`;

      return cellText;
    })
    .join('\n');
};

const questionCellFormationActionTakenByPharmacist = (medicines, answersList, pMedicineRId = null) => {
  const data = answersList.length
    ? medicines
        .filter(med => !pMedicineRId || med.id === pMedicineRId)
        .map(elem => {
          const { medicine, id } = elem;
          const cellText = answersList
            .filter(({ prescriptionMedicine }) => prescriptionMedicine.id === id)
            .map(answers => {
              const { sessionPharmacistAgreedActionReferBack } = answers;
              let text = `${answers.description}`;
              if (sessionPharmacistAgreedActionReferBack.length) {
                text += `, ${sessionPharmacistAgreedActionReferBack.map(spARb => spARb.description).join(', ')}`;
              }
              return text;
            })
            .join(', '); // Join descriptions with a comma and space
          return cellText;
        })
        .join('\n, ')
    : '';
  return data;
};
const questionCellFormationPatient = (medicines, answersList, pMedicineRId = null) => {
  const data = answersList.length
    ? medicines
        .filter(med => !pMedicineRId || med.id === pMedicineRId)
        .map(elem => {
          const { medicine, id } = elem;
          const cellText = answersList
            .filter(({ prescriptionMedicine }) => prescriptionMedicine.id === id)
            .map(answers => {
              return `${answers.description}`;
            })
            .join(', '); // Join descriptions with a comma and space
          return cellText;
        })
        .join('\n, ')
    : '';
  return data;
};

const pharmacistFormation = (prescriptionMedicines, pMedicineRId = null) => {
  return prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map((elem, index) => {
      const { medicine, appUserIntervention } = elem;
      let cellText = '';
      cellText = appUserIntervention?.internalUser?.firstName;
      return cellText;
    })
    .join('\n');
};

const pharmacistGHPCFormation = (prescriptionMedicines, pMedicineRId = null) => {
  return prescriptionMedicines
    .filter(med => !pMedicineRId || med.id === pMedicineRId)
    .map((elem, index) => {
      const { medicine, appUserIntervention } = elem;
      let cellText = '';
      cellText = appUserIntervention?.gphcNumber;
      return cellText;
    })
    .join('\n');
};

const getPreviousMonthEndDates = selectedMonth => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthIndex = monthNames.indexOf(localStorage.getItem('month'));

  // Calculate the previous two months' end dates
  const prevMonthEndDate = new Date(new Date().getFullYear(), monthIndex, 0); // End date of the selected month
  prevMonthEndDate.setDate(prevMonthEndDate.getDate() + 1); // Add one day to prevMonthEndDate

  const currentMonthEndDate = new Date(new Date().getFullYear(), monthIndex + 1, 0); // End date of the month before the selected month

  return [currentMonthEndDate, prevMonthEndDate];
};

export const generateExcelData = (groupedData, wb, selectedMonth = null) => {
  // const [currentMonthEndDate, prevMonthEndDate] = getPreviousMonthEndDates(selectedMonth);

  Object.keys(groupedData).forEach(status => {
    const dataForStatus = [];
    const notEligibleData = [];
    const notNewData = [];
    const pendingFollow_ups = [];
    // const notStartedData = [];
    const pendingIntervenData = [];
    const completedNmsData = [];

    groupedData[status].forEach((record, index) => {
      if (record.prescription.prescriptionMedicines && record.prescription.prescriptionMedicines.length > 0) {
        record.prescription.prescriptionMedicines.forEach((medicine, medIndex) => {
          const newRecord = { ...record, prescriptionMedicine: medicine };
          // if (
          //   record.notStartedCallAttempt === '1' &&
          //   record.sessionStatus?.name === 'in_progress' &&
          //   record.prevSessionType?.name === 'intervention'
          // ) {
          //   notStartedData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine));
          // }
          if (
            record.sessionStatus?.name === 'in_progress' &&
            record.prevSessionType?.name === 'intervention' &&
            moment(record?.nextSession).add(record?.pharmacy?.rule) < moment()
          ) {
            pendingIntervenData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine));
          } else if (medicine.isEligibleCondition === false) {
            notEligibleData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine, 'not_eligible_condition'));
            // if (status === 'in_eligible' && medicine.isEligibleCondition === false) {
            //   notEligibleData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine, 'not_eligible_condition'));
            // }
          } else if (medicine.isNewMedicine === false) {
            notNewData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine, 'not_new_medicine'));
            // if (status === 'in_eligible' && medicine.isNewMedicine === false) {
            //   notNewData.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine, 'not_new_medicine'));
            // }
          } else if (status === 'Pending Follow-ups') {
            pendingFollow_ups.push(interventionData(newRecord, index * 100 + medIndex, medicine));
          } else if (
            status === 'Completed NMS' ||
            status === 'Completed Interventions' ||
            status === 'Completed Follow-ups' ||
            status === 'Completed'
          ) {
            completedNmsData.push(interventionData(newRecord, index * 100 + medIndex, medicine));
          } else {
            dataForStatus.push(interventionDataOther(newRecord, index * 100 + medIndex, medicine));
          }
        });
      }
    });

    const ws = XLSX.utils.json_to_sheet(dataForStatus);
    const notEligibleWs = XLSX.utils.json_to_sheet(notEligibleData);
    const notNewWs = XLSX.utils.json_to_sheet(notNewData);
    const pendingFollowUpWs = XLSX.utils.json_to_sheet(pendingFollow_ups);
    // const notStartedWs = XLSX.utils.json_to_sheet(notStartedData);
    const pendingIntervenWs = XLSX.utils.json_to_sheet(pendingIntervenData);

    const addColumnWidths = worksheet => {
      const totalColumns = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
      worksheet['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(worksheet['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = worksheet[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 };
      });
    };

    addColumnWidths(ws);
    addColumnWidths(notEligibleWs);
    addColumnWidths(notNewWs);
    addColumnWidths(pendingFollowUpWs);
    // addColumnWidths(notStartedWs);
    addColumnWidths(pendingIntervenWs);

    let sanitizedStatus = status.trim();

    switch (sanitizedStatus) {
      case 'completed':
        sanitizedStatus = 'Completed';
        break;
      case 'Completed NMS':
        sanitizedStatus = 'Completed NMS';
        break;
      case 'Completed Interventions':
        sanitizedStatus = 'Completed Interventions';
        break;
      case 'Completed Follow-ups':
        sanitizedStatus = 'Completed Follow-ups';
        break;
      case sanitizedStatus === 'not_reacheable' ? 'not_reacheable' : ' No Answered':
        sanitizedStatus = 'No Answer';
        break;
      case sanitizedStatus === 'no_consent' ? 'no_consent' : 'No Consent':
        sanitizedStatus = 'No Consent';
        break;
      case sanitizedStatus === 'incorrect_number' ? 'incorrect_number' : 'Incorrect Number':
        sanitizedStatus = 'Incorrect Number';
        break;
      case sanitizedStatus === 'intervention_not_delivered' ? 'intervention_not_delivered' : 'Intervention Not Delivered':
        sanitizedStatus = 'Intervention Not Delivered';
        break;
      case sanitizedStatus === 'patient_left' ? 'patient_left' : 'Patient Left Pharmacy':
        sanitizedStatus = 'Patient Left Pharmacy';
        break;
      default:
        sanitizedStatus = 'Not Started Medicine';
        break;
    }

    const appendOrUpdateSheet = (sheetName, data) => {
      if (data.length > 0) {
        const existingSheet = wb.Sheets[sheetName];
        if (existingSheet) {
          const existingData = XLSX.utils.sheet_to_json(existingSheet);
          const updatedData = existingData.concat(data);
          const updatedSheet = XLSX.utils.json_to_sheet(updatedData);
          addColumnWidths(updatedSheet);
          wb.Sheets[sheetName] = updatedSheet;
        } else {
          const newWs = XLSX.utils.json_to_sheet(data);
          addColumnWidths(newWs);
          XLSX.utils.book_append_sheet(wb, newWs, sheetName);
        }
      }
    };

    appendOrUpdateSheet(sanitizedStatus, dataForStatus);
    appendOrUpdateSheet('Not New Medicine', notNewData);
    appendOrUpdateSheet('Pending Follow-ups', pendingFollow_ups);
    appendOrUpdateSheet('Pending Intervention', pendingIntervenData);
    appendOrUpdateSheet('Not Eligible Condition', notEligibleData);
    // appendOrUpdateSheet('Not Started Medicine', notStartedData);
    appendOrUpdateSheet(status, completedNmsData);
  });
};

// export const generateExcelDataToDownloadStoreTotal = (groupedData, wb,totalStats) => {
//   const ws = XLSX.utils.aoa_to_sheet([['S/R', 'Stores', 'Total', 'Target', 'Status', 'Performance', 'Pending Follow-ups', 'Completed Follow-ups']]); // Add the headers
//
//   let rowIndex = 1; // Start from row 1, since row 0 has headers
//   let pharmacyIndexCount = 1;
//   // Loop through each group
//   groupedData.forEach((group, groupIndex) => {
//     // Add group name in a separate row
//     XLSX.utils.sheet_add_aoa(ws, [['',`Group: ${group.groupName}`]], { origin: -1 });
//     rowIndex++;
//
//
//     // Loop through pharmacies within each group
//     group.pharmacies.forEach((store, index) => {
//       const row = [pharmacyIndexCount++, store.pharmacyName, store.completedInterventions, store.target, store.status, store.performance, store.pending_follow_ups, store.completed_follow_ups];
//       XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });
//
//       // Apply color based on performance
//       let performanceColor = '';
//       switch (store.performance) {
//         case 'Excellent':
//           performanceColor = 'A9D08E'; // Green for Excellent
//           break;
//         case 'Good':
//           performanceColor = 'FFD966'; // Yellow for Good
//           break;
//         case 'Fair':
//           performanceColor = 'F4B084'; // Orange for Fair
//           break;
//         case 'Average':
//           performanceColor = 'FFC000'; // Light Orange for Average
//           break;
//         case 'Extremely Low':
//           performanceColor = 'FF0000'; // Red for Extremely Low
//           break;
//         default:
//           performanceColor = 'FFFFFF'; // Default white for unknown performance
//       }
//
//       // Apply background color to the Performance column (5th cell in the row)
//       const cellAddress = `F${rowIndex}`; // Cell address starts from F2, changes with rowIndex
//       if (!ws[cellAddress]) {
//         ws[cellAddress] = {}; // Create the cell if it doesn't exist
//       }
//       ws[cellAddress].s = {
//         fill: {
//           patternType: 'solid',
//           fgColor: { rgb: performanceColor }, // Apply the performance color
//         },
//       };
//
//       rowIndex++; // Move to next row for each store
//     });
//
//     // After all pharmacies are listed for this group, add a blank line
//     rowIndex++;
//   });
//
//   // Add the Total Interventions, Total Target, and Overall Percentage at the bottom
//   const totalRow = [
//     'Total',
//     '',
//     totalStats.totalCompletedInterventions,
//     totalStats.totalTarget,
//     totalStats.overallPercentage // Replace with actual percentage if available
//   ];
//   XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });
//
//   // Adjust column widths based on content
//   const addColumnWidths = worksheet => {
//     const totalColumns = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
//     worksheet['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
//       const columnTextLengths = [];
//       for (let R = 0; R <= XLSX.utils.decode_range(worksheet['!ref']).e.r; R++) {
//         const cellAddress = { c: index, r: R };
//         const cellRef = XLSX.utils.encode_cell(cellAddress);
//         const cellText = worksheet[cellRef]?.v?.toString() ?? '';
//         columnTextLengths.push(cellText.length);
//       }
//       const maxTextLength = Math.max(...columnTextLengths);
//       return { wch: maxTextLength + 2 };
//     });
//   };
//   addColumnWidths(ws);
//
//   // Append the worksheet to the workbook
//   XLSX.utils.book_append_sheet(wb, ws, 'Stores Performance');
// };
export const generateExcelDataToDownloadStoreTotal = (groupedData, wb, totalStats, selectedColumns = []) => {
  const columnHeaders = {
    Stores: 'pharmacyName',
    Total: 'completedInterventions',
    Target: 'target',
    Status: 'status',
    Performance: 'performance',
    'Pending Follow-ups': 'pending_follow_ups',
    'Completed Follow-ups': 'completed_follow_ups',
  };

  // If selectedColumns is empty, default to all columns
  if (!selectedColumns || selectedColumns.length === 0) {
    selectedColumns = ['Stores', 'Total', 'Target', 'Status', 'Performance', 'Pending Follow-ups', 'Completed Follow-ups'];
  }

  // Filter column headers based on the selected columns
  const filteredHeaders = ['S/R', ...selectedColumns]; // Include 'S/R' for serial number
  const columnKeys = selectedColumns.map(col => columnHeaders[col]);

  // Add headers to the worksheet
  const ws = XLSX.utils.aoa_to_sheet([filteredHeaders]);

  let rowIndex = 1; // Start from row 1, since row 0 has headers

  // Loop through each group
  groupedData.forEach(group => {
    XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });
    rowIndex++;
    // Add group name in a separate row
    XLSX.utils.sheet_add_aoa(ws, [['', `Group: ${group.groupName}`]], { origin: -1 });
    rowIndex++;

    XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });
    rowIndex++;

    // Loop through pharmacies within each group
    group.pharmacies.forEach((store, index) => {
      // Generate row with selective columns
      const row = [
        index + 1, // S/R column
        ...columnKeys.map(key => store[key]), // Only include selected columns
      ];
      XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });

      // Apply color based on performance (only if 'Performance' is selected)
      if (selectedColumns.includes('Performance')) {
        let performanceColor = '';
        switch (store.performance) {
          case 'Excellent':
            performanceColor = 'A9D08E'; // Green for Excellent
            break;
          case 'Good':
            performanceColor = 'FFD966'; // Yellow for Good
            break;
          case 'Fair':
            performanceColor = 'F4B084'; // Orange for Fair
            break;
          case 'Average':
            performanceColor = 'FFC000'; // Light Orange for Average
            break;
          case 'Extremely Low':
            performanceColor = 'FF0000'; // Red for Extremely Low
            break;
          default:
            performanceColor = 'FFFFFF'; // Default white for unknown performance
        }

        // Apply background color to the Performance column (F column for Performance)
        const performanceColumnIndex = filteredHeaders.indexOf('Performance');
        const cellAddress = XLSX.utils.encode_cell({ c: performanceColumnIndex, r: rowIndex });
        if (!ws[cellAddress]) {
          ws[cellAddress] = {}; // Create the cell if it doesn't exist
        }
        ws[cellAddress].s = {
          fill: {
            patternType: 'solid',
            fgColor: { rgb: performanceColor }, // Apply the performance color
          },
        };
      }

      rowIndex++; // Move to next row for each store
    });

    const totalRow = [
      '',
      'Total',
      group.totalCompletedInterventions,
      group.totalTarget,
      group.overallPercentage, // Replace with actual percentage if available
    ];
    XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });

    // After all pharmacies are listed for this group, add a blank line
    rowIndex++;
  });

  // Add the Total Interventions, Total Target, and Overall Percentage at the bottom (selective columns)
  // const totalRow = [
  //   'Total',
  //   ...columnKeys.map(key => totalStats[`total${key.charAt(0).toUpperCase() + key.slice(1)}`] || '')
  // ];
  //   const totalRow = [
  //   'Total',
  //   '',
  //   totalStats.totalCompletedInterventions,
  //   totalStats.totalTarget,
  //   totalStats.overallPercentage // Replace with actual percentage if available
  // ];
  // XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: -1 });

  // Adjust column widths based on content
  const addColumnWidths = worksheet => {
    const totalColumns = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
    worksheet['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
      const columnTextLengths = [];
      for (let R = 0; R <= XLSX.utils.decode_range(worksheet['!ref']).e.r; R++) {
        const cellAddress = { c: index, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cellText = worksheet[cellRef]?.v?.toString() ?? '';
        columnTextLengths.push(cellText.length);
      }
      const maxTextLength = Math.max(...columnTextLengths);
      return { wch: maxTextLength + 2 };
    });
  };
  addColumnWidths(ws);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Stores Performance');
};

export const generateExcelDataToDownloadPharmacistTotal = (groupedData, wb) => {
  const ws_data = [
    ['Pharmacist Name', 'Completed Interventions', 'Completed Follow-ups', 'Email', 'GHPC Number', 'Contact Number'], // Header row
  ];

  // Loop over the data and push rows
  groupedData.forEach(pharmacist => {
    ws_data.push([
      pharmacist.internaluser_firstname || 'N/A', // Pharmacist Name
      pharmacist.completedInterventions || 0, // Completed Interventions
      pharmacist.completedFollowUps || 0, // Completed Follow-ups
      pharmacist.internaluser_email || 'N/A', // Email
      pharmacist.appuser_gphcnumber || 'N/A', // Email
      pharmacist.appuser_contact_number || 'N/A', // Contact Number
    ]);
  });

  // Create a worksheet
  const ws = XLSX.utils.aoa_to_sheet(ws_data);

  const addColumnWidths = worksheet => {
    const totalColumns = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
    worksheet['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
      const columnTextLengths = [];
      for (let R = 0; R <= XLSX.utils.decode_range(worksheet['!ref']).e.r; R++) {
        const cellAddress = { c: index, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cellText = worksheet[cellRef]?.v?.toString() ?? '';
        columnTextLengths.push(cellText.length);
      }
      const maxTextLength = Math.max(...columnTextLengths);
      return { wch: maxTextLength + 2 };
    });
  };
  addColumnWidths(ws);
  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Pharmacist Stats');
};

export const interventionDataOther = (elem, index, pMedicine = null, sheet = null) => {
  const rowObject = {
    'Patient Name': elem.patient.firstName,

    'Patient Address': elem.patient.address,

    'Patient Ethnicity': '',

    'Date of Birth': elem?.patient?.dateofBirth ? moment(elem?.patient?.dateofBirth).format('DD-MM-Y') : '',

    'Contact number': elem.patient.telephone,

    // 'Secondary Contact number': elem.patient.secondary_telephone,

    'NHS Number': elem.patient.nhsNumber,

    Medicine: medicineCellFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Previously Delivered': elem?.previouslyDelivered === true ? 'Yes' : 'No',
    'Intervention Not Delivered Reason':
      elem?.sessionPreviouslyDeliveredNoType !== null && elem?.sessionPreviouslyDeliveredNoType?.name === 'Other'
        ? elem?.sessionPatientPreviouslyDeliveredOtherDescription
        : elem?.sessionPreviouslyDeliveredNoType?.name,

    'Date of Engagement': elem.nextSession ? moment(elem.nextSession).format('DD-MM-Y') : '',

    'Verbal Consent':
      elem?.sessionStatus?.name === 'not_reacheable' && elem?.consent === false
        ? ''
        : elem?.sessionStatus?.name === 'incorrect_number' && elem?.consent === false
        ? ''
        : elem?.sessionStatus?.name === 'in_progress' &&
          elem?.prevSessionType?.name === 'intervention' &&
          elem?.notStartedCallAttempt === '0'
        ? ''
        : elem.consent === true
        ? 'Yes'
        : 'No',
    'Outcome of phone call':
      sheet === 'not_eligible_condition'
        ? outcomofphonecellFormationNotEligibleCondition(elem, pMedicine?.id)
        : sheet === 'not_new_medicine'
        ? outcomofphonecellFormationNotNewMedicine(elem, pMedicine?.id)
        : outcomofphonecellFormationOther(elem, pMedicine?.id),

    'Eligible condition': '',
    'Date of Intervention': '',
    'Time of Intervention': '',
    'Intervention question asked': '',
    'Matters identified with patient': '',
    'Issue reported by patient': '',
    'Agreed Patient Actions': '',
    'Healthy living advice': '',
    'Information Provided': '',
    'Action taken by Pharmacist': '',
    'Intervention Note': '',
    'Follow up declined by patient': '',
    'Follow up declined reason': '',
    'Follow up date': '',
    'Follow up Note': '',
    'Follow up completed': '',
    'Pharmacist name': '',
    'GPHC Number': '',
  };
  return rowObject;
};

export const interventionData = (elem, index, pMedicine = null) => {
  const rowObject = {
    'Patient Name': elem.patient.firstName,
    'Patient Address': elem.patient.address,
    'Patient Ethnicity': elem.patient.ethnicity,
    'Date of Birth': elem?.patient?.dateofBirth ? moment(elem?.patient?.dateofBirth).format('DD-MM-Y') : '',
    'Contact number': elem.patient.telephone,
    // 'Secondary Contact number': elem.patient.secondary_telephone,
    'NHS Number': elem.patient.nhsNumber,
    Medicine: medicineCellFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Previously Delivered': elem?.previouslyDelivered === true ? 'Yes' : 'No',
    'Intervention Not Delivered Reason':
      elem?.sessionPreviouslyDeliveredNoType !== null
        ? elem?.sessionPreviouslyDeliveredNoType?.name
        : elem?.sessionPatientPreviouslyDeliveredOtherDescription,
    'Date of Engagement': elem.nextSession ? moment(elem.nextSession).format('DD-MM-Y') : '',
    'Verbal Consent': elem.consent === true ? 'Yes' : 'No',
    'Outcome of phone call': outcomofphonecellFormation(elem, pMedicine?.id),
    'Eligible condition': eligibleConditionCellFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Date of Intervention': moment(elem.actualInterventionDate).format('DD-MM-Y'),
    'Time of Intervention': moment(elem.actualInterventionDate).format('hh:mm:ss'),
    'Intervention question asked': elem.previouslyDelivered === true ? 'Yes' : 'No',
    'Matters identified with patient': medicineUsedAsPresrcibed(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Issue reported by patient': questionCellFormationPatient(
      elem.prescription.prescriptionMedicines,
      elem.sessionPatientReportedIssues,
      pMedicine?.id
    ),
    'Agreed Patient Actions': questionCellFormationPatient(
      elem.prescription.prescriptionMedicines,
      elem.sessionPatientAgreedActions,
      pMedicine?.id
    ),
    'Healthy living advice': questionCellFormation(
      elem.prescription.prescriptionMedicines,
      elem.sessionHealthyLivingAdvices,
      pMedicine?.id
    ),
    'Information Provided': questionCellFormation(
      elem.prescription.prescriptionMedicines,
      elem.sessionPatientProvidedInformations,
      pMedicine?.id
    ),
    'Action taken by Pharmacist': questionCellFormationActionTakenByPharmacist(
      elem.prescription.prescriptionMedicines,
      elem.sessionPharmacistAgreedActions.filter(({ sessionType }) => sessionType.id === NMS_TYPES.INTERVENTION),
      pMedicine?.id
    ),
    'Intervention Note': interventionNoteFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Follow up declined by patient': elem?.followUpDeclinedByPatient ? 'Yes' : 'No',
    'Follow up declined reason': elem?.followUpDeclinedByPatient
      ? elem?.declinedReason
      : elem?.notDeclinedReason === 'referred_to_gp'
      ? 'Referred to GP'
      : elem?.notDeclinedReason === 'medication_changed'
      ? 'Medication changed'
      : elem?.notDeclinedReason === 'medication_stopped_no_follow_up'
      ? 'Medication stopped by prescriber - follow up not required'
      : '',
    'Follow up date': elem?.prevSessionType.id === NMS_TYPES.FOLLOW_UP ? moment(elem?.followUpdDate).format('DD-MM-Y hh:mm:ss') : '',
    'Follow up Note': followupNoteFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'Follow up completed':
      elem?.prevSessionType.id === NMS_TYPES.FOLLOW_UP && elem?.autoCompletedFollowUp === false
        ? followUpCompletedCellFormation(
            elem.prescription.prescriptionMedicines,
            elem.sessionAdviceToPatients.filter(({ sessionType }) => sessionType.id === NMS_TYPES.FOLLOW_UP),
            elem.sessionPharmacistAgreedActions.filter(({ sessionType }) => sessionType.id === NMS_TYPES.FOLLOW_UP),
            pMedicine?.id
          )
        : elem?.prevSessionType.id === NMS_TYPES.FOLLOW_UP && elem?.autoCompletedFollowUp === true
        ? elem?.autoCompletedFollowUpComment
        : '',
    'Pharmacist name': pharmacistFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
    'GPHC Number': pharmacistGHPCFormation(elem.prescription.prescriptionMedicines, pMedicine?.id),
  };

  return rowObject;
};

export const filesData = (elem, index) => {
  const rowObject = {
    'File Name': elem.name,
    'Uploaded Date': moment(elem.createdDate).format('DD-MM-YYYY'),
    'Uploaded By': elem.createdBy,
  };
  return rowObject;
};

export const ManagePharmacyData = (elem, index) => {
  const rowObject = {
    'Pharmacy Name': elem.name,
    Address: elem.address,
    'Contact Number': elem.contact_number,
    'Gphc Number': elem.gphc_umber,
  };
  return rowObject;
};

export const deletedFilesData = (elem, index) => {
  const rowObject = {
    'File Name': elem.name,
    'Deleted Date': moment(elem.deleted_at).format('DD-MM-YYYY'),
    'Deleted By': elem.lastModifiedBy,
  };
  return rowObject;
};

export const pharmacyNMS = (elem, index) => {
  const data = {
    Store: elem.pharmacyname,
    'Total NMS done': elem.totalsessions,
  };
  return data;
};
export const pharmacyWithPharmacistNMS = (elem, index) => {
  const data = {
    Pharmacist: elem.internalusername,
    Store: elem.pharmacyname,
    'Total NMS done': elem.totalsessions,
  };
  return data;
};
export const deoSessionStatsCurrentDate = stats => {
  const data = {
    'Completed Interventions': stats?.completed_interventions,
    'Completed Follow-ups': stats?.completed_follow_ups,
    'No Answers': stats?.no_answers,
    'No Consents': stats?.no_consents,
    'Not New Medicine': 0,
    'Intervention Pending': stats?.intervention_pending,
    'Followup Pending': stats?.follow_up_pending,
    'Not Eligible': stats?.not_eligible,
  };
  return data;
};
export const followUpData = elem => {
  return {
    'Patient Name': elem.patient.firstName,
    'Patient Address': elem.patient.address,
    'Date of Birth': elem?.patient?.dateofBirth ? moment(elem?.patient?.dateofBirth).format('DD-MM-Y') : '',
    'GP Name': elem.gP.name,
    'GP Address': elem.gP.address,
    'GP Phone Number': elem.gP.telephone,
    'GP Email Address': elem.gP.email,
    'Contact number': elem.patient.telephone,
    'Secondary Contact number': elem.patient.secondary_telephone,
    'NHS Number': elem.patient.nhsNumber,
    Consent: elem.consent === true ? 'Yes' : 'No',
    Medicine: medicineCellFormation(elem.prescription.prescriptionMedicines),
    'Date of Engagement': elem.nextSession ? moment(elem.nextSession).format('DD-MM-Y') : '',
    'Using Medicine as Prescibed': patientUsingMedicineAsPrescibedCellFormation(elem.prescription.prescriptionMedicines),
    'Reason (Not Using)': questionNotUsingMedicineReasonFormation(elem.prescription.prescriptionMedicines),
    'Advice Given': questionCellFormation(
      elem.prescription.prescriptionMedicines,
      elem.sessionAdviceToPatients.filter(({ sessionType }) => sessionType.id === NMS_TYPES.FOLLOW_UP)
    ),
    'Issue Identified(ReferToGp)': questionCellFormationActionTakenByPharmacist(
      elem.prescription.prescriptionMedicines,
      elem.sessionPharmacistAgreedActions.filter(({ sessionType }) => sessionType.id === NMS_TYPES.FOLLOW_UP)
    ),
    'Pharmacist name': elem.appUser ? elem.appUser.internalUser.firstName : '',
    'GPHC Number': elem.appUser ? elem.appUser.gphcNumber : '',
  };
};
