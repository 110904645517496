import React from 'react';
import useListingTable from 'app/modules/deo-work-flow/hooks/useListingTable';
import moment from 'moment/moment';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
  OutlinedInput,
  InputLabel,
  TablePagination,
  Tooltip,
  Box,
  CircularProgress,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DailySummaryHook from 'app/modules/deo-work-flow/hooks/dailySummaryHook';
import { useAppSelector } from 'app/config/store';
import { useState } from 'react';
import { generalMonthArray } from 'app/shared/util/utits';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import './daily-summary.scss';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';
export const DailySummary = props => {
  const { states, handlers } = DailySummaryHook();
  const {
    allPharmacies,
    allPharmaciesOfUser,
    allServicesPharmacist,
    selectedPharmacies,
    searchCreteria,
    filteredPharmacies,
    allDeoSessionStatsCurrentDate,
    allGroupNames,
    checkedTabs,
  } = states;
  const {
    handleSelectService,
    handleSelectPharmacy,
    handleSelectBranch,
    handleExcelFileStats,
    handleRefresh,
    handleSelectMonth,
    handledateFrom,
    handledateTill,
    handleTabClick,
  } = handlers;

  const role = localStorage.getItem('role');
  const loading = useAppSelector(state => state.deo.unSubmitRecordDeleteLoading);
  const loadingStats = useAppSelector(state => state.deo.loading);
  const navigate = useNavigate();

  const formatDate = date => {
    if (date) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment().format('DD-MM-YYYY');
    }
  };
  const gridData = [
    {
      icon: '../../../../content/assets/completed-nms.png',
      iconWhite: '../../../../content/assets/completed-nms-white.png',
      heading: role === '"CLIENT"' ? 'Claimable NMS' : 'Completed NMS',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_nms
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/completed-interventions.png',
      iconWhite: '../../../../content/assets/completed-interventions-white.png',
      heading: 'Completed Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_intervention
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/completed-follow-up.png',
      iconWhite: '../../../../content/assets/completed-follow-up-white.png',
      heading: 'Completed Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_up
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/pending-interventions.png',
      iconWhite: '../../../../content/assets/pending-interventions-white.png',
      heading: 'Pending Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/pending-follow-up.png',
      iconWhite: '../../../../content/assets/pending-follow-up-white.png',
      heading: 'Pending Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].follow_up_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/intervention-not-delivered.png',
      iconWhite: '../../../../content/assets/intervention-not-delivered-white.png',
      heading: 'Intervention Not Delivered',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_not_delivered
        : 0,
    },
    {
      icon: '../../../../content/assets/no-answer.png',
      iconWhite: '../../../../content/assets/no-answer-white.png',
      heading: 'No Answers',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_answers : 0,
    },
    {
      icon: '../../../../content/assets/no-consents.png',
      iconWhite: '../../../../content/assets/no-consents-white.png',
      heading: 'No Consents',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_consents : 0,
    },
    {
      icon: '../../../../content/assets/not-new-medicine.png',
      iconWhite: '../../../../content/assets/not-new-medicine-white.png',
      heading: 'Not New Medicine',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_new_medicine
        : 0,
    },
    {
      icon: '../../../../content/assets/not-eligible-conditions.png',
      iconWhite: '../../../../content/assets/not-eligible-conditions-white.png',
      heading: 'Not Eligible Conditions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_eligible
        : 0,
    },
    {
      icon: '../../../../content/assets/patient-left-the-pharmacy.png',
      iconWhite: '../../../../content/assets/patient-left-the-pharmacy-white.png',
      heading: 'Patient Left Pharmacy',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].patient_left
        : 0,
    },
    {
      icon: '../../../../content/assets/incorrect-number.png',
      iconWhite: '../../../../content/assets/incorrect-number-white.png',
      heading: 'Incorrect Number',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].incorrect_number
        : 0,
    },
    // {
    //   icon: '../../../../content/assets/Total Roles.png',
    //   heading: 'InEligible',
    //   heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].in_eligible : 0,
    // },
  ];
  const handleFileUpload = () => {
    navigate('/operator/dashboard/session-deo-file-uploaded');
  };
  const renderFilter = () => {
    switch (role) {
      case '"MANAGER_DEO"':
      case '"LEAD_DEO"':
      case '"DEO"':
        return (
          <>
            <Grid container style={{ marginTop: '20px' }} spacing={2}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2.2}>
                  <Select
                    id="demo-simple-select"
                    value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
                    onChange={handleSelectBranch}
                    className={'mui-field-design-filter'}
                  >
                    <MenuItem value={'All Groups'}>All Groups</MenuItem>
                    {allGroupNames.map(elem => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* All Pharmacies Filter */}
                <Grid item xs={2.2}>
                  <Select
                    id="demo-simple-select"
                    value={searchCreteria.pharmacy === '' ? ['All Pharmacies'] : [Number(searchCreteria.pharmacy)]}
                    onChange={handleSelectPharmacy}
                    renderValue={selected => {
                      if (selected[0] === 'All Pharmacies') {
                        return 'All Pharmacies';
                      } else {
                        const selectedPharmacy = filteredPharmacies.find(pharmacy => pharmacy.id === selected[0]);
                        return selectedPharmacy ? selectedPharmacy.name : '';
                      }
                    }}
                    className={'clock mui-field-design-filter'}
                  >
                    <MenuItem value={['All Pharmacies']}>All Pharmacies</MenuItem>
                    {filteredPharmacies.map(elem => (
                      <MenuItem key={elem.id} value={[elem.id]}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={2.2}>
                  <Select
                    id="demo-simple-select"
                    value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
                    onChange={handleSelectService}
                    className={'clock mui-field-design-filter'}
                  >
                    <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
                    {allServicesPharmacist.map(elem => {
                      return (
                        <MenuItem key={elem.id} value={elem.id}>
                          {elem.firstName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={2.2}></Grid>
                <Grid item xs={3.2} style={{ display: 'flex', justifyContent: 'end', gap: 10 }}>
                  <Tooltip title="Upload">
                    <IconButton
                      onClick={handleFileUpload}
                      style={{
                        background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
                        height: 34,
                        width: 100,
                        padding: '0px',
                        display: 'flex',
                        borderRadius: 5,
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        marginLeft: 10, // Add margin here
                      }}
                    >
                      <h1 style={{ fontSize: 12, color: 'white', marginTop: 10 }}>Upload</h1>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2.2}>
                  <Tooltip title="Date From">
                    <div style={{ display: 'flex' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                          className="clock mui-field-design-filter"
                          format="DD/MM/YYYY"
                          value={searchCreteria.from === '' ? null : searchCreteria.from}
                          onChange={newValue => handledateFrom(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </Tooltip>
                </Grid>

                <Grid item xs={2.2}>
                  <Tooltip title="Date Till">
                    <div style={{ display: 'flex' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                          value={searchCreteria.to === '' ? null : searchCreteria.to}
                          className="clock mui-field-design-filter"
                          format="DD/MM/YYYY"
                          onChange={newValue => handledateTill(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      case '"ROLE_ADMIN"':
        return (
          <>
            <Grid container style={{ marginTop: '20px' }} spacing={2}>
              {/* Row 1 */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2.2}>
                  <Select
                    className={'mui-field-design-filter'}
                    id="demo-simple-select"
                    value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
                    onChange={handleSelectBranch}
                  >
                    <MenuItem value={'All Groups'}>All Groups</MenuItem>
                    {allGroupNames.map(elem => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2.2}>
                  <Select
                    className={'mui-field-design-filter'}
                    multiple
                    value={selectedPharmacies}
                    onChange={handleSelectPharmacy}
                    displayEmpty
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return 'All Pharmacies';
                      }
                      const selectedPharmacyNames = selected.map(id => {
                        const pharmacy = allPharmacies.find(pharmacy => pharmacy.id === id);
                        return pharmacy ? pharmacy.name : '';
                      });
                      return (
                        <div>
                          {selectedPharmacyNames.map(name => (
                            <Chip key={name} label={name} size="small" />
                          ))}
                        </div>
                      );
                    }}
                  >
                    <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
                    {filteredPharmacies.map(elem => (
                      <MenuItem key={elem.id} value={elem.id}>
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2.2}>
                  <Select
                    className={'selectFieldCss mui-field-design-filter'}
                    id="demo-simple-select"
                    value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
                    onChange={handleSelectService}
                  >
                    <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
                    {allServicesPharmacist.map(elem => {
                      return (
                        <MenuItem key={elem.id} value={elem.id}>
                          {elem.firstName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>

              {/* Row 2 */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2.2}>
                  <Select
                    className={'mui-field-design-filter'}
                    id="demo-simple-select"
                    value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                    onChange={handleSelectMonth}
                  >
                    {generalMonthArray.map((month, index) => (
                      <MenuItem key={index} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={2.2}>
                  <Tooltip title="Date From">
                    <div style={{ display: 'flex' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                          className="clock mui-field-design-filter"
                          format="DD/MM/YYYY"
                          value={searchCreteria.from === '' ? null : searchCreteria.from}
                          onChange={newValue => handledateFrom(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={2.2}>
                  <Tooltip title="Date Till">
                    <div style={{ display: 'flex' }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker
                          value={searchCreteria.to === '' ? null : searchCreteria.to}
                          className="clock mui-field-design-filter"
                          format="DD/MM/YYYY"
                          onChange={newValue => handledateTill(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      case '"CLIENT"':
        return (
          <>
            {/* Filters Section */}
            <Grid container style={{ marginTop: 20, gap: '10px', alignItems: 'center' }}>
              {/* Pharmacies Select */}
              <Grid item>
                <Select
                  className={'mui-field-design-filter'}
                  multiple
                  value={selectedPharmacies}
                  onChange={handleSelectPharmacy}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return 'All Pharmacies';
                    }
                    const selectedPharmacyNames = selected.map(id => {
                      const pharma = allPharmaciesOfUser.find(pharm => pharm.id === id);
                      return pharma ? pharma.name : '';
                    });
                    return (
                      <div>
                        {selectedPharmacyNames.map(name => (
                          <Chip key={name} label={name} size="small" style={{ marginRight: 5 }} />
                        ))}
                      </div>
                    );
                  }}
                >
                  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
                  {allPharmaciesOfUser.map(elem => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* Month Select */}
              <Grid item>
                <Select
                  id="demo-simple-select"
                  value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                  onChange={handleSelectMonth}
                  className={'mui-field-design-filter'}
                >
                  {generalMonthArray.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </>
        );
      default:
        null;
    }
  };
  const { showMenu, handleDashboardSwitch } = useDashboardContext();
  return (
    <Grid
      lg={9.7}
      item
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: '0.4%',
        marginLeft: showMenu ? '12%' : '20%',
        marginRight: showMenu ? '0%' : '5%',
        transition: 'ease 0.5s',
      }}
    >
      <Grid
        container
        style={{
          width: '100%',
          backgroundColor: '#F5F6FA',
          marginTop: 30,
          alignItems: 'flex-start',
          borderRadius: 8,
        }}
      >
        {/* Today's Date with Icon */}
        <Grid item lg={3} xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <img src="../../../../content/assets/calendar.png" alt="refresh" style={{ height: '20px', marginRight: 8 }} />
          <h1
            style={{
              fontSize: 15,
              color: '#333',
              margin: 0,
              fontWeight: 550,
            }}
          >
            {'Today - ' + moment().format('DD MMMM, YYYY')}
          </h1>
        </Grid>

        {/* Spacer */}
        <Grid item lg={6} xs={6} />

        {/* Buttons Section */}
        <Grid
          item
          lg={3}
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '10px',
            marginBottom: 10,
          }}
        >
          {/* Download Button */}
          <Tooltip title="Download Stats">
            <IconButton
              onClick={handleExcelFileStats}
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src="../../../../content/assets/download.png" alt="refresh" style={{ height: '30px' }} />
            </IconButton>
          </Tooltip>
          {/* Refresh Button */}
          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src="../../../../content/assets/refresh.png" alt="refresh" style={{ height: '30px' }} />
              {/*<RefreshIcon style={{ color: '#FFF', fontSize: 20 }} />*/}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {renderFilter()}
      {/*<Grid className={'daily-overview-tile'} lg={12} xs={4} style={{ width: '100%', marginBottom: 10, backgroundColor: '#F5F6FA' }}>*/}
      {/*  {gridData.map((item, index) =>*/}
      {/*    index % 4 === 0 ? (*/}
      {/*      <Grid container key={index} style={{ width: '100%', justifyContent: 'space-between' }}>*/}
      {/*        {gridData.slice(index, index + 4).map((subItem, subIndex) => {*/}
      {/*          const isChecked = checkedTabs.includes(subItem.heading);*/}
      {/*          return (*/}
      {/*            <Grid*/}
      {/*              key={subIndex}*/}
      {/*              lg={2.7}*/}
      {/*              style={{*/}
      {/*                height: 140,*/}
      {/*                justifyContent: 'center',*/}
      {/*                background: isChecked*/}
      {/*                  ? 'linear-gradient(135deg, #d6d6d6, #b0bec5)' // Selected gradient*/}
      {/*                  : 'linear-gradient(135deg, #e3f2fd, #bbdefb)', // Default gradient*/}
      {/*                marginTop: 40,*/}
      {/*                cursor: 'pointer',*/}
      {/*                borderRadius: 8,*/}
      {/*                transition: 'all 0.3s ease', // Smooth hover and selection effect*/}
      {/*                boxShadow: isChecked*/}
      {/*                  ? '0px 8px 16px rgba(0, 123, 255, 0.3)' // Selected shadow*/}
      {/*                  : '0px 4px 8px rgba(0, 0, 0, 0.1)', // Default shadow*/}
      {/*                ...(subIndex !== 3 ? { marginRight: 20 } : {}),*/}
      {/*              }}*/}
      {/*              onClick={() => handleTabClick(subItem.heading)}*/}
      {/*              onMouseEnter={e => {*/}
      {/*                e.currentTarget.style.filter = 'brightness(1.1)'; // Hover brightness*/}
      {/*                e.currentTarget.style.boxShadow = '0px 8px 16px rgba(0, 0, 0, 0.2)'; // Hover shadow*/}
      {/*              }}*/}
      {/*              onMouseLeave={e => {*/}
      {/*                e.currentTarget.style.filter = 'brightness(1)'; // Reset brightness*/}
      {/*                e.currentTarget.style.boxShadow = isChecked*/}
      {/*                  ? '0px 8px 16px rgba(0, 123, 255, 0.3)' // Retain selected shadow*/}
      {/*                  : '0px 4px 8px rgba(0, 0, 0, 0.1)'; // Reset shadow*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <div*/}
      {/*                style={{*/}
      {/*                  width: '100%',*/}
      {/*                  height: 100,*/}
      {/*                  display: 'flex',*/}
      {/*                  flexDirection: 'column',*/}
      {/*                  alignItems: 'center',*/}
      {/*                  justifyContent: 'center',*/}
      {/*                  marginTop: 20,*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                <div*/}
      {/*                  style={{*/}
      {/*                    width: '100%',*/}
      {/*                    height: 100,*/}
      {/*                    display: 'flex',*/}
      {/*                    flexDirection: 'column',*/}
      {/*                    alignItems: 'center',*/}
      {/*                    justifyContent: 'center',*/}
      {/*                    marginTop: 20,*/}
      {/*                  }}*/}
      {/*                >*/}
      {/*                  <img src={subItem.icon} alt="logo" style={{ height: '40px' }} />*/}
      {/*                  <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{subItem.heading}</h1>*/}
      {/*                  <h1 style={{ fontSize: 17, marginTop: 5 }}>{loadingStats ? <CircularProgress /> : subItem.heading2}</h1>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </Grid>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </Grid>*/}
      {/*    ) : null*/}
      {/*  )}*/}
      {/*</Grid>*/}

      <Grid className={'daily-overview-tile'} lg={12} xs={4} style={{ width: '100%', marginBottom: 10, backgroundColor: '#F5F6FA' }}>
        {gridData.map((item, index) =>
          index % 4 === 0 ? (
            <Grid container key={index} style={{ width: '100%', justifyContent: 'space-between' }}>
              {gridData.slice(index, index + 4).map((subItem, subIndex) => {
                const isChecked = checkedTabs.includes(subItem.heading);
                return (
                  <Grid
                    key={subIndex}
                    lg={2.7}
                    style={{
                      height: 140,
                      justifyContent: 'center',
                      border: '0.2px solid rgb(187 216 254)',
                      background: isChecked
                        ? 'transparent linear-gradient(110deg, #76CF50 0%, #529038 100%) 0% 0% no-repeat padding-box' // Selected gradient
                        : 'white', // Default gradient
                      marginTop: 40,
                      cursor: 'pointer',
                      borderRadius: '6px',
                      transition: 'all 0.3s ease', // Smooth hover and selection effect
                      boxShadow: isChecked
                        ? '0px 8px 16px rgba(0, 0, 0, 0.2)' // Selected shadow
                        : '0px 4px 8px rgba(0, 0, 0, 0.1)', // Default shadow
                      ...(subIndex !== 3 ? { marginRight: 20 } : {}),
                    }}
                    onClick={() => handleTabClick(subItem.heading)}
                    onMouseEnter={e => {
                      e.currentTarget.style.filter = 'brightness(1.1)'; // Hover brightness
                      e.currentTarget.style.boxShadow = '0px 8px 16px rgba(0, 0, 0, 0.2)'; // Hover shadow
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.filter = 'brightness(1)'; // Reset brightness
                      e.currentTarget.style.boxShadow = isChecked
                        ? '0px 8px 16px rgba(0, 0, 0, 0.2)' // Retain selected shadow
                        : '0px 4px 8px rgba(0, 0, 0, 0.1)'; // Reset shadow
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: 25,
                      }}
                    >
                      {/* Icon and Heading */}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <img src={isChecked ? subItem.iconWhite : subItem.icon} alt="logo" style={{ height: '40px' }} />
                        <h1 style={{ fontSize: 25, color: isChecked ? 'white' : '#004DB2', fontWeight: 600 }}>
                          {loadingStats ? <CircularProgress /> : subItem.heading2}
                        </h1>
                      </div>

                      {/* Heading2 */}
                      <h1
                        style={{
                          fontSize: 15,
                          color: isChecked ? 'white' : '#000000',
                          textAlign: 'left',
                          fontWeight: '550',
                          marginTop: 10,
                          textTransform: 'uppercase',
                        }}
                      >
                        {subItem.heading}
                      </h1>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          ) : null
        )}
      </Grid>

      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default DailySummary;
