import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ISession } from 'app/shared/model/session.model';
import { createEntitySlice } from 'app/shared/reducers/reducer.utils';
import { IPharmacySessionList } from '../admin-work-flow/interface';

const initialState = {
  count: 0,
  results: [],
  excelFileRecords: [],
  rows: 50,
  page: 0,
  allUserPharmacies: [],
  loading: false,
  error: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
  totalCountIntervention: 0,
  totalCountFollowUp: 0,
  totalCount: 0,
};

// Actions
const apiUrlGetAllClientStats = 'api/client/get-all-stats';
const apiUrlGetAllPharmacy = 'api/client/get-all-pharmacy';
const apiUrlDownloadAllSessionList = 'api/client/download-all-pharmacy-session';

const apiUrlGetAllClientPharmacySession = 'api/client/get-all-pharmacy-session';

export const getAllClientStats = createAsyncThunk('client/fetch_all_stats', async () => {
  const requestUrl = `${apiUrlGetAllClientStats}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getAllPharmacyByUserIdList = createAsyncThunk('client/fetch_all_pharmacy', async () => {
  const requestUrl = `${apiUrlGetAllPharmacy}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getAllSessionOfPharmacyWithDateRange = createAsyncThunk(
  'client/fetch_all_session_pharmacy',
  async (params: IPharmacySessionList) => {
    const { page, size, searchQuery, pharmacy, month, from, to } = params;
    const requestUrl = `${apiUrlGetAllClientPharmacySession}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&month=${month}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
    return axios.get<ISession[]>(requestUrl);
  }
);

export const downloadAllSessionListForPharmacy = createAsyncThunk(
  'client/download_all_session_pharmacy',
  async (params: IPharmacySessionList) => {
    const { searchQuery, pharmacy, month, from, to } = params;
    const requestUrl = `${apiUrlDownloadAllSessionList}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&month=${month}&from=${from}&to=${to}&searchQuery=${searchQuery}`;
    return axios.get<ISession[]>(requestUrl);
  }
);

export const ClientSlice = createEntitySlice({
  name: 'client',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllClientStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          totalCountIntervention: action.payload.data.totalCountIntervention,
          totalCountFollowUp: action.payload.data.totalCountFollowUp,
          totalCount: action.payload.data.totalCount,
        };
      })
      .addCase(getAllSessionOfPharmacyWithDateRange.fulfilled, (state, action: any) => {
        return {
          ...state,
          results: action.payload.data.results,
          count: action.payload.data.count,
          rows: action.payload.data.rows,
          page: action.payload.data.page,
        };
      })
      .addCase(getAllPharmacyByUserIdList.fulfilled, (state, action: any) => {
        return {
          ...state,
          allUserPharmacies: action.payload.data,
        };
      })
      .addCase(downloadAllSessionListForPharmacy.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelFileRecords: action.payload.data.results,
        };
      });
  },
});

export const { reset } = ClientSlice.actions;

// Reducer
export default ClientSlice.reducer;
