import { NMS_TYPES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  followUpData,
  generateExcelData,
  interventionData,
  isValidDate,
  pharmacyNMS,
  pharmacyWithPharmacistNMS,
  s2ab,
} from 'app/shared/util/utits';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  downloadExcelFileRecords,
  downloadExcelPharmacyNMSRecords,
  getAllPharmacies,
  getAllServicePharmacist,
  getAllSessionsListingWithDateRange,
  getSessionStatuses,
  getGroupNames,
  deleteUserwithRoles,
  assignPharmacy,
  listAllPharmacy,
  updateFollowUpDate,
} from '../admin-reducer';
import { IAssignPharmacy, ISessionFollowUP, ISessionParam } from '../interface';
import {
  getAllDeoOverviewSessions,
  getAllDeoSessionsCurrentDateStats,
  getDeletedSessions,
  getRecordsStatsForDeo,
  permanentDeleteRecord,
  updatePatientConsent,
} from 'app/modules/deo-work-flow/deo-reducer';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const intialState: ISessionParam = {
  size: 50,
  page: 0,
  searchQuery: '',
  servicePharmacist: '',
  pharmacy: '',
  status: '',
  group: '',
  from: '',
  to: '',
  month: '',
};

const initialStateFollowUp = {
  followUpdDate: dayjs(),
};

const useListingTable = () => {
  const pageNo = useAppSelector(state => state.admin.page);
  const limit = useAppSelector(state => state.admin.rows);
  const totalCount = useAppSelector(state => state.admin.count);

  const allPharmacies = useAppSelector(state => state.admin.allPharmacies);
  const allServicesPharmacist = useAppSelector(state => state.admin.allServicePharmacist);

  const allSessionListingIndex = useAppSelector(state => state.admin.results);

  const renderSessionStatus = item => {
    const statusName = item?.sessionStatus?.name;
    const prevSessionTypeName = item?.prevSessionType?.name;

    switch (statusName) {
      case 'completed':
        return 'Completed';
      case 'not_reacheable':
        return 'No Answered';
      case 'no_consent':
        return 'No Consent';
      case 'in_eligible':
        return 'Ineligible';
      case 'patient_left':
        return 'Patient Left Pharmacy';
      case 'incorrect_number':
        return 'Incorrect Number';
      case 'intervention_not_delivered':
        return 'Intervention Not Delivered';
      case 'in_progress':
        if (prevSessionTypeName === 'intervention') {
          return 'Pending Intervention';
        } else if (prevSessionTypeName === 'follow_up') {
          return 'Pending Follow-ups';
        }
        break;
      default:
        return '-';
    }
  };
  const allSessionListing = allSessionListingIndex?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
      sessionStatus: renderSessionStatus(item),
    };
  });

  const [searchCreteria, setsearchCreteria] = useState<ISessionParam>(intialState);
  const [initialStateFollow, setInitialStateFollow] = useState(initialStateFollowUp);
  const [downloadData, setDownloadData] = useState([]);
  const dispatch = useAppDispatch();
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const currentMonth = moment().format('MMMM');
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [open, setOpen] = useState(false);
  const [userId, setUserID] = useState();
  const [editUserId, setEditUserID] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);

  const emptyRowCount = 10;
  const emptyCellCount = 8;

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  useEffect(() => {
    dispatch(getGroupNames());
  }, []);

  useEffect(() => {
    setFilteredPharmacies(allPharmacies);
  }, [allPharmacies]);

  const setUserIdAndOpenDialogue = useCallback(id => {
    setUserID(id);
    setOpen(true);
  }, []);

  const handleEdit = useCallback(item => {
    navigate('/operator/edit-patient/' + item.id);
    localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'three', index: 3 }));
    // setEditUserID(item?.id);
    // setInitialStateFollow({
    //   followUpdDate: dayjs(item?.followUpdDate),
    // });
    // setOpenEditModal(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleDelete = useCallback(id => {
    if (id !== '') {
      dispatch(permanentDeleteRecord(id))
        .then(result => {
          dispatch(getAllSessionsListingWithDateRange(searchCreteria));
        })
        .catch(error => {
          console.error('Error deleting record: ', error);
        })
        .finally(() => {
          handleClose();
        });
    }
  }, []);
  const handleConsent = useCallback(id => {
    if (id !== '') {
      dispatch(updatePatientConsent(id))
        .then(result => {
          const params = {
            ...searchCreteria,
            status: 'No Consent',
          };
          dispatch(getAllSessionsListingWithDateRange(params));
        })
        .catch(error => {
          console.error('Error deleting record: ', error);
        });
    }
  }, []);

  const onSubmit = useCallback(
    async params => {
      const data: ISessionFollowUP = {
        id: editUserId,
        followUpdDate: params.followUpdDate,
      };
      const res = await dispatch(updateFollowUpDate(data))
        .then(result => {
          const message = result?.payload['data']?.message;
          if (result?.payload['status'] === 200) {
            toast.success(message);
          }
        })
        .catch(error => {
          console.error('Error updating record: ', error);
        })
        .finally(() => {
          setsearchCreteria(intialState);
          dispatch(getAllSessionsListingWithDateRange(intialState));
          setOpenEditModal(false);
        });
    },
    [editUserId]
  );

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };

    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  // const handleSelectPharmacy = event => {
  //   let value = event.target.value;
  //   value = value === 'All Pharmacies' ? '' : value;
  //   const params = {
  //     ...searchCreteria,
  //     pharmacy: value,
  //   };
  //   setsearchCreteria({
  //     ...searchCreteria,
  //     pharmacy: value,
  //   });
  //   dispatch(getAllSessionsListingWithDateRange(params));
  // };

  const handleSelectPharmacy = event => {
    const { value } = event.target;

    let updatedSelections;

    if (Array.isArray(value) && value.includes('All Pharmacies')) {
      updatedSelections = [];
    } else {
      updatedSelections = value;
    }

    setSelectedPharmacies(updatedSelections);

    const params = {
      ...searchCreteria,
      pharmacy: updatedSelections.length > 0 ? updatedSelections : '',
    };

    setsearchCreteria(params);

    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const handleSelectStatus = event => {
    let value = event.target.value;
    value = value === 'Status' ? '' : value;
    const params = {
      ...searchCreteria,
      status: value,
      month: value !== 'Completed' || value !== 'Completed Interventions' || value !== 'Completed Follow-ups' ? '' : searchCreteria?.month,
    };
    setsearchCreteria({
      ...searchCreteria,
      status: value,
      month: value !== 'Completed' || value !== 'Completed Interventions' || value !== 'Completed Follow-ups' ? '' : searchCreteria?.month,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const handleSelectMonth = event => {
    let value = event.target.value;
    value = value === 'All Months' ? '' : value;
    const params = {
      ...searchCreteria,
      month: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      month: value,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const handleSelectGroup = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });

    if (value && value !== '' && value !== 'All Groups') {
      const filteredPharmacies = allPharmacies.filter(pharmacy => pharmacy.pharmacyGroup && pharmacy.pharmacyGroup.id === value);
      setFilteredPharmacies(filteredPharmacies);
    } else {
      setFilteredPharmacies(allPharmacies);
    }

    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const handleSelectService = event => {
    let value = event.target.value;
    value = value === 'All Service Pharmacist' ? '' : value;

    const params = {
      ...searchCreteria,
      servicePharmacist: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      servicePharmacist: value,
    });
    dispatch(getAllSessionsListingWithDateRange(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialState,
    };

    setsearchCreteria({
      ...intialState,
      servicePharmacist: '',
      pharmacy: '',
      from: '',
      to: '',
      searchQuery: '',
    });
    setSelectedPharmacies([]);

    dispatch(getAllSessionsListingWithDateRange(params));
  }, []);

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(downloadExcelFileRecords(params));
    if (response.type === 'session/download_excel_file_records/fulfilled') {
      if (response.payload?.data['message']) {
        toast.error(response.payload?.data['message']);
        return;
      }

      // Group data by pharmacy
      const groupedByPharmacy = response.payload?.data.reduce((acc, record) => {
        const pharmacyName = record.pharmacy.name; // Adjust this if the path to the pharmacy name is different
        if (!acc[pharmacyName]) {
          acc[pharmacyName] = [];
        }
        acc[pharmacyName].push(record);
        return acc;
      }, {} as Record<string, typeof response.payload.data>);

      // Generate and download a file for each pharmacy
      Object.keys(groupedByPharmacy).forEach(pharmacyName => {
        const pharmacyData = groupedByPharmacy[pharmacyName];

        // Group data by status for each pharmacy
        const groupedData = pharmacyData.reduce((acc, record) => {
          const status = searchCreteria.status
            ? searchCreteria.status
            : record.sessionStatus.name === 'completed'
            ? 'Completed'
            : record.sessionStatus.name;
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(record);
          return acc;
        }, {} as Record<string, typeof pharmacyData>);

        // Create a new workbook and generate the data for the current pharmacy
        const wb = XLSX.utils.book_new();
        generateExcelData(groupedData, wb);

        // Write the workbook to a binary string
        const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Create a blob and download the file
        const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${pharmacyName} - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }, [searchCreteria]);

  // const getDownloadData = useCallback(async () => {
  //   const params = {
  //     ...searchCreteria,
  //   };
  //   const response: any = await dispatch(downloadExcelFileRecords(params));
  //   if (response.type === 'session/download_excel_file_records/fulfilled') {
  //
  //     if (response?.payload?.data['message']){
  //       toast.error(response?.payload?.data['message'])
  //       return;
  //     }
  //
  //     // setDownloadData(response.payload.data.results);
  //     const groupedData = response.payload?.data.reduce((acc, record) => {
  //       const status = searchCreteria.status
  //         ? searchCreteria.status
  //         : record.sessionStatus.name === 'completed'
  //         ? 'Completed'
  //         : record.sessionStatus.name;
  //       if (!acc[status]) {
  //         acc[status] = [];
  //       }
  //       acc[status].push(record);
  //       return acc;
  //     }, {});
  //
  //     const wb = XLSX.utils.book_new();
  //     generateExcelData(groupedData, wb);
  //
  //     const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  //
  //     const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `Overview - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // }, [searchCreteria]);

  // const handleExcelFileRecords = useCallback(() => {
  //   const groupedData = downloadData.reduce((acc, record) => {
  //     const status = record.sessionStatus.name;
  //     if (!acc[status]) {
  //       acc[status] = [];
  //     }
  //     acc[status].push(record);
  //     return acc;
  //   }, {});
  //
  //   const wb = XLSX.utils.book_new();
  //   generateExcelData(groupedData, wb);
  //
  //   const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  //
  //   const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = `Completed NMS.xlsx`; // Set the file name here
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }, [downloadData]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const handleExcelPharmacyNMSRecords = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };

    const response: any = await dispatch(downloadExcelPharmacyNMSRecords(params));

    if (response.type === 'session/download_excel_file_pharmacy_nms_records/fulfilled') {
      const { results: excelDataList } = response.payload.data;
      const data = excelDataList;
      let pharamcy_nms;
      let totalNMS = 0;
      if (params.pharmacy === '' && params.servicePharmacist === '') {
        pharamcy_nms = data.map((elem, index) => {
          totalNMS += Number(elem.totalsessions);
          return pharmacyNMS(elem, index);
        });
      } else {
        pharamcy_nms = data.map((elem, index) => {
          totalNMS += Number(elem.totalsessions);
          return pharmacyWithPharmacistNMS(elem, index);
        });
      }

      const totalRow = { '': 'Total', 'Total NMS done': totalNMS };
      pharamcy_nms.push(totalRow);

      // // Create a worksheets
      const ws = XLSX.utils.json_to_sheet(pharamcy_nms);

      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef] ? ws[cellRef].v.toString() : '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Completed NMS BY Pharmacy');
      // XLSX.utils.book_append_sheet(wb, ws2, 'FollowUp');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Total NMS.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  // call for getting All pharmacy,service pharmacist & SessionListing

  const getAllPharmacy = useCallback(() => {
    dispatch(getAllPharmacies());
  }, []);

  const getAllSessionStatuses = useCallback(() => {
    dispatch(getSessionStatuses());
  }, []);

  const getAllServicePharmacit = useCallback(() => {
    dispatch(getAllServicePharmacist());
  }, []);

  // const getAllSessionWithDateRange = useCallback(() => {
  //   dispatch(getAllSessionsListingWithDateRange(searchCreteria));
  // }, []);

  useEffect(() => {
    getAllPharmacy();
    getAllServicePharmacit();
    getAllSessionStatuses();
    // getAllSessionWithDateRange();
    localStorage.setItem('month', currentMonth);
  }, []);

  // useEffect(() => {
  //   getDownloadData();
  // }, [searchCreteria]);

  const states = {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allServicesPharmacist,
    allSessionListing,
    allGroupNames,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    filteredPharmacies,
    selectedMonth,
    userId,
    open,
    openEditModal,
    initialStateFollow,
    selectedPharmacies,
  };
  const handlers = {
    getDownloadData,
    handleExcelPharmacyNMSRecords,
    handleRefresh,
    handleSelectService,
    handleSelectPharmacy,
    handleSelectStatus,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handledateFrom,
    handledateTill,
    handleSelectMonth,
    setUserIdAndOpenDialogue,
    handleEdit,
    handleClose,
    handleDelete,
    handleCloseEditModal,
    onSubmit,
    handleConsent,
  };

  return {
    states,
    handlers,
  };
};

export default useListingTable;
