import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { getAllSessionsListingWithDateRange, getAllStats, getRecentClients, getRecentRoles, listRecentPharmacy } from '../admin-reducer';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { isValidDate, s2ab } from 'app/shared/util/utits';
import { ISessionParam, IStatsParam } from 'app/modules/admin-work-flow/interface';
const intialState: ISessionParam = {
  from: '',
  to: '',
};
const useOverviewScreen = () => {
  const stats = useAppSelector(state => state.admin.stats);
  const recentListRoles = useAppSelector(state => state.admin.recentRoles);
  const recentListPharmacies = useAppSelector(state => state.admin.recentPharmacies);
  const recentRoles = recentListRoles?.map((item, index) => {
    const { internalUser } = item;
    const { authorities } = internalUser;

    const updatedAuthorities = authorities.map(authority => {
      if (authority.name === 'CLIENT') {
        return { ...authority, name: 'Pharmacy Contractor' };
      } else if (authority.name === 'ROLE_ADMIN') {
        return { ...authority, name: 'Admin' };
      } else if (authority.name === 'MANAGER_DEO') {
        return { ...authority, name: 'Manager DEO' };
      } else if (authority.name === 'LEAD_DEO') {
        return { ...authority, name: 'Lead DEO' };
      }

      return authority;
    });

    const formattedAuthorities = updatedAuthorities.map(auth => auth.name).join(', ');

    return {
      ...item,
      index: index + 1,
      authorities: updatedAuthorities,
      formattedAuthorities, // Add formatted authorities
    };
  });
  const recentClients = useAppSelector(state => state.admin.recentClients);
  const [itemsData, setItemsData] = useState([]);
  const [pharmacyData, setPharmacyData] = useState([]);
  const [searchFilter, setSearchFilter] = useState('UntillNow');
  const [searchCreteria, setsearchCreteria] = useState<ISessionParam>(intialState);
  const dispatch = useAppDispatch();

  // const fetchAllStats = useCallback((search: string) => {
  //   setSearchFilter(search);
  //   dispatch(getAllStats({ searchFilter: search }));
  // }, []);

  const getAllRecentRoles = useCallback(() => {
    dispatch(getRecentRoles());
  }, []);

  const handleRefresh = useCallback(() => {
    setsearchCreteria({
      from: '',
      to: '',
    });
    getAllStatsData();
  }, []);

  const getAllRecentPharmracies = useCallback(async () => {
    const res: any = await dispatch(listRecentPharmacy());
    if (res.type === 'manage_pharmacy/list_recent_pharmacy/fulfilled') {
      const result = res.payload.data.results;
      const items = result.map(elem => {
        return {
          logoSrc: '../../../../content/assets/unnamed-1-2.png',
          name: elem.name,
          date: moment(elem.createdDate).format('DD-MMMM-Y'),
        };
      });
      setPharmacyData(items);
    }
  }, []);

  const getAllRecentClients = useCallback(async () => {
    const res: any = await dispatch(getRecentClients());
    if (res.type === 'overviewScreen/get_recent_clients/fulfilled') {
      const result = res.payload.data.result;
      const items = result.map(elem => {
        return {
          logoSrc: '../../../../content/assets/unnamed-1-2.png',
          name: elem.internalUser.firstName,
          date: moment(elem.lastModifiedDate).format('DD-MMMM-Y'),
        };
      });
      setItemsData(items);
    }
  }, []);

  const downloadSummaryInExcelFile = useCallback(() => {
    const data = [
      {
        'Total Clients': stats.totalClients,
        'Completed NMS': Number(stats.totalFollowUp) + Number(stats.totalIntervention),
        'Completed Interventions': Number(stats.totalCompletedIntervention),
        'Total Roles': stats.totalRoles,
      },
    ];

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Summary');

    // Generate XLSX data in binary form
    const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary data to a Blob
    const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link for the Excel file
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'stats_summary.xlsx'; // Set the file name here
    document.body.appendChild(a);
    a.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  }, [stats]);
  const getAllStatsData = useCallback(() => {
    dispatch(getAllStats(<IStatsParam>searchCreteria));
  }, []);
  useEffect(() => {
    getAllStatsData();
    getAllRecentRoles();
    getAllRecentPharmracies();
    getAllRecentClients();
  }, []);

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllStats(<IStatsParam>params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllStats(<IStatsParam>params));
  };

  const states = {
    stats,
    recentClients,
    recentRoles,
    searchFilter,
    itemsData,
    pharmacyData,
    searchCreteria,
  };
  const handlers = {
    // fetchAllStats,
    downloadSummaryInExcelFile,
    handledateFrom,
    handledateTill,
    handleRefresh,
  };

  return { states, handlers };
};

export default useOverviewScreen;
