import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISession, defaultValue } from 'app/shared/model/session.model';
import { toast } from 'react-toastify';
import loading = toast.loading;

const initialState = {
  loading: false,
  successMessage: null,
  errorMessage: null,
  requestStatus: null,
  payloadStatus: null,
  fileData: null,
  entities: [],
  deoSessionEntities: [],
  sessionByPharmacyEntities: [],
  pharmacistReferBackTypes: [],
  sessionCounts: [],
  medicineConditionsList: [],
  invalidRecordCount: null,
  validRecordCount: null,
  reviewedMedicineResponse: null,
  entity: defaultValue,
  deoSessionEntity: null,
  updating: false,
  updateSuccess: false,
  session_status: null,
  session_type: null,
  pharmacyDetail: null,
  pageNo: 0,
  rows: 50,
  totalCount: null,
};
interface ISessionListParams {
  sort: any;
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  rows: any;
  q: any;
}
interface IGPDetail {
  session_id: number | string;
  name: string;
  email: string;
  telephone: string;
  address: string;
  referDetail: string;
  referDetailComments: string;
  referDetailTypes: Array<any>;
}
interface IDeoSessionListParam {
  isValid: any;
  fileId: any;
  pageNo: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
  session_status: any;
  session_type: any;
  pageNo: null;
  rows: null;
  sort: any;
}
interface ISessionCallAttempt {
  id: any;
  callAttempt: any;
  session_status: any;
  session_type: any;
  pageNo: null;
  rows: null;
  sort: any;
}
interface IUploadData {
  file: File | null | undefined;
}
interface IReviewSession {
  session_id: number | string | null;
  sessionStatus: number | string | null;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  nextSession: Date;
  nextSessionTime: Date;
  sessionConsultationMethod: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}
interface IReviewSessionMedicine {
  session_id: number | string;
  medicine_id: number | string;
  prescription_medicine_id: number | string;
  medicineUsedAsPrescribed: boolean;
  sessionMedicineUsedAsPrescribedNoType: number | string | null;
  patientReportedIssues: Array<any>;
  adviceToPatient: Array<any>;
  adviceToPatientOther: number | string | null;
  patientAgreedAction: Array<any>;
  agreedPharmacistAction: Array<any>;
  healthyLivingAdvice: Array<any>;
  pharmacistAgreedReferBack: number | string | null;
  pharmacistAgreedReferBackId: number | string | null;
  agreedPharmacistActionOther: number | string | null;
  healthyLivingAdviceOther: number | string | null;
  patientReportedIssuesOther: number | string | null;
  pharmacistAgreedReferBackValue: number | string | null;
  pharmacistAgreedReferBackOther: number | string | null;
  patientAgreedActionOther: number | string | null;
}
interface IReviewInEligibleSession {
  session_id: number | string;
  medicine_id: number | string;
  prescription_medicine_id: number | string;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  sessionConsultationMethod: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}
interface IReviewInterventionNotDeliveredSession {
  session_id: number | string;
  sessionPreviouslyDeliveredOtherReason: number | string | null;
  previouslyDelivered: boolean;
  sessionConsultationMethod: any;
  sessionPreviouslyDeliveredNoType: any;
  lastModifiedDate: Date;
  lastModifiedDateTime: Date;
}
interface IDeoSession {
  id: number | string | null;
  prescriptionMedicineId: number | string | null;
  fullName: any;
  dateofBirth: Date;
  address: any;
  telephone: any;
  // gpName: any;
  // gpAddress: any;
  // gpEmail: any;
  // gpTelephone: any;
  nhsNumber: any;
  medicineArray: Array<any>;
}

type MedicineUsedAsPrescribedFollowUp = number | Array<any> | null;
type patientReportedIssuesOther = any | null;
type adviceProvidedOtherIntervention = any | null;
type patientAgreedActionOther = any | null;
type agreedPharmacistActionOther = any | null;
type pharmacistAgreedReferBackOtherIntervention = any | null;
type healthyLivingAdviceOther = any | null;

interface IDeoMedicineSessionPayload {
  prescriptionMedicineId: number | null;
  id: number | string | null;
  actualFollowUpDate: string | null;
  actualFollowUpTime: string | null;
  followUpDate: string | null;
  followUpTime: string | null;

  lastModifiedDate: string | null;
  lastModifiedDateTime: string | null;
  nextSession: string | null;
  nextSessionTime: string | null;
}
export type SessionState = Readonly<typeof initialState>;

const apiUrl = 'api/sessions';
const apiUrlPharmacy = 'api/pharmacies';
const apiUrlCreate = 'api/sessions/patient/create';
const referBackApiUrl = 'api/pharmacist-agreed-action-refer-back-types';
// Actions

export const getEntities = createAsyncThunk('session/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<ISession[]>(requestUrl);
});

export const getPharmacistAgreedActionReferBackTypes = createAsyncThunk(
  'session/pharmacist-agreed-action-refer-back-types',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${referBackApiUrl}?cacheBuster=${new Date().getTime()}`;
    return axios.get(requestUrl);
  }
);
export const getAllSessionsByPharmacy = createAsyncThunk(
  'session/fetch_all_entity_list_by_pharmacy',
  async (iSessionListParam: ISessionListParams) => {
    const sortParam = encodeURIComponent(JSON.stringify(iSessionListParam.sort));

    const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}&sort=${sortParam}&service_id=${
      iSessionListParam.service_id
    }&session_status=${iSessionListParam.session_status}&session_type=${iSessionListParam.session_type}&rows=${
      iSessionListParam.rows
    }&page=${iSessionListParam.pageNo}&q=${iSessionListParam.q}&pharmacist_type=${JSON.parse(localStorage.getItem('pharmacist_type'))}`;
    return axios.get<ISession[]>(requestUrl, cleanEntity(iSessionListParam));
  }
);
export const getDeoEntities = createAsyncThunk(
  'session/fetch_all_entity_list_by_pharmacy_of_deo',
  async (iDeoSessionListParam: IDeoSessionListParam) => {
    const requestUrl = `${apiUrl}/operator/sessions/?cacheBuster=${new Date().getTime()}&isValid=${iDeoSessionListParam.isValid}&fileId=${
      iDeoSessionListParam.fileId
    }&rows=${iDeoSessionListParam.rows}&page=${iDeoSessionListParam.pageNo}&q=${iDeoSessionListParam.q}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const getMedicineConditions = createAsyncThunk('session/medicine_conditions', async () => {
  const requestUrl = `${apiUrl}/medicine-conditions?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getEntity = createAsyncThunk(
  'session/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISession>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getPharmacyById = createAsyncThunk(
  'session/fetch_pharmacy_by_id',
  async (id: string | number) => {
    const requestUrl = `${apiUrlPharmacy}/${id}`;
    return axios.get<ISession>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getDeoEntity = createAsyncThunk(
  'session/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/edit/${id}`;
    return axios.get<ISession>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'session/create_patinet_entity',
  async (entity: ISession, thunkAPI) => {
    const result = await axios.post<ISession>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const createPatientEntity = createAsyncThunk(
  'session/create_entity',
  async (entity: IDeoSession, thunkAPI) => {
    const result = await axios.post<IDeoSession>(
      `${apiUrlCreate}?pharmacy=${atob(localStorage.getItem('pharmacySelected'))}&service=${atob(localStorage.getItem('serviceSelected'))}`,
      cleanEntity(entity)
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const uploadEntity = createAsyncThunk('session/upload_entity', async (entity: IUploadData, thunkAPI) => {
  const formData = new FormData();
  formData.append('file', entity.file);
  try {
    const response = await axios.post<ISession>(
      `${apiUrl}/upload-data?pharmacy=${atob(localStorage.getItem('pharmacySelected'))}&service=${atob(
        localStorage.getItem('serviceSelected')
      )}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const updateEntity = createAsyncThunk(
  'session/update_entity',
  async (entity: ISession, thunkAPI) => {
    const result = await axios.put<ISession>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const updatePatientEntity = createAsyncThunk(
  'session/update_patient_entity',
  async (entity: IDeoSession, thunkAPI) => {
    const result = await axios.patch<IDeoSession>(`${apiUrl}/update/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateMedicineEntity = createAsyncThunk(
  'session/update_medicine_entity',
  async (entity: IDeoMedicineSessionPayload, thunkAPI) => {
    const result = await axios.patch<IDeoMedicineSessionPayload>(`${apiUrl}/update-medicine/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'session/partial_update_entity',
  async (entity: ISessionConsent, thunkAPI) => {
    const result = await axios.patch<ISession>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(
      getAllSessionsByPharmacy({
        pageNo: entity.pageNo,
        q: '',
        rows: entity.rows,
        service_id: 1,
        session_status: entity.session_status,
        session_type: entity.session_type,
        sort: entity.sort,
      })
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const followUpNotContactableToComplete = createAsyncThunk(
  'session/follow-up-not-contactable-to-complete',
  async (entity: ISessionConsent, thunkAPI) => {
    const result = await axios.post<ISession>(`${apiUrl}/follow-up-not-contactable-to-complete/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(
      getAllSessionsByPharmacy({
        pageNo: entity.pageNo,
        q: '',
        rows: entity.rows,
        service_id: 1,
        session_status: entity.session_status,
        session_type: entity.session_type,
        sort: entity.sort,
      })
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const callAttempt = createAsyncThunk(
  'session/partial_update_entity',
  async (entity: ISessionCallAttempt, thunkAPI) => {
    const result = await axios.patch<ISession>(`${apiUrl}/call-attempt/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(
      getAllSessionsByPharmacy({
        pageNo: entity.pageNo,
        q: '',
        rows: entity.rows,
        service_id: 1,
        session_status: entity.session_status,
        session_type: entity.session_type,
        sort: entity.sort,
      })
    );
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reviewSession = createAsyncThunk(
  'session/review_session',
  async (entity: IReviewSession, thunkAPI) => {
    const result = await axios.post<IReviewSession>(`${apiUrl}/review/${entity.session_id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const referGp = createAsyncThunk(
  'session/refer_to_gp',
  async (entity: IGPDetail, thunkAPI) => {
    const result = await axios.post<IReviewSession>(`${apiUrl}/refer-to-gp/${entity.session_id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const reviewSessionMedicine = createAsyncThunk(
  'session/review_session_medicine',
  async (entity: IReviewSessionMedicine, thunkAPI) => {
    const result = await axios.post<IReviewSessionMedicine>(
      `${apiUrl}/review/${entity.session_id}/medicine/${entity.medicine_id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntity(entity.session_id));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const reviewInEligibleSession = createAsyncThunk(
  'session/review_session_medicine',
  async (entity: IReviewInEligibleSession, thunkAPI) => {
    const result = await axios.post<IReviewInEligibleSession>(
      `${apiUrl}/review/${entity.session_id}/medicine/${entity.medicine_id}/in-eligible`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntity(entity.session_id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const interventionNotDeliveredSession = createAsyncThunk(
  'session/review_session_medicine',
  async (entity: IReviewInterventionNotDeliveredSession, thunkAPI) => {
    const result = await axios.post<IReviewInterventionNotDeliveredSession>(
      `${apiUrl}/review/${entity.session_id}/not_delivered`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntity(entity.session_id));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'session/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/delete/${id}`;
    const result = await axios.delete<ISession>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const clearEntity = createAsyncThunk('session/clear_entity', async () => {}, { serializeError: serializeAxiosError });

// slice

export const SessionSlice = createEntitySlice({
  name: 'session',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getPharmacyById.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          pharmacyDetail: action.payload.data,
        };
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getPharmacistAgreedActionReferBackTypes.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          pharmacistReferBackTypes: action.payload['data'],
        };
      })
      .addMatcher(isFulfilled(getDeoEntity), (state, action) => {
        return {
          ...state,
          loading: false,
          deoSessionEntity: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(referGp), (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(getDeoEntities), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          deoSessionEntities: data['results'],
          rows: data['rows'],
          pageNo: data['page'],
          invalidRecordCount: data['invalidRecordCount'],
          validRecordCount: data['validRecordCount'],
        };
      })
      .addMatcher(isFulfilled(getMedicineConditions), (state, action) => {
        return {
          ...state,
          loading: false,
          medicineConditionsList: action.payload['data'].results,
        };
      })
      .addMatcher(isFulfilled(getAllSessionsByPharmacy), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          session_status: data['sessionStatusValue'],
          session_type: data['sessionTypeValue'],
          sessionByPharmacyEntities: data ? data['result']['results'] : [],
          sessionCounts: data['count'],
          totalCount: data['result']['count'],
          rows: data['rows'],
          pageNo: data['page'],
        };
      })
      .addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(followUpNotContactableToComplete), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(callAttempt), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createPatientEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(reviewSession), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isFulfilled(reviewSessionMedicine), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          updating: false,
          updateSuccess: true,
          reviewedMedicineResponse: data,
        };
      })
      .addMatcher(isFulfilled(reviewInEligibleSession), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          updating: false,
          updateSuccess: true,
        };
      })
      .addMatcher(isFulfilled(interventionNotDeliveredSession), (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          loading: false,
          updating: false,
          updateSuccess: true,
        };
      })
      .addMatcher(isFulfilled(uploadEntity), (state, action) => {
        if (action?.payload === undefined) {
          return {
            ...initialState,
          };
        }
        const fileId = action?.payload['data']['id'];
        const file_name = action?.payload['data']['name'];
        const pharmacy_name = action?.payload['pharmacyData']['name'];
        const pharmacy_gphcnumber = action?.payload['pharmacyData']['gphc_umber'];
        localStorage.setItem('fileId', fileId);
        localStorage.setItem('fileName', file_name);
        localStorage.setItem('pharmacyName', pharmacy_name);
        localStorage.setItem('pharmacyNumber', pharmacy_gphcnumber);
        return {
          ...state,
          loading: false,
          updating: false,
          updateSuccess: true,
          successMessage: action.payload && action.payload['message'],
          requestStatus: action.meta && action.meta.requestStatus,
          payloadStatus: action.payload && action.payload['status'],
          fileData: action?.payload['data'],
        };
      })
      .addMatcher(isPending(getEntities, getEntity, getDeoEntity, getPharmacyById, getAllSessionsByPharmacy), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(getEntities, getEntity, getDeoEntity, getPharmacyById, getAllSessionsByPharmacy), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(uploadEntity), (state, action) => {
        return {
          ...state,
          errorMessage: null,
          updateSuccess: false,
          loading: true,
          requestStatus: action.meta.requestStatus,
          payloadStatus: action.payload && action.payload['status'],
        };
      })
      .addMatcher(isFulfilled(clearEntity), (state, action) => {
        return {
          ...initialState,
        };
      })
      .addMatcher(isPending(clearEntity), (state, action) => {
        return {
          ...initialState,
        };
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          updatePatientEntity,
          updateMedicineEntity,
          partialUpdateEntity,
          followUpNotContactableToComplete,
          reviewSession,
          reviewSessionMedicine,
          reviewInEligibleSession,
          interventionNotDeliveredSession,
          deleteEntity,
          createPatientEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
          state.updating = true;
        }
      )
      .addMatcher(
        isRejected(
          createEntity,
          updateEntity,
          updatePatientEntity,
          updateMedicineEntity,
          partialUpdateEntity,
          followUpNotContactableToComplete,
          reviewSession,
          reviewSessionMedicine,
          reviewInEligibleSession,
          interventionNotDeliveredSession,
          deleteEntity,
          createPatientEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
          state.updating = true;
        }
      );
  },
});

export const { reset } = SessionSlice.actions;

// Reducer
export default SessionSlice.reducer;
