import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { IListingPharmacy } from '../interface';
import { s2ab } from 'app/shared/util/utits'; // Ensure valid import path
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getMedicines,
  getMedicinesConditions,
  addMedicine,
  addMedicineCondition,
  addMedicineConditionRelation,
  deleteCondition,
  updateCondition,
} from '../admin-reducer';
import { useNavigate } from 'react-router-dom';

const useManageMedicineCondition = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [conditionIdToDelete, setConditionIdToDelete] = useState<number | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addConditionDialogOpen, setAddConditionDialogOpen] = useState(false);
  const [addMedicineDialogOpen, setAddMedicineDialogOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [newConditionName, setNewConditionName] = useState('');
  const [selectedMedicines, setSelectedMedicines] = useState([]); // Updated to hold multiple selected medicines
  const [manageMedicineDialogOpen, setManageMedicineDialogOpen] = useState(false);
  const [newMedicineName, setNewMedicineName] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [conditionToDelete, setConditionToDelete] = useState(null);
  const [editConditionDialogOpen, setEditConditionDialogOpen] = useState(false);
  const [conditionToEdit, setConditionToEdit] = useState(null);
  const allMedicines = useAppSelector(state => state.admin.allMedicines);
  const allMedicinesConditions = useAppSelector(state => state.admin.allMedicinesConditions);

  const handleConditionDelete = useCallback(async (id: number) => {
    // Simulating deletion, replace with actual logic if needed
    setConditionIdToDelete(undefined);
    toast.success('Condition deleted successfully');
  }, []);

  useEffect(() => {
    dispatch(getMedicines());
    dispatch(getMedicinesConditions());
  }, [dispatch]);

  const addMedicineI = async data => {
    const result = await dispatch(addMedicine(data));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(getMedicines());
      toast.success('Medicine added successfully');
      navigate('/admin/dashboard', { state: { selectedColor: 'nine', index: 9 } });
    }
  };

  const addConditionI = async data => {
    const result = await dispatch(addMedicineCondition(data));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(getMedicinesConditions());
      toast.success('Condition added successfully');
      navigate('/admin/dashboard', { state: { selectedColor: 'nine', index: 9 } });
    }
  };

  const addConditionRelationI = async data => {
    const result = await dispatch(addMedicineConditionRelation(data));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(getMedicinesConditions());
      toast.success('Condition Relation added successfully');
      navigate('/admin/dashboard', { state: { selectedColor: 'nine', index: 9 } });
    }
  };

  const editConditionI = async data => {
    const result = await dispatch(updateCondition(data));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(getMedicinesConditions());
      toast.success('Condition updated successfully');
      navigate('/admin/dashboard', { state: { selectedColor: 'nine', index: 9 } });
    }
  };

  const handleAddConditionDialogOpen = () => {
    setAddConditionDialogOpen(true);
  };

  const handleAddConditionDialogClose = () => {
    setAddConditionDialogOpen(false);
  };

  const handleAddCondition = () => {
    const data = {
      name: newConditionName,
    };
    addConditionI(data);
    setNewConditionName('');
    setAddConditionDialogOpen(false);
  };

  const handleManageConditions = () => {
    setDialogOpen(true);
  };

  const handleConditionChange = event => {
    setSelectedCondition(event.target.value);
  };

  const handleAddMedicineDialogOpen = () => {
    setAddMedicineDialogOpen(true);
  };

  const handleAddMedicineDialogClose = () => {
    setAddMedicineDialogOpen(false);
  };

  const handleAddMedicine = () => {
    const medicineIds = selectedMedicines.map(medicine => medicine.id);
    const data = {
      condition: selectedCondition,
      medicines: medicineIds,
    };
    addConditionRelationI(data);
    setAddMedicineDialogOpen(false);
  };

  const handleManageMedicineDialogOpen = () => {
    setManageMedicineDialogOpen(true);
  };

  const handleManageMedicineDialogClose = () => {
    setManageMedicineDialogOpen(false);
  };

  const handleAddNewMedicine = () => {
    const data = {
      name: newMedicineName,
    };
    addMedicineI(data);
    setNewMedicineName('');
    setManageMedicineDialogOpen(false);
  };

  const handleDeleteCondition = condition => {
    setConditionToDelete(condition);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const result = await dispatch(deleteCondition(Number(conditionToDelete)));
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(getMedicinesConditions());
      toast.success('Condition successfully deleted');
      navigate('/admin/dashboard', { state: { selectedColor: 'nine', index: 9 } });
    }
    setConfirmationDialogOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmationDialogOpen(false);
  };
  const handleEditCondition = condition => {
    setConditionToEdit(condition);
    setEditConditionDialogOpen(true);
  };

  const handleEditConditionDialogClose = () => {
    setEditConditionDialogOpen(false);
  };

  const handleSaveEditCondition = () => {
    const data = {
      ...conditionToEdit,
      name: conditionToEdit?.name || newConditionName, // Ensure the name is not empty
    };
    editConditionI(data);
    setNewConditionName(''); // Clear the input field
    setEditConditionDialogOpen(false);
  };

  return {
    states: {
      allMedicines,
      allMedicinesConditions,
      conditionIdToDelete,
      dialogOpen,
      addConditionDialogOpen,
      addMedicineDialogOpen,
      selectedCondition,
      newConditionName,
      selectedMedicines,
      manageMedicineDialogOpen,
      newMedicineName,
      setDialogOpen,
      setNewConditionName,
      setSelectedMedicines,
      setNewMedicineName,
      editConditionDialogOpen,
      conditionToEdit,
      confirmationDialogOpen,
      setEditConditionDialogOpen,
      setConditionToEdit,
    },
    handlers: {
      handleConditionDelete,
      handleEditCondition,
      handleAddConditionDialogOpen,
      handleAddConditionDialogClose,
      handleAddCondition,
      handleManageConditions,
      handleConditionChange,
      handleAddMedicineDialogOpen,
      handleAddMedicineDialogClose,
      handleAddMedicine,
      handleManageMedicineDialogOpen,
      handleManageMedicineDialogClose,
      handleAddNewMedicine,
      setSelectedCondition,
      handleDeleteCondition,
      handleConfirmDelete,
      handleCancelDelete,
      handleEditConditionDialogClose,
      handleSaveEditCondition,
    },
  };
};

export default useManageMedicineCondition;
