import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import {
  getEntity,
  deleteEntity,
  getEntities,
  createEntity,
  updateEntity,
  partialUpdateEntity,
} from 'app/entities/app-user/app-user.reducer';
import { IPharmacy } from 'app/shared/model/pharmacy.model';
import { ISession } from 'app/shared/model/session.model';
import { IUser } from 'app/shared/model/user.model';
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import {
  IActiveInActiveUsers,
  IAddEditPharmacyContractor,
  IAddUserRoles,
  IAppUserParam,
  IAssignPharmacy,
  IListingPharmacist,
  IListingPharmacy,
  IListingRoles,
  IPharmacistPharmacyParam,
  IPharmacyParam,
  ISessionFollowUP,
  ISessionParam,
  IStatsParam,
  IUpdatePharmacy,
  IAddMedicine,
} from './interface';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';

const initialState = {
  count: 0,
  unfinishedCount: 0,
  results: [],
  unfinishedResults: [],
  allPharmaciesStats: [],
  allPharmacistStats: [],
  allPharmaciesOverallStats: {},
  excelFileRecords: [],
  excelStoreTotalRecords: [],
  excelPharmacistTotalRecords: [],
  excelFilePharmacyStatsRecords: [],
  rows: 50,
  unfinishedRows: 50,
  unfinishedPage: 0,
  allPharmacies: [],
  allSessionStatuses: [],
  allServicePharmacist: [],
  loading: false,
  error: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  updateSuccess: false,
  loadingAdminExcelDownload: false,
  loadingAdminStoreTotalDownload: false,
  loadingAdminPharmacistTotalDownload: false,
  stats: {},
  recentRoles: [],
  recentPharmacies: [],
  recentClients: [],
  roles: [],
  rolesListing: [],
  rolesCount: 0,
  rolesRows: 50,
  rolesPage: 0,
  userDetails: null,
  allUserPharmacies: [],
  allListPharmacies: [],
  listPharmacy: [],
  listAllServicePharmacist: [],
  pharmacyCount: 0,
  pharmacyRows: 50,
  pharmacyPage: 0,
  pharmacyDetails: null,
  pharmaciesGroupName: null,
  allGroupNames: [],
  allMedicines: [],
  allMedicinesConditions: [],
};

// Actions
const apiUrlPharmacy = 'api/pharmacies';
const apiUrlSessionStatuses = 'api/session-statuses';
const apiUrlUser = 'api/admin/users/get-all-pharmacist';
const apiUrlSessions = 'api/sessions/get-all-sessions-with-date-range';
const apiUrlPharmaciesStats = 'api/pharmacies/store-total';
const apiUrlPharmacistStats = 'api/app-users/pharmacist-stats';
const apiUrlDownloadPharmacistStats = 'api/app-users/download-pharmacist-stats';
const apiUrlSessionsFollow = 'api/sessions';
const apiUrlSessionDownload = 'api/sessions/download-excel-file-data';
const apiUrlStoreTotalDownload = 'api/pharmacies/download-store-total';
const apiUrlPharmacistStatsByPharmacy = 'api/app-users/single-pharmacist-stats-per-pharmacy';
const apiUrlPharmacySessionStatsDownload = 'api/sessions/download-excel-file-pharmacy-nms-data';
const apiUrlStats = 'api/admin/get-all-overview-stats';
const apiUrlRecentRoles = 'api/admin/get-recent-roles-listing';
const apiUrlRecentClients = 'api/admin/get-recent-client-listing';
const apiUrlGetAllRoles = 'api/admin/roles';
const apiUrlListingRoles = 'api/admin/get-all-users-with-roles';
const apiUrlDownloadRoleRecords = 'api/admin/download-user-role-records';
const apiUrlAddUserWithRoles = 'api/admin/add-user';
const apiUrlDeleteUser = 'api/admin/delete-user';
const apiUrlUpdateUser = 'api/admin/update-user';
const apiUrlActiveInActiveUser = 'api/admin/active-inactive-user';
const apiUrlGetUserRoleById = 'api/admin/get-user-role-by-id';
const apiUrlAddPharmacyContractor = 'api/admin/add-pharmacist-contractor';
const apiUrlUpdatePharmacyContractor = 'api/admin/update-pharmacist-contractor';
const apiUrlGetAllPharmacies = 'api/admin/get-all-pharmacy';

const apiUrlListAllPharmacies = 'api/admin/list-all-pharmacy';
const apiUrlListRecentPharmacies = 'api/admin/list-recent-pharmacy';
const apiUrlGetPharmacies = 'api/admin/get-all-pharmacies';
const apiUrlDownloadAllPharmacies = 'api/admin/download-all-pharmacy';
const apiUrlAssignPharmacy = 'api/admin/assign-pharmacy';
const apiUrlActiveInActivePharmacy = 'api/admin/active-inactive-pharmacy';
const apiUrlUpdatePharmacy = 'api/admin/update-pharmacy';
const apiUrlGetAllServicePharmacist = 'api/admin/get-all-service-pharmacists';
const apiUrlGetPharmacyById = 'api/admin/get-pharmacy-by-id';
const apiUrlDeletePharmacyById = 'api/admin/delete-pharmacy-by-id';
const apiUrlGetPharmaciesGroupName = 'api/admin/get-user-group-name';
const apiUrlGetAllGroupNames = 'api/pharmacy-groups';
const apiUrlGetAllMedicines = 'api/medicines/all';
const apiUrlGetAllMedicinesConditions = 'api/medicine-conditions/all';
const apiUrlPostMedicines = 'api/medicines';
const apiUrlPostMedicinesCondition = 'api/medicine-conditions';
const apiUrlPostMedicinesConditionRelation = 'api/medicine-conditions/relation';
const apiUrlDeleteCondition = 'api/medicine-conditions';
const apiUrlPutCondition = 'api/medicine-conditions';

export const getAllPharmacies = createAsyncThunk('pharmacy/fetch_all_pharmacies', async () => {
  const requestUrl = `${apiUrlPharmacy}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IPharmacy[]>(requestUrl);
});

export const getSessionStatuses = createAsyncThunk('pharmacy/fetch_all_statuses', async () => {
  const requestUrl = `${apiUrlSessionStatuses}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IPharmacy[]>(requestUrl);
});

export const getAllServicePharmacist = createAsyncThunk('pharmacy/fetch_all_service_pharmacies', async () => {
  const requestUrl = `${apiUrlUser}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllSessionsListingWithDateRange = createAsyncThunk(
  'pharmacy/fetch_all_session_date_range',
  async (params: ISessionParam) => {
    const { page, size, searchQuery, servicePharmacist, pharmacy, status, month, from, to, group } = params;
    const requestUrl = `${apiUrlSessions}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&status=${status}&month=${month}&group=${group}&servicePharmacist=${servicePharmacist}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const getAllUnfinishedListing = createAsyncThunk('pharmacy/fetch_all_unfinished_session', async (params: ISessionParam) => {
  const { page, size, searchQuery, servicePharmacist, pharmacy, status, month, from, to, group } = params;
  const requestUrl = `${apiUrlSessionsFollow}/unfinished?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&status=${status}&month=${month}&group=${group}&pharmacist=${servicePharmacist}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
  return axios.get<ISession[]>(requestUrl);
});
export const getAllStoreTotals = createAsyncThunk('pharmacies/fetch_all_store_total', async (params: IPharmacyParam) => {
  const { page, size, searchQuery, pharmacy, month, group } = params;
  const requestUrl = `${apiUrlPharmaciesStats}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&month=${month}&group=${group}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
  return axios.get<ISession[]>(requestUrl);
});
export const getPharmacistStats = createAsyncThunk('pharmacist/fetch_pharmacist_stats', async (params: IAppUserParam) => {
  const { page, size, searchQuery, pharmacy, servicePharmacist, month, group, from, to } = params;
  const requestUrl = `${apiUrlPharmacistStats}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${servicePharmacist}&month=${month}&group=${group}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
  return axios.get<ISession[]>(requestUrl);
});
export const getDownloadPharmacistStats = createAsyncThunk('pharmacist/download_pharmacist_stats', async (params: IAppUserParam) => {
  const { page, size, searchQuery, pharmacy, servicePharmacist, month, group, from, to } = params;
  const requestUrl = `${apiUrlDownloadPharmacistStats}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${servicePharmacist}&month=${month}&group=${group}&from=${from}&to=${to}`;
  return axios.get<ISession[]>(requestUrl);
});
export const updateFollowUpDate = createAsyncThunk(
  'session/follow_up_date_update_entity',
  async (entity: ISessionFollowUP, thunkAPI) => {
    const result = await axios.patch<ISessionFollowUP>(`${apiUrlSessionsFollow}/update/follow-up-date/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const downloadStoreTotal = createAsyncThunk('pharmacies/download_store_total', async (params: IPharmacyParam) => {
  const { searchQuery, pharmacy, month, group } = params;
  const requestUrl = `${apiUrlStoreTotalDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&month=${month}&group=${group}`;
  return axios.get<ISession[]>(requestUrl);
});

export const getPharmacistStatsByPharmacy = createAsyncThunk(
  'app-users/pharmacist_stats_per_pharmacy',
  async (params: IPharmacistPharmacyParam) => {
    const requestUrl = `${apiUrlPharmacistStatsByPharmacy}?cacheBuster=${new Date().getTime()}
    &pharmacist=${params}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const downloadExcelFileRecords = createAsyncThunk('session/download_excel_file_records', async (params: ISessionParam) => {
  const { searchQuery, servicePharmacist, pharmacy, status, month, group, from, to } = params;
  const requestUrl = `${apiUrlSessionDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&status=${status}&month=${month}&group=${group}&servicePharmacist=${servicePharmacist}&from=${from}&to=${to}&searchQuery=${searchQuery}`;
  return axios.get<ISession[]>(requestUrl);
});
export const downloadExcelPharmacyNMSRecords = createAsyncThunk(
  'session/download_excel_file_pharmacy_nms_records',
  async (params: ISessionParam) => {
    const { searchQuery, servicePharmacist, pharmacy, from, to } = params;
    const requestUrl = `${apiUrlPharmacySessionStatsDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&servicePharmacist=${servicePharmacist}&from=${from}&to=${to}&searchQuery=${searchQuery}`;
    return axios.get<ISession[]>(requestUrl);
  }
);

export const getAllStats = createAsyncThunk('overviewScreen/get_All_Stats', async (params: IStatsParam) => {
  const { from, to } = params;
  const requestUrl = `${apiUrlStats}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}`;
  return axios.get(requestUrl);
});

export const getRecentRoles = createAsyncThunk('overviewScreen/get_recent_roles', async () => {
  const requestUrl = `${apiUrlRecentRoles}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getRecentClients = createAsyncThunk('overviewScreen/get_recent_clients', async () => {
  const requestUrl = `${apiUrlRecentClients}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getAllRoles = createAsyncThunk('manage_roles/get_all_roles', async () => {
  const requestUrl = `${apiUrlGetAllRoles}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const listAllRoles = createAsyncThunk('manage_roles/list_all_roles', async (params: IListingRoles) => {
  const { searchQuery, role, from, to, page, size } = params;
  const requestUrl = `${apiUrlListingRoles}?cacheBuster=${new Date().getTime()}
  &role=${role}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
  return axios.get(requestUrl);
});

export const downloadUserRoleRecords = createAsyncThunk('manage_roles/download_all_role_records', async (params: IListingRoles) => {
  const { searchQuery, role, from, to } = params;
  const requestUrl = `${apiUrlDownloadRoleRecords}?cacheBuster=${new Date().getTime()}
  &role=${role}&from=${from}&to=${to}&searchQuery=${searchQuery}`;
  return axios.get(requestUrl);
});

export const addUserWithRoles = createAsyncThunk('manage_roles/add_user_with_roles', async (params: IAddUserRoles) => {
  const requestUrl = `${apiUrlAddUserWithRoles}`;
  return axios.post(requestUrl, params);
});

export const editUserRoles = createAsyncThunk('manage_roles/edit_user_with_roles', async (params: IAddUserRoles) => {
  const { id, ...postData } = params;
  const requestUrl = `${apiUrlUpdateUser}/${id}`;
  return axios.post(requestUrl, postData);
});

export const activeInactiveUsers = createAsyncThunk('manage_roles/actve_inactive_users', async (params: IActiveInActiveUsers) => {
  const { id, active } = params;
  const requestUrl = `${apiUrlActiveInActiveUser}?cacheBuster=${new Date().getTime()}
  &id=${id}&active=${active}`;
  return axios.get(requestUrl);
});

export const deleteUserwithRoles = createAsyncThunk('manage_roles/delete_users', async (id: number) => {
  const requestUrl = `${apiUrlDeleteUser}/${id}`;
  return axios.delete(requestUrl);
});

export const getUserRoleById = createAsyncThunk('manage_roles/get_user_role_by_id', async (id: number) => {
  const requestUrl = `${apiUrlGetUserRoleById}/${id}`;
  return axios.get(requestUrl);
});

export const addPharmacyContractor = createAsyncThunk(
  'manage_roles/add_pharmacy_contractor',
  async (params: IAddEditPharmacyContractor) => {
    const requestUrl = `${apiUrlAddPharmacyContractor}`;
    return axios.post(requestUrl, params);
  }
);

export const updatePharmacyContractor = createAsyncThunk(
  'manage_roles/update_pharmacy_contractor',
  async (params: IAddEditPharmacyContractor) => {
    const { id } = params;
    const requestUrl = `${apiUrlUpdatePharmacyContractor}/${id}`;
    return axios.post(requestUrl, params);
  }
);

export const getAllPharmacyByUserId = createAsyncThunk('manage_roles/get_all_pharmacy_by_user_id', async (id: number) => {
  const requestUrl = `${apiUrlGetAllPharmacies}/${id}`;
  return axios.get(requestUrl);
});

export const listAllPharmacy = createAsyncThunk('manage_pharmacy/list_all_pharmacy', async (params: IListingPharmacy) => {
  const { searchQuery, pharmacy, from, to, page, size, group } = params;
  const requestUrl = `${apiUrlListAllPharmacies}?cacheBuster=${new Date().getTime()}
  &pharmacy=${pharmacy}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}&group=${group}`;
  return axios.get(requestUrl);
});
export const listRecentPharmacy = createAsyncThunk('manage_pharmacy/list_recent_pharmacy', async () => {
  const requestUrl = `${apiUrlListRecentPharmacies}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getPharmacies = createAsyncThunk('manage_pharmacy/get_pharmacies', async () => {
  const requestUrl = `${apiUrlGetPharmacies}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const downloadAllPharmacies = createAsyncThunk('manage_pharmacy/download_all_pharmacy', async (params: IListingPharmacy) => {
  const { searchQuery, pharmacy, from, to, group } = params;
  const requestUrl = `${apiUrlDownloadAllPharmacies}?cacheBuster=${new Date().getTime()}
  &pharmacy=${pharmacy}&from=${from}&to=${to}&searchQuery=${searchQuery}&group=${group}`;
  return axios.get(requestUrl);
});

export const assignPharmacy = createAsyncThunk('manage_pharmacy/assign_pharmacy', async (params: IAssignPharmacy) => {
  const requestUrl = `${apiUrlAssignPharmacy}`;
  return axios.post(requestUrl, params);
});

export const activeInactivePharmacy = createAsyncThunk('manage_pharmacy/active_inactive_pharmacy', async (params: IActiveInActiveUsers) => {
  const requestUrl = `${apiUrlActiveInActivePharmacy}`;
  return axios.post(requestUrl, params);
});

export const getAllServicePharmacists = createAsyncThunk(
  'manage_pharmacy/get_all_service_pharmacist',
  async (params: IListingPharmacist) => {
    const { searchQuery, from, to, page, size, group } = params;
    const requestUrl = `${apiUrlGetAllServicePharmacist}?cacheBuster=${new Date().getTime()}
  &from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}&group=${group}`;
    return axios.get(requestUrl);
  }
);

export const getPharmacyById = createAsyncThunk('manage_pharmacy/get_pharmacy_by_id', async (id: number) => {
  const requestUrl = `${apiUrlGetPharmacyById}/${id}`;
  return axios.get(requestUrl);
});

export const updatePharmacy = createAsyncThunk('manage_pharmacy/update_pharmacy', async (params: IAssignPharmacy) => {
  const { ...data } = params;
  const requestUrl = `${apiUrlUpdatePharmacy}/`;
  return axios.post(requestUrl, data);
});

export const deletePharmacyById = createAsyncThunk('manage_pharmacy/delete_pharmacy', async (id: number) => {
  const requestUrl = `${apiUrlDeletePharmacyById}/${id}`;
  return axios.delete(requestUrl);
});

export const getPharmaciesGroupName = createAsyncThunk('manage_pharmacy/get_pharmcies_groups', async (id: number) => {
  const requestUrl = `${apiUrlGetPharmaciesGroupName}/${id}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getGroupNames = createAsyncThunk('manage_pharmacy/get_all_pharmacy_groups', async () => {
  const requestUrl = `${apiUrlGetAllGroupNames}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});
export const getMedicines = createAsyncThunk('manage_medicine_condition/get_all_medicines', async () => {
  const requestUrl = `${apiUrlGetAllMedicines}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getMedicinesConditions = createAsyncThunk('manage_medicine_condition/get_all_medicines_conditions', async () => {
  const requestUrl = `${apiUrlGetAllMedicinesConditions}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const addMedicine = createAsyncThunk('manage_medicine/add_medicine', async (params: IAddMedicine) => {
  const { ...postData } = params;
  const requestUrl = `${apiUrlPostMedicines}/`;
  return axios.post(requestUrl, postData);
});

export const addMedicineCondition = createAsyncThunk('manage_medicine/add_medicine_condition', async (params: IAddMedicine) => {
  const { ...postData } = params;
  const requestUrl = `${apiUrlPostMedicinesCondition}/`;
  return axios.post(requestUrl, postData);
});

export const addMedicineConditionRelation = createAsyncThunk(
  'manage_medicine/add_medicine_condition_relation',
  async (params: IAddMedicine) => {
    const { ...postData } = params;
    const requestUrl = `${apiUrlPostMedicinesConditionRelation}/`;
    return axios.post(requestUrl, postData);
  }
);

export const updateCondition = createAsyncThunk('manage_medicine/update_condition', async (data: any) => {
  const requestUrl = `${apiUrlPutCondition}/${data.id}`;
  return axios.put(requestUrl, data);
});

export const deleteCondition = createAsyncThunk('manage_medicine/delete_condition', async (id: number) => {
  const requestUrl = `${apiUrlDeleteCondition}/${id}`;
  return axios.delete(requestUrl);
});

export const AdminSlice = createEntitySlice({
  name: 'admin',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(addUserWithRoles.pending, state => {
        return {
          ...state,
          loading: true,
          errorMessage: null,
        };
      })
      .addCase(addUserWithRoles.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          errorMessage: null,
        };
      })
      .addCase(addUserWithRoles.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          errorMessage: 'Failed to add user with roles',
        };
      })
      .addCase(getAllPharmacies.fulfilled, (state, action) => {
        return {
          ...state,
          allPharmacies: action?.payload?.data['results'],
        };
      })
      .addCase(getAllPharmacies.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllPharmacies.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getSessionStatuses.fulfilled, (state, action) => {
        return {
          ...state,
          allSessionStatuses: action.payload.data,
        };
      })
      .addCase(getSessionStatuses.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getSessionStatuses.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllServicePharmacist.fulfilled, (state, action) => {
        return {
          ...state,
          allServicePharmacist: action.payload.data,
        };
      })
      .addCase(getAllServicePharmacist.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllServicePharmacist.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllSessionsListingWithDateRange.fulfilled, (state, action: any) => {
        return {
          ...state,
          results: action.payload.data.results,
          count: action.payload.data.count,
          rows: action.payload.data.rows,
          page: action.payload.data.page,
        };
      })
      .addCase(getAllSessionsListingWithDateRange.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllSessionsListingWithDateRange.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllUnfinishedListing.fulfilled, (state, action: any) => {
        return {
          ...state,
          unfinishedResults: action.payload.data.results,
          unfinishedCount: action.payload.data.count,
          unfinishedRows: action.payload.data['pageRequest'].size,
          unfinishedPage: action.payload.data['pageRequest'].page,
        };
      })
      .addCase(getAllUnfinishedListing.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllUnfinishedListing.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllStoreTotals.fulfilled, (state, action: any) => {
        return {
          ...state,
          allPharmaciesStats: action.payload.data?.results,
          allPharmaciesOverallStats: action.payload?.data,
          count: action.payload.data?.count,
          rows: action.payload.data?.pageRequest?.size,
          page: action.payload.data?.pageRequest?.page,
        };
      })
      .addCase(getAllStoreTotals.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllStoreTotals.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getPharmacistStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          allPharmacistStats: action.payload.data?.results,
          count: action.payload.data?.count,
          rows: action.payload.data?.pageRequest?.size,
          page: action.payload.data?.pageRequest?.page,
        };
      })
      .addCase(getPharmacistStats.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPharmacistStats.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(downloadExcelFileRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelFileRecords: action.payload.data.results,
          loadingAdminExcelDownload: false,
        };
      })
      .addCase(downloadExcelFileRecords.pending, state => {
        return {
          ...state,
          loadingAdminExcelDownload: true,
        };
      })
      .addCase(downloadExcelFileRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          loadingAdminExcelDownload: true,
        };
      })
      .addCase(downloadStoreTotal.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelStoreTotalRecords: action.payload.data.results,
          loadingAdminStoreTotalDownload: false,
        };
      })
      .addCase(downloadStoreTotal.pending, state => {
        return {
          ...state,
          loadingAdminStoreTotalDownload: true,
        };
      })
      .addCase(downloadStoreTotal.rejected, state => {
        return {
          ...state,
          loading: false,
          loadingAdminStoreTotalDownload: true,
        };
      })
      .addCase(getPharmacistStatsByPharmacy.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getPharmacistStatsByPharmacy.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getPharmacistStatsByPharmacy.rejected, state => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getDownloadPharmacistStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelPharmacistTotalRecords: action.payload.data.results,
          loadingAdminPharmacistTotalDownload: false,
        };
      })
      .addCase(getDownloadPharmacistStats.pending, state => {
        return {
          ...state,
          loadingAdminPharmacistTotalDownload: true,
        };
      })
      .addCase(getDownloadPharmacistStats.rejected, state => {
        return {
          ...state,
          loading: false,
          loadingAdminPharmacistTotalDownload: false,
        };
      })
      .addCase(downloadExcelPharmacyNMSRecords.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadExcelPharmacyNMSRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelFilePharmacyStatsRecords: action.payload.data.results,
        };
      })
      .addCase(downloadExcelPharmacyNMSRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          stats: action.payload.data,
        };
      })
      .addCase(getAllStats.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllStats.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getRecentRoles.fulfilled, (state, action: any) => {
        return {
          ...state,
          recentRoles: action.payload.data.result,
        };
      })
      .addCase(getRecentRoles.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRecentRoles.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(listRecentPharmacy.fulfilled, (state, action: any) => {
        return {
          ...state,
          recentPharmacies: action.payload.data.results,
        };
      })
      .addCase(listRecentPharmacy.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(listRecentPharmacy.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getRecentClients.fulfilled, (state, action: any) => {
        return {
          ...state,
          recentClients: action.payload.data.result,
        };
      })
      .addCase(getRecentClients.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRecentClients.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })

      .addCase(getAllRoles.fulfilled, (state, action: any) => {
        return {
          ...state,
          roles: action.payload.data,
        };
      })

      .addCase(listAllRoles.fulfilled, (state, action: any) => {
        return {
          ...state,
          rolesListing: action.payload.data.results,
          rolesCount: action.payload.data.count,
          rolesRows: action.payload.data.rows,
          rolesPage: action.payload.data.page,
        };
      })

      .addCase(getUserRoleById.fulfilled, (state, action: any) => {
        return {
          ...state,
          userDetails: action.payload.data,
        };
      })
      .addCase(getAllPharmacyByUserId.fulfilled, (state, action: any) => {
        return {
          ...state,
          allUserPharmacies: action.payload.data,
        };
      })
      .addCase(getPharmacies.fulfilled, (state, action: any) => {
        return {
          ...state,
          allListPharmacies: action.payload.data,
        };
      })
      .addCase(listAllPharmacy.fulfilled, (state, action: any) => {
        return {
          ...state,
          listPharmacy: action.payload.data.results,
          pharmacyCount: action.payload.data.count,
          pharmacyRows: action.payload.data.rows,
          pharmacyPage: action.payload.data.page,
        };
      })
      .addCase(getAllServicePharmacists.fulfilled, (state, action: any) => {
        return {
          ...state,
          listAllServicePharmacist: action.payload.data.results,
          pharmacistCount: action.payload.data.count,
          pharmacistRows: action.payload.data.rows,
          pharmacistPage: action.payload.data.page,
        };
      })
      .addCase(getPharmacyById.fulfilled, (state, action: any) => {
        return {
          ...state,
          pharmacyDetails: action.payload.data,
        };
      })
      .addCase(getPharmaciesGroupName.fulfilled, (state, action: any) => {
        return {
          ...state,
          pharmaciesGroupName: action.payload.data,
        };
      })
      .addCase(getGroupNames.fulfilled, (state, action: any) => {
        return {
          ...state,
          allGroupNames: action.payload.data,
        };
      })

      .addCase(getMedicines.fulfilled, (state, action: any) => {
        return {
          ...state,
          allMedicines: action.payload.data,
        };
      })
      .addCase(getMedicinesConditions.fulfilled, (state, action: any) => {
        return {
          ...state,
          allMedicinesConditions: action.payload.data,
        };
      });

    //   .addMatcher(isFulfilled(getEntities), (state, action) => {
    //     const { data } = action.payload;

    //     return {
    //       ...state,
    //       loading: false,
    //       entities: data,
    //     };
    //   })
    //   .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
    //     state.updating = false;
    //     state.loading = false;
    //     state.updateSuccess = true;
    //     state.entity = action.payload.data;
    //   })
    //   .addMatcher(isPending(getEntities, getEntity), state => {
    //     state.errorMessage = null;
    //     state.updateSuccess = false;
    //     state.loading = true;
    //   })
    //   .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
    //     state.errorMessage = null;
    //     state.updateSuccess = false;
    //     state.updating = true;
    //   });
  },
});

export const { reset } = AdminSlice.actions;

// Reducer
export default AdminSlice.reducer;
