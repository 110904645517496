import React, { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppSelector } from 'app/config/store';
import { generalMonthArray, sessionStatusesOverview } from 'app/shared/util/utits';
import useUnfinishedScreen from 'app/modules/admin-work-flow/hooks/useUnfinishedScreen';
import RichDataTable from 'app/modules/admin-work-flow/rich-data-table/RichDataTable';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
}

export const AdminUnfinishedTable = props => {
  const { states, handlers } = useUnfinishedScreen();
  const loadingDownload = useAppSelector(state => state.admin.loadingAdminExcelDownload);
  const { showMenu } = useDashboardContext();

  const {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allServicesPharmacist,
    allSessionListing,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    allGroupNames,
    filteredPharmacies,
    selectedMonth,
    userId,
    open,
    openEditModal,
    initialStateFollow,
    selectedPharmacies,
  } = states;
  const {
    getDownloadData,
    handleExcelPharmacyNMSRecords,
    handleRefresh,
    handleSelectService,
    handleSelectPharmacy,
    handleSelectStatus,
    handleSelectMonth,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handledateFrom,
    handledateTill,
    setUserIdAndOpenDialogue,
    handleEdit,
    handleDelete,
    handleClose,
    handleCloseEditModal,
    onSubmit,
    handleConsent,
  } = handlers;

  const formatNumber = number => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      container
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: '2%',
        transition: 'ease 0.5s',
        maxWidth: 'fit-content',
        position: 'fixed',
        marginLeft: showMenu ? '6%' : '17%',
      }}
    >
      <Grid lg={11.8} xs={12} style={{ display: 'flex', marginBottom: '2%', gap: 10 }}>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.group === '' ? 'All Groups' : Number(searchCreteria.group)}
            onChange={handleSelectGroup}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Groups'}>All Groups</MenuItem>
            {allGroupNames.map((elem, index) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            multiple
            value={selectedPharmacies}
            onChange={handleSelectPharmacy}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return 'All Pharmacies';
              }
              const selectedPharmacyNames = selected.map(id => {
                const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                return pharmacy ? pharmacy.name : '';
              });
              return (
                <div>
                  {selectedPharmacyNames.map(name => (
                    <Chip key={name} label={name} size="small" />
                  ))}
                </div>
              );
            }}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
            {filteredPharmacies.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.servicePharmacist === '' ? 'All Service Pharmacist' : searchCreteria.servicePharmacist}
            onChange={handleSelectService}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Service Pharmacist'}>All Service Pharmacist</MenuItem>
            {allServicesPharmacist.map((elem, index) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.firstName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%', marginLeft: '5px' }}>
          <Tooltip title="Total NMS">
            <div style={{ display: 'flex', width: '74px' }}>
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6" style={{ marginLeft: '2px' }}>
                {formatNumber(totalCount)}
              </Typography>
            </div>
          </Tooltip>
        </Grid>

        <Grid lg={4} xs={4} style={{ backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px' }} />
            </IconButton>
          </Tooltip>
          {/*{loadingDownload ? (*/}
          {/*  <CircularProgress />*/}
          {/*) : (*/}
          {/*  <Tooltip title="Download">*/}
          {/*    <IconButton*/}
          {/*      onClick={() => getDownloadData()}*/}
          {/*      style={{*/}
          {/*        backgroundColor: '#7CB6F8',*/}
          {/*        marginLeft: 5,*/}
          {/*        height: 35,*/}
          {/*        width: 35,*/}
          {/*        padding: '0px',*/}
          {/*        justifyContent: 'center',*/}
          {/*        borderRadius: 5,*/}
          {/*        cursor: 'pointer',*/}
          {/*        transition: 'background-color 0.3s',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <img*/}
          {/*        src={'../../../../content/assets/download2.png'}*/}
          {/*        alt="logo"*/}
          {/*        style={{ height: '17px', marginRight: 0, marginLeft: 0 }}*/}
          {/*      />*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
        </Grid>
      </Grid>

      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>

        <RichDataTable
          limit={limit}
          page={pageNo}
          count={totalCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          emptyRowCount={emptyRowCount}
          emptyCellCount={emptyCellCount}
          dataIndexs={['index', 'patient.firstName', 'patient.telephone', 'patient.address', 'pharmacy.name', 'sessionStatus']}
          dataSource={allSessionListing}
          headers={['ID', 'Patient Name', 'Primary Contact', 'Address', 'Pharmacy', 'Status']}
          showEditDeletButton={true}
          handleDelete={setUserIdAndOpenDialogue}
          handleEdit={handleEdit}
        />
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => handleDelete(userId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default AdminUnfinishedTable;
