import React, { createContext, useContext, useState } from 'react';

export const DashboardContext = createContext(null);

export const DashboardProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleDashboardSwitch = () => {
    setShowMenu(!showMenu);
  };

  return <DashboardContext.Provider value={{ showMenu, handleDashboardSwitch }}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => useContext(DashboardContext);
