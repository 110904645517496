import { NMS_TYPES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  followUpData,
  generateExcelData,
  generateExcelDataToDownloadStoreTotal,
  interventionData,
  isValidDate,
  pharmacyNMS,
  pharmacyWithPharmacistNMS,
  s2ab,
} from 'app/shared/util/utits';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  downloadExcelFileRecords,
  downloadExcelPharmacyNMSRecords,
  getAllPharmacies,
  getAllServicePharmacist,
  getAllSessionsListingWithDateRange,
  getSessionStatuses,
  getGroupNames,
  deleteUserwithRoles,
  assignPharmacy,
  listAllPharmacy,
  updateFollowUpDate,
  getAllStoreTotals,
  listAllRoles,
  downloadStoreTotal,
} from '../admin-reducer';
import { IAssignPharmacy, IPharmacyParam, ISessionFollowUP, ISessionParam } from '../interface';
import {
  getAllDeoOverviewSessions,
  getAllDeoSessionsCurrentDateStats,
  getDeletedSessions,
  getRecordsStatsForDeo,
  permanentDeleteRecord,
  updatePatientConsent,
} from 'app/modules/deo-work-flow/deo-reducer';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const intialState: IPharmacyParam = {
  size: 50,
  page: 0,
  searchQuery: '',
  // servicePharmacist: '',
  pharmacy: '',
  // status: '',
  group: '',
  // from: '',
  // to: '',
  month: '',
};

const initialStateFollowUp = {
  followUpdDate: dayjs(),
};

const storeTotalHook = () => {
  const columns = [
    { name: 'Stores', key: 'pharmacyName' },
    { name: 'Total', key: 'completedInterventions' },
    { name: 'Target', key: 'target' },
    { name: 'Status', key: 'status' },
    { name: 'Performance', key: 'performance' },
    { name: 'Pending Follow-ups', key: 'pending_follow_ups' },
    { name: 'Completed Follow-ups', key: 'completed_follow_ups' },
  ];
  const pageNo = useAppSelector(state => state.admin.page);
  const limit = useAppSelector(state => state.admin.rows);
  const totalCount = useAppSelector(state => state.admin.count);

  const allPharmacies = useAppSelector(state => state.admin.allPharmacies);
  const allServicesPharmacist = useAppSelector(state => state.admin.allServicePharmacist);

  const allPharmaciesListing = useAppSelector(state => state.admin.allPharmaciesStats);
  const allPharmaciesOverallStats = useAppSelector(state => state.admin.allPharmaciesOverallStats);

  const [searchCreteria, setsearchCreteria] = useState<IPharmacyParam>(intialState);
  const [initialStateFollow, setInitialStateFollow] = useState(initialStateFollowUp);
  const [downloadData, setDownloadData] = useState([]);
  const dispatch = useAppDispatch();
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const currentMonth = moment().format('MMMM');
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [open, setOpen] = useState(false);
  const [userId, setUserID] = useState();
  const [editUserId, setEditUserID] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = column => {
    setSelectedColumns(prev => (prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column]));
  };

  const emptyRowCount = 6;
  const emptyCellCount = 8;

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    dispatch(getAllStoreTotals(params));
  };

  useEffect(() => {
    dispatch(getGroupNames());
  }, []);

  useEffect(() => {
    setFilteredPharmacies(allPharmacies);
  }, [allPharmacies]);

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    dispatch(getAllStoreTotals(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };

    dispatch(getAllStoreTotals(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handleSelectPharmacy = event => {
    const { value } = event.target;

    let updatedSelections;

    if (Array.isArray(value) && value.includes('All Pharmacies')) {
      updatedSelections = [];
    } else {
      updatedSelections = value;
    }

    setSelectedPharmacies(updatedSelections);

    const params = {
      ...searchCreteria,
      pharmacy: updatedSelections.length > 0 ? updatedSelections : '',
    };

    setsearchCreteria(params);

    dispatch(getAllStoreTotals(params));
  };

  const handleSelectMonth = event => {
    let value = event.target.value;
    value = value === 'All Months' ? '' : value;
    const params = {
      ...searchCreteria,
      month: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      month: value,
    });
    dispatch(getAllStoreTotals(params));
  };

  const handleSelectGroup = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });

    if (value && value !== '' && value !== 'All Groups') {
      const filterPharmacies = allPharmacies.filter(pharmacy => pharmacy.pharmacyGroup && pharmacy.pharmacyGroup.id === value);
      setFilteredPharmacies(filterPharmacies);
    } else {
      setFilteredPharmacies(allPharmacies);
    }

    dispatch(getAllStoreTotals(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialState,
    };

    setsearchCreteria({
      ...intialState,
      // servicePharmacist: '',
      pharmacy: '',
      month: '',
      // from: '',
      // to: '',
      searchQuery: '',
    });
    setSelectedPharmacies([]);

    dispatch(getAllStoreTotals(params));
  }, []);

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    console.log({ selectedColumns });
    const response: any = await dispatch(downloadStoreTotal(params));
    if (response.type === 'pharmacies/download_store_total/fulfilled') {
      if (response.payload?.data['message']) {
        toast.error(response.payload?.data['message']);
        return;
      }

      const groupedData = response.payload?.data?.groupedResponse;

      const totalStats = {
        totalCompletedInterventions: response.payload?.data?.totalCompletedInterventions,
        totalTarget: response.payload?.data?.totalTarget,
        overallPercentage: response.payload?.data?.overallPercentage,
      };

      if (groupedData && groupedData.length > 0) {
        const wb = XLSX.utils.book_new();
        generateExcelDataToDownloadStoreTotal(groupedData, wb, totalStats, selectedColumns);

        // Write the workbook to a binary string
        const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Create a blob and download the file
        const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${'Store Total'} - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  }, [searchCreteria, selectedColumns]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  // call for getting All pharmacy,service pharmacist & SessionListing

  const getAllPharmacy = useCallback(() => {
    dispatch(getAllPharmacies());
  }, []);

  const getAllSessionStatuses = useCallback(() => {
    dispatch(getSessionStatuses());
  }, []);

  const getAllServicePharmacit = useCallback(() => {
    dispatch(getAllServicePharmacist());
  }, []);

  // const getAllSessionWithDateRange = useCallback(() => {
  //   dispatch(getAllSessionsListingWithDateRange(searchCreteria));
  // }, []);

  const getAllStoreTotal = useCallback(() => {
    dispatch(getAllStoreTotals(searchCreteria));
  }, []);

  useEffect(() => {
    getAllPharmacy();
    getAllServicePharmacit();
    getAllStoreTotal();
    // getAllSessionStatuses();
    // localStorage.setItem('month', currentMonth);
  }, []);

  // useEffect(() => {
  //   getDownloadData();
  // }, [searchCreteria]);

  const states = {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allServicesPharmacist,
    allPharmaciesListing,
    allGroupNames,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    filteredPharmacies,
    selectedMonth,
    userId,
    open,
    openEditModal,
    initialStateFollow,
    selectedPharmacies,
    allPharmaciesOverallStats,
    columns,
    selectedColumns,
    anchorEl,
  };
  const handlers = {
    getDownloadData,
    handleRefresh,
    // handleSelectService,
    handleSelectPharmacy,
    // handleSelectStatus,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    // handledateFrom,
    // handledateTill,
    handleSelectMonth,
    // setUserIdAndOpenDialogue,
    // handleEdit,
    // handleClose,
    // handleDelete,
    // handleCloseEditModal,
    // onSubmit,
    // handleConsent,
    setSelectedColumns,
    handleOpenMenu,
    handleCloseMenu,
    handleCheckboxChange,
  };

  return {
    states,
    handlers,
  };
};

export default storeTotalHook;
