import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useCallback, useEffect, useState } from 'react';

import { IDeoOverviewSessionParam, IListingPharmacy } from '../interface';
import { useNavigate } from 'react-router-dom';
import { deleteEntity } from 'app/entities/session/session.reducer';
import {
  downloadExcelFileOverviewRecords,
  getAllDeoOverdueSessions,
  getAllDeoOverviewSessions,
  getDeletedSessions,
  getRecordsStatsForDeo,
} from 'app/modules/deo-work-flow/deo-reducer';
import { generateExcelData, interventionData, isValidDate } from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getGroupNames, getAllPharmacies, getAllSessionsListingWithDateRange } from '../../admin-work-flow/admin-reducer';
import { toast } from 'react-toastify';

const intialState: IListingPharmacy = {
  size: 50,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
};
const intialStateOverdue: IDeoOverviewSessionParam = {
  size: 50,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
  month: '',
};

const OverviewHook = () => {
  const [searchCreteria, setsearchCreteria] = useState<IDeoOverviewSessionParam>(intialStateOverdue);
  const emptyRowCount = 7;
  const emptyCellCount = 9;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const [recordId, setRecordId] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const deoOverviewSessions = useAppSelector(state => state.deo.deoOverviewSession);
  const allPharmacies = useAppSelector(state => state.admin.allPharmacies);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  // const currentMonth = moment().format('MMMM');
  // const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // const handleSelectMonth = event => {
  //   setSelectedMonth(event.target.value);
  //   localStorage.setItem('month', event.target.value);
  // };

  useEffect(() => {
    dispatch(getGroupNames());
    dispatch(getAllPharmacies());
    // localStorage.setItem('month', currentMonth);
  }, []);

  useEffect(() => {
    setFilteredPharmacies(allPharmacies);
  }, [allPharmacies]);

  const handleRowsChange = event => {
    const pagePerRow = event.target.value;
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    dispatch(getAllDeoOverviewSessions(params));
  };
  const handlePageChange = (event, pageNum) => {
    setsearchCreteria({
      ...searchCreteria,
      page: pageNum,
    });
    const params = {
      ...searchCreteria,
      page: pageNum,
    };
    dispatch(getAllDeoOverviewSessions(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };
    dispatch(getAllDeoOverviewSessions(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllDeoOverviewSessions(params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllDeoOverviewSessions(params));
  };

  // const handleSelectPharmacy = event => {
  //   let value = event.target.value;
  //   value = value === 'All Pharmacies' ? '' : value;
  //   const params = {
  //     ...searchCreteria,
  //     pharmacy: value,
  //   };
  //   setsearchCreteria({
  //     ...searchCreteria,
  //     pharmacy: value,
  //   });
  //   dispatch(getAllDeoOverviewSessions(params));
  // };

  const handleSelectPharmacy = event => {
    const { value } = event.target;

    let updatedSelections;

    if (Array.isArray(value) && value.includes('All Pharmacies')) {
      updatedSelections = [];
    } else {
      updatedSelections = value;
    }

    setSelectedPharmacies(updatedSelections);

    const params = {
      ...searchCreteria,
      pharmacy: updatedSelections.length > 0 ? updatedSelections : '',
    };

    setsearchCreteria(params);

    dispatch(getAllDeoOverviewSessions(params));
  };
  const handleSelectMonth = event => {
    let value = event.target.value;
    value = value === 'All Months' ? '' : value;
    const params = {
      ...searchCreteria,
      month: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      month: value,
    });
    dispatch(getAllDeoOverviewSessions(params));
  };

  const handleSelectBranch = event => {
    let value = event.target.value;
    value = value === 'All Groups' ? '' : value;
    const params = {
      ...searchCreteria,
      group: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      group: value,
    });

    if (value && value !== '' && value !== 'All Groups') {
      const filteredPharmacies = allPharmacies.filter(pharmacy => pharmacy.pharmacyGroup && pharmacy.pharmacyGroup.id === value);
      setFilteredPharmacies(filteredPharmacies);
    } else {
      setFilteredPharmacies(allPharmacies);
    }

    dispatch(getAllDeoOverviewSessions(params));
  };

  const handleSelectService = event => {
    let value = event.target.value;
    value = value === 'All Service Pharmacists' ? '' : value;
    const params = {
      ...searchCreteria,
      pharmacist: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      pharmacist: value,
    });
    dispatch(getAllDeoOverviewSessions(params));
  };

  const handleSelectStatus = event => {
    let value = event.target.value;
    value = value === 'Status' ? '' : value;
    const params = {
      ...searchCreteria,
      status: value,
      month: value !== 'Completed' ? '' : searchCreteria?.month,
    };
    setsearchCreteria({
      ...searchCreteria,
      status: value,
      month: value !== 'Completed' ? '' : searchCreteria?.month,
    });

    dispatch(getAllDeoOverviewSessions(params));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleRedirect = id => {
    navigate('/operator/edit-patient/' + id);
    localStorage.setItem('lastActiveTabDeo', JSON.stringify({ activeItem: 'Overview' }));
  };
  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };
  const handleClickOpen = id => {
    setRecordId(id);
    setOpen(true);
  };

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(downloadExcelFileOverviewRecords(params));
    if (response.type === 'session/download-overview-excel-file-data/fulfilled') {
      if (response.payload?.data['message']) {
        toast.error(response.payload?.data['message']);
        return;
      }

      // Group data by pharmacy
      const groupedByPharmacy = response.payload?.data.reduce((acc, record) => {
        const pharmacyName = record.pharmacy.name; // Adjust this if the path to the pharmacy name is different
        if (!acc[pharmacyName]) {
          acc[pharmacyName] = [];
        }
        acc[pharmacyName].push(record);
        return acc;
      }, {} as Record<string, typeof response.payload.data>);

      // Generate and download a file for each pharmacy
      Object.keys(groupedByPharmacy).forEach(pharmacyName => {
        const pharmacyData = groupedByPharmacy[pharmacyName];

        // Group data by status for each pharmacy
        const groupedData = pharmacyData.reduce((acc, record) => {
          const status = searchCreteria.status
            ? searchCreteria.status
            : record.sessionStatus.name === 'completed'
            ? 'Completed'
            : record.sessionStatus.name;
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(record);
          return acc;
        }, {} as Record<string, typeof pharmacyData>);

        // Create a new workbook and generate the data for the current pharmacy
        const wb = XLSX.utils.book_new();
        generateExcelData(groupedData, wb);

        // Write the workbook to a binary string
        const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        // Create a blob and download the file
        const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${pharmacyName} - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }, [searchCreteria]);

  const getOverviewSession = useCallback(() => {
    dispatch(getAllDeoOverviewSessions(searchCreteria));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const handleDelete = () => {
    if (recordId !== '') {
      dispatch(deleteEntity(recordId))
        .then(result => {
          dispatch(getAllDeoOverviewSessions(searchCreteria));
          dispatch(getRecordsStatsForDeo());
          // dispatch(getDeletedSessions(searchCreteria));
          // dispatch(getAllDeoOverdueSessions(intialStateOverdue));
          handleClose();
        })
        .catch(error => {});
    }
  };

  useEffect(() => {
    getOverviewSession();
  }, []);

  useEffect(() => {}, []);

  const states = {
    emptyCellCount,
    emptyRowCount,
    searchCreteria,
    deoOverviewSessions,
    anchorEl,
    open,
    filteredPharmacies,
    selectedPharmacies,
  };
  const handlers = {
    getDownloadData,
    handleRowsChange,
    handlePageChange,
    handleDelete,
    handleRedirect,
    handleClosed,
    handleClick,
    handleClose,
    handleClickOpen,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    handleSelectPharmacy,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    handleSelectMonth,
  };
  // const statesSecondary = { selectedMonth };
  // const handlersSecondary = { handleSelectMonth };
  return {
    states,
    handlers,
  };
};

export default OverviewHook;
