import useListingTable from 'app/modules/admin-work-flow/hooks/useListingTable';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { generalMonthArray } from 'app/shared/util/utits';
import RichDataTable from 'app/modules/admin-work-flow/rich-data-table/RichDataTable';
import React, { useState } from 'react';
import storeTotalHook from 'app/modules/admin-work-flow/hooks/storeTotalHook';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import pharmacistStatsHook from 'app/modules/admin-work-flow/hooks/pharmacistStatsHook';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getPharmacistStatsByPharmacy } from 'app/modules/admin-work-flow/admin-reducer';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

export const PharmacistStatsTab = props => {
  const { states, handlers } = pharmacistStatsHook();
  const loadingDownload = useAppSelector(state => state.admin.loadingAdminPharmacistTotalDownload);
  const dispatch = useAppDispatch();
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [nestedData, setNestedData] = useState({});
  const { showMenu } = useDashboardContext();
  const {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allPharmacistListing,
    allServicesPharmacist,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    allGroupNames,
    filteredPharmacies,
    selectedPharmacies,
    anchorEl,
  } = states;
  const {
    getDownloadData,
    handleRefresh,
    handleSelectPharmacy,
    handleSelectMonth,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handleSelectService,
    handledateFrom,
    handledateTill,
    handleOpenMenu,
    handleCloseMenu,
    handleCheckboxChange,
  } = handlers;

  const formatNumber = number => {
    return new Intl.NumberFormat().format(number);
  };
  // const NestedTable = ({ nestedRecords = [] }) => {
  //   // Ensure nestedRecords is processed as an array of numbers
  //   console.log({nestedRecords})
  //   const data = nestedRecords['data']['pharmacies'];
  //   const total = nestedRecords['data']['total'];
  //   console.log({data,total})
  //   const totalInterventions = total.totalInterventions
  //   const totalEarnings = total.totalEarnings
  //   const totalFollowUps = total.totalFollowUps
  //
  //
  //   return (
  //     <Box marginLeft={4}>
  //       <Table size="small" aria-label="nested table">
  //         <TableBody>
  //           {Array.isArray(data) &&
  //             data.map((pharmacy, index) => (
  //               <TableRow key={index}>
  //                 <TableCell>{pharmacy.pharmacyName}</TableCell>
  //                 <TableCell align="center">{parseFloat(pharmacy.completedInterventions || 0)}</TableCell>
  //                 <TableCell align="center">{parseFloat(pharmacy.earnings || 0)}</TableCell>
  //                 <TableCell align="center">{parseFloat(pharmacy.completedFollowUps || 0)}</TableCell>
  //               </TableRow>
  //             ))}
  //           <TableRow>
  //             <TableCell><strong>Total</strong></TableCell>
  //             <TableCell align="center"><strong>{totalInterventions.toFixed(1)}</strong></TableCell>
  //             <TableCell align="center"><strong>{totalEarnings.toFixed(1)}</strong></TableCell>
  //             <TableCell align="center"><strong>{totalFollowUps.toFixed(1)}</strong></TableCell>
  //           </TableRow>
  //         </TableBody>
  //       </Table>
  //     </Box>
  //   );
  // };
  const NestedTable = ({ nestedRecords = [] }) => {
    const data = nestedRecords['data']['pharmacies'];
    console.log({ nestedRecords });
    const total = nestedRecords['data']['totals'];
    const totalIntervention = total.totalIntervention || 0;
    const totalEarning = total.totalEarnings || 0;
    const totalFollowUp = total.totalFollowUp || 0;

    return (
      <Box>
        <Table size="small" aria-label="nested table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Pharmacy Name</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Completed Interventions</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Earnings</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Completed Follow-ups</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((pharmacy, index) => (
              <TableRow key={index}>
                <TableCell>{pharmacy.pharmacyName}</TableCell>
                <TableCell align="center">{parseFloat(pharmacy.completedIntervention || 0)}</TableCell>
                <TableCell align="center">£ {parseFloat(pharmacy.earnings || 0)}</TableCell>
                <TableCell align="center">{parseFloat(pharmacy.completedFollowUp || 0)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{totalIntervention.toFixed(1)}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>£ {totalEarning.toFixed(1)}</strong>
              </TableCell>
              <TableCell align="center">
                <strong>{totalFollowUp.toFixed(1)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  };

  const toggleNestedTable = async (index, pharmacist) => {
    if (openRowIndex === index) {
      // Collapse the row if already open
      setOpenRowIndex(null);
      return;
    }
    if (pharmacist) {
      console.log({ pharmacist });
      const [response] = await Promise.all([dispatch(getPharmacistStatsByPharmacy(pharmacist))]);
      setNestedData(prevData => ({
        ...prevData,
        [index]: response.payload, // Assuming the API returns data in `payload`
      }));
    }

    setOpenRowIndex(index);
  };
  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={12}
      sm={8}
      container
      style={{
        backgroundColor: '#F5F6FA',
        marginTop: '2%',
        transition: 'ease 0.5s',
        maxWidth: 'fit-content',
        position: 'fixed',
        marginLeft: showMenu ? '6%' : '17%',
      }}
    >
      <Grid container style={{ marginBottom: '2%' }} spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.group === '' ? 'All Groups' : Number(searchCreteria.group)}
              onChange={handleSelectGroup}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Groups'}>All Groups</MenuItem>
              {allGroupNames.map((elem, index) => {
                return (
                  <MenuItem key={elem.id} value={elem.id}>
                    {elem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            {/*<Select*/}
            {/*  id="demo-simple-select"*/}
            {/*  value={searchCreteria.pharmacy === '' ? 'All Pharmacies' : Number(searchCreteria.pharmacy)}*/}
            {/*  onChange={handleSelectPharmacy}*/}
            {/*  // displayEmpty*/}
            {/*  style={{*/}
            {/*    fontSize: '12px',*/}
            {/*    backgroundColor: '#EFEFEF',*/}
            {/*    color: '#393939',*/}
            {/*    width: '95%',*/}
            {/*    height: '40px',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>*/}
            {/*  {filteredPharmacies.map((elem, index) => {*/}
            {/*    return (*/}
            {/*      <MenuItem key={elem.id} value={elem.id}>*/}
            {/*        {elem.name}*/}
            {/*      </MenuItem>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</Select>*/}
            <Select
              multiple
              value={selectedPharmacies}
              onChange={handleSelectPharmacy}
              displayEmpty
              renderValue={selected => {
                if (selected.length === 0) {
                  return 'All Pharmacies';
                }
                const selectedPharmacyNames = selected.map(id => {
                  const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                  return pharmacy ? pharmacy.name : '';
                });
                return (
                  <div>
                    {selectedPharmacyNames.map(name => (
                      <Chip key={name} label={name} size="small" />
                    ))}
                  </div>
                );
              }}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
              {filteredPharmacies.map(elem => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.servicePharmacist === '' ? 'All Service Pharmacist' : searchCreteria.servicePharmacist}
              onChange={handleSelectService}
              className={'mui-field-design-filter'}
            >
              <MenuItem value={'All Service Pharmacist'}>All Service Pharmacist</MenuItem>
              {allServicesPharmacist.map((elem, index) => {
                return (
                  <MenuItem key={elem.id} value={elem.id}>
                    {elem.firstName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2} spacing={2}>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item xs={2} spacing={2}>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', gap: 10, marginLeft: -25 }}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <Tooltip title="Download">
                <IconButton
                  onClick={() => getDownloadData()}
                  style={{
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                >
                  <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '30px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Select
              id="demo-simple-select"
              value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
              onChange={handleSelectMonth}
              className={'mui-field-design-filter'}
            >
              {generalMonthArray.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    // className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateFrom(newValue)}
                    className={'mui-field-design-filter'}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock mui-field-design-filter"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateTill(newValue)}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>

        <Paper sx={{ width: '100%', paddingTop: '15px', border: 'none' }}>
          <TableContainer sx={{ height: '54vh', border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead sx={{ border: 'none' }}>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                      textAlign: 'center',
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                      textAlign: 'center',
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Pharmacist
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      textAlign: 'center',
                    }}
                  >
                    Completed Interventions
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                      textAlign: 'center',
                    }}
                  >
                    Completed Follow-ups
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    GPHC
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Contact Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPharmacistListing &&
                  allPharmacistListing?.map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow key={index} hover role="complementary" style={{ height: 50, textAlign: 'center' }}>
                        <TableCell style={{ paddingLeft: '20px' }}>
                          <IconButton size="small" onClick={() => toggleNestedTable(index, item?.appuser_id)}>
                            {openRowIndex === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        >
                          {Number(index) + Number(1)}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        >
                          {item?.internaluser_firstname}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            textAlign: 'center',
                          }}
                        >
                          {item?.completedInterventions}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            textAlign: 'center',
                          }}
                        >
                          {item?.completedFollowUps}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                            color: '#236EBA',
                          }}
                        >
                          {item?.internaluser_email}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        >
                          {item?.appuser_gphcnumber}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        >
                          {item?.appuser_contact_number}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                            paddingLeft: '40px',
                          }}
                        ></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                          <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>
                            {nestedData[index] ? <NestedTable nestedRecords={nestedData[index]} /> : <Box padding={2}>Loading...</Box>}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px', height: '12%' }}
            rowsPerPageOptions={[50, 150, 300]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div data-page={pageNo} style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default PharmacistStatsTab;
