import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Chip, Grid, MenuItem, Paper, Select, TextField, CircularProgress } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import DailySummaryHook from '../deo-work-flow/hooks/dailySummaryHook';
import { generalMonthArray } from 'app/shared/util/utits';

const useResizeObserver = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef<HTMLDivElement | null>(null);

  const handleResize = useCallback(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [handleResize]);

  return { ref, size };
};

const ContractorAnalytics = ({ showMenu }: { showMenu: boolean }) => {
  const { states, handlers } = DailySummaryHook();
  const { allPharmaciesOfUser, searchCreteria, allDeoSessionStatsCurrentDate } = states;
  const { handleSelectMonth } = handlers;
  const chartContainer = useResizeObserver();
  const [selectedPharmacies, setSelectedPharmacies] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>('All Months');
  const [backendResponse, setBackendRespnse] = useState<any[]>([]);
  const filteredBackendPharmacies = backendResponse.filter(pharmacy =>
    selectedPharmacies.includes(allPharmaciesOfUser.find(pharm => pharm.name === pharmacy.pharmacy)?.id || -1)
  );

  const colorMapping: { [key: string]: string } = {
    'Claimable NMS': '#2b8a3e',
    'Completed Interventions': '#40c057',
    'Completed Follow-ups': '#8ce99a',
    'Pending Interventions': '#868e96',
    'Pending Follow-ups': '#834e56',
    'Intervention Not Delivered': '#e67700',
    'No Answers': '#ffbf00',
    'No Consents': '#ff2500',
    'Not New Medicine': '#c92a2a',
    'Not Eligible Conditions': '#f66151',
    'Patient Left Pharmacy': '#b5b69c',
    'Incorrect Number': '#fff3bf',
  };
  const propertyMapping: { [key: string]: string } = {
    completed_nms: 'Claimable NMS',
    completed_intervention: 'Completed Interventions',
    completed_follow_up: 'Completed Follow-ups',
    intervention_pending: 'Pending Interventions',
    follow_up_pending: 'Pending Follow-ups',
    no_answers: 'No Answers',
    not_new_medicine: 'Not New Medicine',
    patient_left: 'Patient Left Pharmacy',
    incorrect_number: 'Incorrect Number',
    intervention_not_delivered: 'Intervention Not Delivered',
    no_consents: 'No Consents',
    not_eligible: 'Not Eligible Conditions',
  };

  const calculateTimeSaved = data => {
    return data.map(pharmacy => {
      const timeSaved =
        pharmacy.completed_intervention * 4 +
        pharmacy.completed_follow_up * 4 +
        (pharmacy.no_consents +
          pharmacy.not_eligible +
          pharmacy.in_eligible +
          pharmacy.intervention_pending +
          pharmacy.follow_up_pending +
          pharmacy.no_answers +
          pharmacy.not_new_medicine +
          pharmacy.patient_left +
          pharmacy.incorrect_number +
          pharmacy.intervention_not_delivered) *
          1;
      return {
        pharmacy: pharmacy.pharmacy,
        time_saved: timeSaved,
      };
    });
  };

  useEffect(() => {
    if (allPharmaciesOfUser && allPharmaciesOfUser.length > 0) {
      const defaultSelectedPharmacies = allPharmaciesOfUser.slice(0, 5).map(pharmacy => pharmacy.id);
      setSelectedPharmacies(defaultSelectedPharmacies);
    }
  }, [allPharmaciesOfUser]);

  useEffect(() => {
    // Check if data is undefined or empty before setting the state
    if (allDeoSessionStatsCurrentDate && allDeoSessionStatsCurrentDate.results) {
      setBackendRespnse(allDeoSessionStatsCurrentDate.results.results || []);
    } else {
      setBackendRespnse([]); // Handle empty or undefined response
    }
  }, [allDeoSessionStatsCurrentDate]);

  const handlePharmacySelection = event => {
    const { value } = event.target;
    if (Array.isArray(value)) {
      if (value.length <= 5) {
        setSelectedPharmacies(value);
      }
    }
  };

  const series = Object.keys(colorMapping)
    .map(key => {
      const propertyKey = Object.keys(propertyMapping).find(backendKey => propertyMapping[backendKey] === key);

      if (propertyKey) {
        return {
          name: key,
          data: filteredBackendPharmacies.map(pharmacy => pharmacy[propertyKey]),
          color: colorMapping[key],
        };
      }
      return null;
    })
    .filter(seriesItem => seriesItem !== null);

  const options = {
    chart: {
      stacked: false,
      width: '100%',
      height: '100%',
      id: 'contractor-analytics-chart',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    xaxis: {
      categories: filteredBackendPharmacies.map(pharmacy => pharmacy.pharmacy),
      title: { text: 'Pharmacies', style: { color: '#22577A' } },
      labels: { style: { colors: '#22577A' } },
    },
    yaxis: {
      title: { text: 'Values', style: { color: '#22577A' } },
      labels: { style: { colors: '#22577A' } },
    },
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    legend: {
      position: 'right' as 'right', // Ensure this is explicitly typed
      horizontalAlign: 'center' as 'center', // Explicitly type the value
      floating: false,
      fontSize: '12px',
      labels: {
        colors: '#22577A',
      },
      containerMargin: {
        top: 0,
        left: 0,
      },
      offsetX: 0,
      offsetY: 0,
    } as const,
  };

  const timeSavedOptions = {
    chart: { height: '100%' },
    xaxis: {
      categories: filteredBackendPharmacies.map(pharmacy => pharmacy.pharmacy),
      labels: {
        formatter: value => {
          // Truncate the value to 15 characters, with ellipsis
          const maxLength = 13;
          return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
        },
        style: { colors: '#22577A' },
      },
    },
    yaxis: { title: { text: 'Total Time Saved (mins)' } },
    title: { text: 'Time Saved for Pharmacies by Outsourcing NMS Services', align: 'center' as 'center' },
  };

  const timeSavedSeries = calculateTimeSaved(filteredBackendPharmacies); // Calculate time saved here

  const performanceSeries = [
    {
      name: 'Average Target Performance (%)',
      data: filteredBackendPharmacies.map(pharmacy => {
        // Ensure that performancePercentage is a number or falls back to 0 if not available
        const performancePercentage = parseFloat(pharmacy.performancePercentage) || 0;
        return performancePercentage;
      }),
    },
  ];

  const performanceOptions = {
    chart: { height: '100%' },
    xaxis: {
      categories: filteredBackendPharmacies.map(pharmacy => pharmacy.pharmacy),
      labels: {
        formatter: value => {
          // Truncate the value to 15 characters, with ellipsis
          const maxLength = 13;
          return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
        },
        style: { colors: '#22577A' },
      },
    },
    yaxis: { title: { text: 'Average Target Percentage (%)' } },
    title: { text: '3-Month Average NMS Target Performance', align: 'center' as 'center' },
  };

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={8}
      container
      style={{
        backgroundColor: 'transparent',
        top: 0,
        marginTop: 60,
        height: '87%',
        transition: 'ease 0.5s',
        position: 'fixed',
        right: '2%',
        marginRight: -52,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Grid container style={{ marginTop: 20, gap: '10px', justifyContent: 'flex-start', alignItems: 'center' }}>
        {/* Pharmacies Select */}
        <Grid item>
          <Select
            className={'mui-field-design-filter'}
            multiple
            value={selectedPharmacies}
            onChange={handlePharmacySelection}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return 'All Pharmacies';
              }
              const selectedPharmacyNames = selected.map(id => {
                const pharma = allPharmaciesOfUser.find(pharm => pharm.id === id);
                return pharma ? pharma.name : '';
              });
              return (
                <div>
                  {selectedPharmacyNames.map(name => (
                    <Chip key={name} label={name} size="small" style={{ marginRight: 5 }} />
                  ))}
                </div>
              );
            }}
          >
            <MenuItem disabled value={'All Pharmacies'}>
              All Pharmacies
            </MenuItem>
            {allPharmaciesOfUser.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Months Select */}
        <Grid item>
          <Select
            id="demo-simple-select"
            value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
            onChange={handleSelectMonth}
            className={'mui-field-design-filter'}
          >
            {generalMonthArray.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {/* Chart and Paper Container */}
      <Grid
        lg={11.6}
        container
        gap={3}
        style={{
          flexDirection: 'column', // Change to column to stack the graphs vertically
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%', // Ensure the parent container uses full height
        }}
      >
        <div
          style={{
            padding: 0,
            margin: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%', // Adjust height for the larger graph
          }}
        >
          <Paper
            sx={{
              borderRadius: 4,
              width: '100%',
              height: '100%',
              padding: 2,
              boxShadow: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            ref={chartContainer.ref}
          >
            <div style={{ width: '100%', height: '100%' }}>
              <ReactApexChart type="bar" series={series} options={options} height="100%" width="100%" />
            </div>
          </Paper>
        </div>

        {/* Smaller Graphs below the bigger graph */}
        <Grid
          container
          style={{
            flexDirection: 'row', // Align the two smaller graphs horizontally
            width: '100%',
            height: '40%', // Adjust height for the smaller graphs
            gap: '2%', // Add space between the smaller graphs
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={{
              borderRadius: 4,
              width: '49%', // Make smaller graphs take up less space
              height: '100%',
              padding: 2,
              boxShadow: 3,
            }}
          >
            <ReactApexChart type="bar" series={performanceSeries} options={performanceOptions} height="100%" width="100%" />
          </Paper>

          <Paper
            sx={{
              borderRadius: 4,
              width: '49%', // Make smaller graphs take up less space
              height: '100%',
              padding: 2,
              boxShadow: 3,
            }}
          >
            <ReactApexChart
              type="bar"
              series={[
                {
                  name: 'Time Saved (mins)',
                  data: timeSavedSeries.map(entry => entry.time_saved),
                },
              ]}
              options={timeSavedOptions} // Replace with your dynamic options
              height="100%"
              width="100%"
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContractorAnalytics;
