import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getAllSessionsByPharmacy, partialUpdateEntity } from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { NMS_STATUS, NMS_TYPES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './listing-overview-table.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import RichDataTable from '../rich-data-table/RichDataTable';
import useOverviewScreen from '../hooks/useOverviewScreen';
import { isValidDate } from 'app/shared/util/utits';
import { getAllSessionsListingWithDateRange } from 'app/modules/admin-work-flow/admin-reducer';
import { ISessionParam } from 'app/modules/admin-work-flow/interface';
import { useDashboardContext } from 'app/shared/layout/header/LeftBarContext';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
}

export const AdminOverviewTable = props => {
  const { states, handlers } = useOverviewScreen();
  const { stats, recentClients, recentRoles, pharmacyData, itemsData, searchFilter, searchCreteria } = states;
  const { downloadSummaryInExcelFile, handledateFrom, handledateTill, handleRefresh } = handlers;
  console.log({ pharmacyData });
  const emptyRowCount = 8;
  const emptyCellCount = 4;
  const { showMenu } = useDashboardContext();

  const [selectService, setSelectService] = useState(0);
  const gridData = [
    { icon: '../../../../content/assets/Total Clients.png', heading: 'Total Contractors', heading2: stats.totalClients },
    { icon: '../../../../content/assets/completed-nms.png', heading: 'Completed NMS', heading2: stats.totalNMSCompleted },
    {
      icon: '../../../../content/assets/completed-interventions.png',
      heading: 'Completed Intervention',
      heading2: stats.totalCompletedIntervention,
    },
    // { icon: '../../../../content/assets/Follow Ups.png', heading: 'Follow Ups', heading2: stats.totalFollowUp },
    { icon: '../../../../content/assets/Total Roles.png', heading: 'Total Roles', heading2: stats.totalRoles },
  ];

  return (
    <Grid
      lg={showMenu === false ? 9.75 : 11.15}
      xs={8}
      container
      style={{
        backgroundColor: 'transparent',
        marginTop: '2%',
        transition: 'ease 0.5s',
        marginLeft: showMenu ? '7%' : '18%',
      }}
    >
      <Grid lg={8.5} xs={12} container style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <div className="drop_down_div">
          <Grid lg={3} xs={3} style={{ backgroundColor: '' }}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    // className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateFrom(newValue)}
                    className={'mui-field-design-filter'}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
          <Grid lg={3} xs={3} style={{ backgroundColor: '' }}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock mui-field-design-filter"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateTill(newValue)}
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>
          <Grid lg={1} xs={1} style={{ backgroundColor: '' }}>
            <Tooltip title="Download">
              <IconButton
                onClick={() => downloadSummaryInExcelFile()}
                style={{
                  marginLeft: 10,
                  height: 45,
                  width: 45,
                  borderRadius: 100,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src="../../../../content/assets/download.png" alt="refresh" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '30px' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </div>

        <Grid lg={12} xs={12} style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
          {gridData.map((item, index) => (
            <Grid
              key={index}
              lg={3}
              xs={3}
              style={{
                justifyContent: 'center',
                backgroundColor: 'white',
                border: '0.2px solid rgb(187, 216, 254)',
                borderRadius: '6px',
                marginRight: '2px',
              }}
            >
              {/*<div*/}
              {/*  style={{*/}
              {/*    width: '100%',*/}
              {/*    height: 100,*/}
              {/*    display: 'flex',*/}
              {/*    flexDirection: 'column',*/}
              {/*    alignItems: 'center',*/}
              {/*    justifyContent: 'center',*/}
              {/*    marginTop: 20,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <img src={item.icon} alt="logo" style={{ height: '40px' }} />*/}
              {/*  <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{item.heading}</h1>*/}
              {/*  <h1 style={{ fontSize: 17, marginTop: 5 }}>{item.heading2}</h1>*/}
              {/*</div>*/}
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  padding: 25,
                }}
              >
                {/* Icon and Heading */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <img src={item.icon} alt="logo" style={{ height: '40px' }} />
                  <h1 style={{ fontSize: 25, color: '#004DB2', fontWeight: 600 }}>{item.heading2}</h1>
                </div>

                {/* Heading2 */}
                <h1
                  style={{
                    fontSize: 13,
                    color: '#000000',
                    textAlign: 'left',
                    fontWeight: '550',
                    marginTop: 15,
                    textTransform: 'uppercase',
                  }}
                >
                  {item.heading}
                </h1>
              </div>
            </Grid>
          ))}
        </Grid>

        <Paper sx={{ width: '100%', paddingTop: '10px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
              justifyContent: 'space-between',
              marginLeft: 15,
            }}
          >
            <p style={{ fontSize: 13, color: '#0D61A9', marginBottom: 0 }}>Recently Added Roles</p>
          </div>
          <RichDataTable
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['index', 'internalUser.firstName', 'formattedAuthorities']}
            dataSource={recentRoles}
            headers={['ID', 'Staff Name', 'Role']}
            showPagination={false}
          />
        </Paper>
      </Grid>
      <Grid
        lg={3}
        container
        style={{
          backgroundColor: 'white',
          marginTop: 15,
          marginLeft: 20,
          border: '0.2px solid rgb(187, 216, 254)',
          borderRadius: '6px',
        }}
      >
        <div style={{ width: '100%', marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20 }}>
          <h1 style={{ fontSize: 13, color: '#0D61A9', marginLeft: 10 }}>Recently Added Pharmacy Contractors</h1>
          <div style={{ backgroundColor: '#7CB6F8', height: 1, width: '100%', marginTop: 0 }}></div>

          <div style={{ height: 350, backgroundColor: '', overflow: 'auto' }}>
            {itemsData.map((item, index) => (
              <React.Fragment key={index}>
                <div>
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      height: 50,
                      marginTop: 10,
                    }}
                  >
                    <img
                      src={'../../../../content/assets/profile1.png'}
                      alt="logo"
                      style={{ height: '40px', marginRight: 5, marginLeft: 12, marginTop: 6 }}
                      onClick={() => {}}
                    />

                    <div
                      style={{
                        justifyContent: 'space-between',
                        backgroundColor: '',
                        height: 50,
                        marginTop: 10,
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#000000',
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#707070',
                        }}
                      >
                        {item.date}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ backgroundColor: '#0000000D', height: 1, width: '100%', marginTop: 20 }}></div>
                </div>
              </React.Fragment>
            ))}
          </div>
          <h1 style={{ fontSize: 13, color: '#0D61A9', marginLeft: 10 }}>Recently Added Pharmacies</h1>
          <div style={{ backgroundColor: '#7CB6F8', height: 1, width: '100%', marginTop: 0 }}></div>

          <div style={{ height: 390, backgroundColor: '', overflow: 'auto' }}>
            {pharmacyData?.map((item, index) => (
              <React.Fragment key={index}>
                <div>
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      height: 50,
                      marginTop: 10,
                    }}
                  >
                    <img
                      src={'../../../../content/assets/profile1.png'}
                      alt="logo"
                      style={{ height: '40px', marginRight: 5, marginLeft: 12, marginTop: 6 }}
                      onClick={() => {}}
                    />

                    <div
                      style={{
                        justifyContent: 'space-between',
                        backgroundColor: '',
                        height: 50,
                        marginTop: 10,
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#000000',
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 10,
                          marginLeft: 0.5,
                          fontFamily: 'Roboto, sans-serif',
                          color: '#707070',
                        }}
                      >
                        {item.date}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ backgroundColor: '#0000000D', height: 1, width: '100%', marginTop: 20 }}></div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default AdminOverviewTable;

interface Styles {
  container: React.CSSProperties;
}

// Define the styles object
const styles: Styles = {
  container: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    marginTop: 15,
    marginLeft: 20,
    display: 'flex',
  },
};
