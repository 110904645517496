import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton, Select, MenuItem, CircularProgress, Chip } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterHook from '../hooks/filterHook';
import moment from 'moment';
import OverviewHook from 'app/modules/deo-work-flow/hooks/overviewHook';
import overviewHook from 'app/modules/deo-work-flow/hooks/overviewHook';
import { generalMonthArray, sessionStatusesOverview } from 'app/shared/util/utits';
import { useNavigate } from 'react-router-dom';
// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const SelectFilter = props => {
  const { states } = FilterHook();

  // const { statesSecondary, handlersSecondary } = overviewHook();
  // const { selectedMonth } = statesSecondary;
  // const { handleSelectMonth } = handlersSecondary;
  const {
    handleSelectPharmacy,
    handleSelectMonth,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    searchCreteria,
    isOverview,
    selectedPharmacies,
    getDownloadData,
    loadingDownload,
    handledateFrom,
    handledateTill,
  } = props;

  const { allServicesPharmacist, allPharmacies, allSessionStatuses, allGroupNames } = states;
  const [selectedGroup, setSelectedGroup] = useState('');
  const [pharmacyList, setPharmacyList] = useState([]);

  const sessionStatusesOverdue = [
    { id: 2, name: 'No Answered' },
    { id: 5, name: 'Intervention Pending' },
    { id: 6, name: 'Followup Pending' },
  ];

  useEffect(() => {
    getFilteredPharmacies(selectedGroup);
  }, [selectedGroup, allGroupNames]);

  const handleGroupChange = event => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    handleSelectBranch(event);
  };

  const getFilteredPharmacies = groupId => {
    if (groupId === '' || groupId === 'All Groups') {
      setPharmacyList(allPharmacies);
    } else {
      const selectedGroupObj = allGroupNames.find(group => group.id === groupId);
      setPharmacyList(selectedGroupObj ? selectedGroupObj.pharmacy : []);
    }
  };

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={2}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
            onChange={handleGroupChange}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Groups'}>All Groups</MenuItem>
            {allGroupNames.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select
            multiple
            value={selectedPharmacies}
            onChange={handleSelectPharmacy}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return 'All Pharmacies';
              }
              const selectedPharmacyNames = selected.map(id => {
                const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                return pharmacy ? pharmacy.name : '';
              });
              return (
                <div>
                  {selectedPharmacyNames.map(name => (
                    <Chip key={name} label={name} size="small" />
                  ))}
                </div>
              );
            }}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
            {pharmacyList.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
            onChange={handleSelectService}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
            {allServicesPharmacist.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.firstName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.status === '' ? 'Status' : searchCreteria.status}
            onChange={handleSelectStatus}
            className={'mui-field-design-filter'}
          >
            <MenuItem value={'Status'}>Status</MenuItem>

            {isOverview
              ? sessionStatusesOverview.map(elem => {
                  return (
                    <MenuItem key={elem.id} value={elem.name}>
                      {elem.name}
                    </MenuItem>
                  );
                })
              : sessionStatusesOverdue.map(elem => {
                  return (
                    <MenuItem key={elem.id} value={elem.name}>
                      {elem.name}
                    </MenuItem>
                  );
                })}
          </Select>
        </Grid>
        <Grid item xs={2} spacing={2}>
          <Grid item xs={2}></Grid> <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', gap: 10, marginLeft: -25 }}>
          {ActionsFilter({ getDownloadData, loadingDownload })}
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={2}>
          <Select
            id="demo-simple-select"
            disabled={
              searchCreteria.status !== 'Completed NMS' &&
              searchCreteria.status !== 'Completed Interventions' &&
              searchCreteria.status !== 'Completed Follow-ups'
            }
            value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
            onChange={handleSelectMonth}
            className={'mui-field-design-filter'}
          >
            {generalMonthArray.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {DateFilter({ handledateFrom, handledateTill, searchCreteria })}
      </Grid>
    </>
  );
};

export const DateFilter = props => {
  const { handledateFrom, handledateTill, searchCreteria, handleSelectMonth, selectedMonth } = props;
  return (
    <>
      <Grid item xs={2}>
        <Tooltip title="Date From">
          <div style={{ display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                className="clock mui-field-design-filter"
                format="DD/MM/YYYY"
                value={searchCreteria.from === '' ? null : searchCreteria.from}
                onChange={newValue => handledateFrom(newValue)}
              />
            </LocalizationProvider>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Date Till">
          <div style={{ display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                value={searchCreteria.to === '' ? null : searchCreteria.to}
                className="clock mui-field-design-filter"
                format="DD/MM/YYYY"
                onChange={newValue => handledateTill(newValue)}
              />
            </LocalizationProvider>
          </div>
        </Tooltip>
      </Grid>
    </>
  );
};

export const ActionsFilter = (props: any) => {
  const { getDownloadData, loadingDownload } = props;
  const navigate = useNavigate();
  const handleFileUpload = () => {
    navigate('/operator/dashboard/session-deo-file-uploaded');
  };
  return (
    <>
      <Tooltip title="Download">
        {loadingDownload ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={getDownloadData}
            style={{
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/download.png'} alt="logo" style={{ height: '30px' }} />
          </IconButton>
        )}
      </Tooltip>

      <Tooltip title="Upload">
        <IconButton
          onClick={handleFileUpload}
          style={{
            background: 'transparent linear-gradient(164deg, #164AAA 0%, rgb(82, 200, 0) 100%) 0% 0% no-repeat padding-box',
            height: 34,
            width: 100,
            padding: '0px',
            display: 'flex',
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginLeft: 10, // Add margin here
          }}
        >
          <h1 style={{ fontSize: 12, color: 'white', marginTop: 10 }}>Upload</h1>
        </IconButton>
      </Tooltip>
    </>
  );
};
